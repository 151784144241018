import { ISecurePayloadResponse } from './../types/Request';
import { Exception } from '@/plugins/exception';
import { createDiscreteApi, lightTheme } from 'naive-ui';

export type method = 'GET' | 'POST' | 'PUT' | 'DELETE';
const apiKey = import.meta.env.VITE_APP_API_KEY;
let isErrorTriggered = false;
function getAuthKey() {
	return compressToEncodedURIComponent(new Date().getTime().toString() + '+' + apiKey);
}

class BaseService {
	private requestInit: RequestInit;
	private serviceContext: string;
	private headers: Headers = new Headers();
	private queue = new Queue();
	private isPayloadCompressed = true;
	private notification = createDiscreteApi(['notification'], {
		configProviderProps: {
			theme: lightTheme,
		},
	}).notification;
	constructor(
		_rootEndpoint?: string,
		_requestInit: RequestInit = Object.create({}),
		_secure = false,
		_serviceContext?: string
	) {
		this.requestInit = _requestInit;
		this.serviceContext = _serviceContext || import.meta.env.VITE_APP_API_URL;
		if (_rootEndpoint) {
			this.serviceContext = `${this.serviceContext}/${_rootEndpoint}`;
		}
	}

	public setIsPayloadCompressed(isCompressed: boolean): void {
		this.isPayloadCompressed = isCompressed;
	}

	public setServiceContext(context: string): void {
		this.serviceContext = context;
	}

	public get getServiceContext(): string {
		return this.serviceContext;
	}

	public get getHeaders(): Headers {
		this.headers.set('Content-Type', 'application/json');
		return this.headers;
	}

	public setAuthorizationHeader(authToken: string): void {
		this.headers.set('Authorization', `Bearer ${authToken}`);
	}

	protected buildRequest<PayloadType>(url: string, method: method, body?: PayloadType, isSecure = false): Request {
		this.requestInit.headers = this.getHeaders;
		this.requestInit.method = method;
		this.requestInit.body = this.createBody(body, isSecure);
		return new Request(`${this.getServiceContext}${url}`, this.requestInit);
	}

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
	public async post<ReturnType, PayloadType = unknown>(
		url: string,
		payload: PayloadType & (PayloadType extends undefined ? Record<string, unknown> : PayloadType)
	): Promise<ReturnType> {
		const isSecure = Object.keys(SecureEndpoints).includes(url);
		const request = () => fetch(this.buildRequest(url, 'POST', payload, isSecure));
		return await this.checkBeforeCrudOperation<ReturnType>(request, isSecure);
	}
	private createBody<PayloadType>(payload: PayloadType, isSecure?: boolean): string {
		/* eslint-disable */
		const token = getStorageItem(storageVariables.token);
		if (token && isSecure) {
			// @ts-ignore
			payload.Token = token;
		} else {
			// @ts-ignore
			payload.Key = getAuthKey();
		}
		// @ts-ignore
		if (payload?.payload || payload?.Payload) {
			// @ts-ignore
			const requestPayload = payload?.payload || payload?.Payload;
			import.meta.env.DEV && console.log('requestPayload', requestPayload);
			// @ts-ignore
			payload.Payload = compressToEncodedURIComponent(
				// @ts-ignore
				JSON.stringify(requestPayload)
			);
			// @ts-ignore
			delete payload.payload;
		}
		return JSON.stringify(payload);
		/* eslint-enable */
	}

	private async checkResponse<ReturnType>(response: Response): Promise<ReturnType> {
		const isResponseOk = response.ok;
		try {
			if (!isResponseOk) {
				throw await response.json();
			}
			const result: ISecurePayloadResponse<ReturnType> = await response.json();
			if (result.Token) {
				setStorageItem(storageVariables.token, result.Token);
			}
			if (result.Payload) {
				const payload: ReturnType = this.isPayloadCompressed
					? decompressPayload(result.Payload as string)
					: result.Payload;
				result.Payload = payload;
				this.isPayloadCompressed = true;
			}
			const endpoint = response.url.split('/');
			const isErrored = result.ErrorCode !== 0;
			import.meta.env.DEV &&
				console.log(
					`Response: %c${endpoint[endpoint?.length - 1]}`,
					`color: ${isErrored ? 'red' : 'green'}`,
					result.Payload || result,
					isErrored && !result.Payload ? { ...{ message: result.ErrorMessage, code: result.ErrorCode } } : ''
				);
			if (isErrored) {
				throw result;
			}
			// @ts-ignore
			return result?.Payload ? [result.Payload, result?.Count] : result;
		} catch (error) {
			const err = createException(error as Record<string, string>);
			this.isPayloadCompressed = true;
			if (!isErrorTriggered && err.code == -1) {
				this.notification.error({
					title: 'Error',
					duration: 5000,
					content: 'Something went wrong, please try again later or contact customer support',
				});
				isErrorTriggered = true;
			}

			throw err;
		}
	}

	private async checkAuthToken(): Promise<void> {
		const savedToken = getStorageItem(storageVariables.token);
		if (!savedToken) {
			throw createException({ ErrorCode: 4, ErrorMessage: 'Token is not valid' });
		}
	}

	private async checkBeforeCrudOperation<ReturnType>(
		request: () => Promise<Response>,
		isSecure = false
	): Promise<ReturnType> {
		try {
			const response = isSecure ? await this.queue.enqueue(request) : await request();
			return await this.checkResponse<ReturnType>(response);
		} catch (error) {
			console.log(error)
			const err = error as Exception;
			if (err.code === 2) {
				try {
					await this.checkAuthToken();
					const response = await request();
					const res = await this.checkResponse<ReturnType>(response);
					return res;
				} catch (error) {
					console.log(error);
					throw error;
				}
			} else {
				throw createException(error as Record<string, string>);
			}
		}
	}
}

export default new BaseService();
