<template>
	<AlertModalSkeleton v-model="isOpenModal" title="Terms of Use" modal-type="info" is-close-icon-visible>
		<template #body>
			<div class="space-y-4 w-full">
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Overview</h2>
					<p>This website is provided by Dental Practice Today and its affiliates (collectively, “Dental Practice Today”).</p>
					<p>
						By accessing the Dental Practice Today website, you are indicating your acknowledgement and acceptance of these
						Terms of Use. These Terms of Use have taken effect on January 15<sup>th</sup>, 2023.
					</p>
					<p>
						These Terms of Use were last updated on January 23<sup>rd</sup>, 2023. These Terms of Use are subject to change by Dental
						Practice Today at any time at its discretion. Your use of this Site after such changes are implemented constitutes
						your acknowledgement and acceptance of the changes. Please consult these Terms of Use regularly.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Restrictions on Use</h2>
					<p>
						All material included on this Site, and any other Site owned, operated, licensed or controlled by Dental Practice 
						Today, such as text, graphics, logos, images, photographs, video and audio clips, digital downloads, data
						compilations and software (the “Content”), is the property of Dental Practice Today, its subsidiaries, affiliated
						companies and/or third-party licensors and is protected by United States and international copyright laws.
						Modification or use of the Content except as expressly provided in these Terms of Use violates Dental Practice
						Today’s intellectual property rights.
					</p>
				</div>

				<div>
					<h1 class="text-2xl font-extrabold tracking-tight text-gray-700 capitalize">Information We Collect</h1>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Required Information</h2>
					<p>
						To purchase a software product or service from us, you must provide your name, company, address, phone
						number, and e-mail address. In order to make payments electronically, you must provide credit card or debit
						account information, credit card verification code or debit card PIN, account holder’s name, and account
						holder’s billing address and telephone. This required information is necessary for us to process
						transactions, protect you against credit card fraud, and to contact you should the need arise in
						administering your account. If you purchase our products or services from an authorized reseller, the
						reseller will only share the information needed to deliver the product and / or provide the service you
						purchased with us, such as your name, company, address, phone number, and e-mail address. We will not
						receive any payment information from our resellers. Please check the reseller’s Privacy Policy for more
						details.
					</p>
					<p>
						The Content may not be copied, distributed, republished, uploaded, posted or transmitted in any way without
						the prior written consent of Dental Practice Today, except that:
					</p>
					<ol class="list-decimal ml-12">
						<li>
							You may download, print, distribute and use pages from the Site for your own informational, non-commercial
							purposes, but not for commercial use or general distribution.
						</li>
						<li>
							Any copies of documents or pages from the Site must not alter the original Site content and must include
							the Dental Practice Today copyright notice: © Dental Practice Today. All Rights Reserved.
						</li>
						<li>
							You may link to this Site provided that the link does not falsely imply or suggest that Dental Practice Today
							has endorsed or is affiliated with the linked Site.
						</li>
					</ol>
					<p>Users of the Site must not:</p>
					<ol class="ml-12 list-decimal">
						<li>
							Monitor, gather or copy any Content on this Site by using any robot, “bot”, spider, crawler, spyware,
							engine, device, software, extraction tool or any other automatic device, utility or manual process of any
							kind.
						</li>
						<li>
							Frame or utilize framing techniques to enclose any trademark or other proprietary information (including,
							without limitation, any images, text or page layout).
						</li>
						<li>
							Engage in any activities through or in connection with this Site that seek to attempt to harm minors or
							are unlawful, offensive, obscene, threatening, harassing, abusive or that violate any right of any third
							party.
						</li>
						<li>Attempt to circumvent the security systems of the Site.</li>
						<li>
							Attempt to gain unauthorized access to services, materials, other accounts, computer systems or networks
							connected to any Dental Practice Today’s server.
						</li>
						<li>
							Attempt to use the Site for any purposes other than those intended by Dental Practice Today, as determined by
							Dental Practice Today in its sole discretion.
						</li>
						<li>
							Upload or submit any data or information that contains viruses or any other computer code, corrupt files
							or programs designed to interrupt, destroy or limit the functionality or disrupt any software, hardware,
							telecommunications, networks, servers or other equipment.
						</li>
						<li>
							Engage in any activity that interferes with a user’s access to this Site or the proper operation of this
							Site. You also agree that, in using this Site, you will not impersonate any person or entity.
						</li>
					</ol>

					<p>
						Dental Practice Today shall have the right to monitor use of this Site to determine compliance with these Terms of
						Use, revoke its consent at any time and you shall immediately cease any further use of the Dental Practice Today
						Site Content. The foregoing consent does not include permission to copy the design elements, look and feel
						or layout of Dental Practice Today’s Site. Subject to the further terms set forth above, you assume all risks
						concerning the suitability and accuracy of the information within Dental Practice Today’s Site which you propose
						to use, subject to Dental Practice Today’s consent.
					</p>
				</div>

				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">
						Proprietary Information Consent
					</h2>
					<p>
						If you wish to use material contained on this Site other than for your individual review and individual
						educational purposes, and the copyright ownership of such material is held by a third party, then you must
						secure the permission of such third party in order to use such material. If you are not a customer of
						Dental Practice Today and wish to download and use any information contained in this Site other than for your
						individual review and individual educational purposes or in connection with your customer relationship or
						potential customer relationship with Dental Practice Today, please send a request with your proposed use to
						info at dentalpracticetoday.com so that we may evaluate your proposed use of Dental Practice Today’s Site Content.
					</p>
					<p>
						If we approve your request to use Dental Practice Today’s Site Content, you are granted permission to view, use,
						reproduce, copy and distribute documents within Dental Practice Today’s Site for the sole purposes specified in
						your written request. You agree to all terms below:
					</p>
					<ol class="ml-12 list-decimal">
						<li>Not to modify the documents, publications or graphics</li>
						<li>Not to quote materials out of context</li>
						<li>To retain and display the copyright notice of Dental Practice Today on such Content</li>
					</ol>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Disclamer</h2>
					<p>
						Dental Practice Today cannot guarantee that the Site or its content is error free and Dental Practice Today makes no
						representations about the technical accuracy or functionality of the Site or that the Content is accurate,
						error free or up to date.
					</p>
					<p>
						THIS SITE IS PROVIDED BY Dental Practice Today ON AN “AS IS” AND “AS AVAILABLE” BASIS. Dental Practice Today MAKES NO
						REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
						WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT OF THIRD-PARTY
						RIGHTS OR INTELLECTUAL PROPERTY. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK.
						Dental Practice Today DOES NOT WARRANT THAT THE INFORMATION IN THIS SITE IS ACCURATE, RELIABLE, UP TO DATE OR
						CORRECT, THAT THIS SITE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION OR THAT THE SITE IS FREE OF
						VIRUSES OR OTHER HARMFUL COMPONENTS. THE CONTENT MAY INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS,
						AND Dental Practice Today MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME. YOU, AND NOT Dental Practice Today, ASSUME THE
						ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY LOSS OR DAMAGE ARISING FROM
						THE USE OF THIS SITE OR ITS CONTENT. Dental Practice Today MAKES NO WARRANTIES THAT YOUR USE OF THE CONTENT WILL
						NOT INFRINGE THE RIGHTS OF OTHERS AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH
						CONTENT.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Limitation of Liability</h2>
					<p>
						Neither Dental Practice Today, any of its affiliates, directors, officers and employees, nor any other party
						involved in creating, producing or delivering the Site is liable for any direct, incidental, consequential,
						indirect or punitive damages arising out of your access to, or use of, the Site or the operation of the Site
						or failure of the Site to operate. In no event shall Dental Practice Today be liable for any direct, indirect,
						special, punitive, incidental, exemplary or consequential, damages or any damages whatsoever, even if
						Dental Practice Today has been previously advised of the possibility of such damages, whether in an action in
						contract, negligence, or any other theory, arising out of or in connection with the use, inability to use or
						performance of the information, services, products and materials available from this Site. These limitations
						shall apply notwithstanding any failure of essential purpose of any limited remedy. Your acceptance of this
						limitation of liability is an essential term of this agreement and the parties acknowledge that Dental Practice
						Today would not grant access to the Site without your agreement to this term.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Intellectual Property</h2>
					<p>
						You should assume that everything you see or read on the Site is proprietary information protected by
						copyright or trademark unless otherwise noted, and may not be used except as provided in these Terms of Use
						or with the written permission of Dental Practice Today. Nothing posted on this Site grants a license to any
						Dental Practice Today trademarks, copyrights or other intellectual property rights, whether by implication,
						estoppel or otherwise.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Third Party Trademarks</h2>
					<p>
						Trademarks displayed on this Site not owned by Dental Practice Today are the property of their respective owners,
						who may or may not be affiliated with Dental Practice Today. Nothing contained on this Site should be construed as
						granting any license or right to use any third party trademarks without the written permission of such third
						party that may own the trademarks. Your use of the trademarks, or any other Content on the Site, except as
						provided in these Terms of Use, is strictly prohibited. We ask that you respect these rights.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Linked Sites</h2>
					<p>
						This Site may link to or be linked from other websites that are not maintained by, or related to, Dental Practice
						Today. Dental Practice Today does not endorse, and is not responsible for, the content of any of those
						third-party websites.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Sales of Products and Services</h2>
					<p>
						All sales of products and services are subject to Dental Practice Today’s standard terms and conditions of sale, a
						copy of which is available upon request.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">International Use</h2>
					<p>
						Dental Practice Today makes no representation that materials on this Site are appropriate or available for use in
						locations outside the United States. Access to this site from countries or territories where such access is
						illegal is prohibited. Those who choose to access this Site outside the United States do so on their own
						initiative and are responsible for compliance with local laws.
					</p>
				</div>

				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Governing Law</h2>
					<p>
						You agree that your use of this Site, this Agreement and any disputes relating thereto shall be governed in
						all respects by the laws of the State of Illinois, United States of America. Any dispute relating to this
						Agreement shall be resolved solely in the state or US federal courts located in Chicago, Illinois.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Contacting Us</h2>
					<p>
						We are committed to handling your customer information with high standards of information security. Your
						credit or debit card information is transferred in encrypted form (banking – grade SSL 256-bit or higher
						technology) to our credit card payment processor directly; we may also store the last 4 digits of your card
						number, expiration date, and transaction confirmation information we receive form our credit card processor
						as proof of payment. We restrict access to your personally identifiable information to employees who need to
						know that information in order to provide products or services to you. We maintain physical, electronic, and
						procedural safeguards to guard your nonpublic personal information. Any e-mail or other communication asking
						you to provide sensitive account information via email, or linking to a website prompting you for sensitive
						account information with a URL that does not begin with https://dentalpracticetoday.com/ should be treated as
						unauthorized and suspicious and should be reported to us immediately.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">
						Accessing and Changing Your Information
					</h2>
					<p>
						If you have any questions about these Terms of use or your dealings with us, you can contact us by email at
						info at dentalpracticetoday.com, by phone at (847) 756-7962 Monday through Friday between the hours of 9:00am and 4:00pm
						CST (with the exception of legal holidays), or by postal mail at Dental Practice Today, 1600 Golf Road Suite
						1200, Rolling Meadows, IL 60008, United States of America.
					</p>
				</div>
			</div>
		</template>
	</AlertModalSkeleton>
</template>
<script lang="ts" setup>
const { modelValue = true } = defineProps<{
	modelValue: boolean;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
	(event: 'close', value: boolean): void;
}>();

const isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => {
		emit('update:modelValue', value), emit('close', value);
	},
});
</script>
