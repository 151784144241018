<template>
	<div class="flex flex-row space-x-2">
		<button
			type="button"
			class="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
			:disabled="isLoading"
			@click="handlePostLike()"
		>
			<HandThumbUpIcon class="h-5 w-5" aria-hidden="true" />
			<span class="font-medium text-gray-900">{{ postLikes }}</span>
			<span class="sr-only">likes</span>
		</button>
	</div>
</template>
<script lang="ts" setup>
import { ItemID } from '@/types';
import { HandThumbUpIcon } from '@heroicons/vue/24/outline';

const { likes, postID } = defineProps<{ likes: number; postID: ItemID }>();

let postLikes: number = $ref(likes);
let isLoading: boolean = $ref(false);

async function handlePostLike() {
	isLoading = true;
	try {
		await LikeNewsItem(postID);
		postLikes++;
	} catch (error) {
		console.error(error);
	} finally {
		isLoading = false;
	}
}
</script>
