<template>
	<div class="flex flex-col w-full">
		<n-form ref="formRef" :rules="rules" :model="payment">
			<n-form-item class="col-span-3" label="Card Type:" path="PaymentType">
				<n-select
					v-model:value="payment.PaymentType"
					:options="transformedCardTypes"
					placeholder="Please select your Card Type"
				/>
			</n-form-item>	
			<n-form-item label="Card Number" path="AccountNumber">
				<n-input
					v-model:value="payment.AccountNumber"
					:maxlength="16"
					:allow-input="(value) => /^[0-9]*$/.test(value)"
				/>
			</n-form-item>
			<n-form-item label="Expiration:" path="Expiration">
				<div class="w-full flex flex-row space-x-2">
					<n-select
						v-model:value="payment.ExpirationMonth"
						placeholder="Month"
						:options="transformDataToLabelAndValue(paymentStore.paymentInfo.Months, 'Prompt')"
					/>
					<n-select
						v-model:value="payment.ExpirationYear"
						placeholder="Year"
						:options="transformDataToLabelAndValue(paymentStore.paymentInfo.Years, 'Prompt')"
					/>
				</div>
			</n-form-item>
			<n-form-item label="Security Code:" path="SecurityCode">
				<n-input v-model:value="payment.SecurityCode" :maxlength="4" :allow-input="(value) => /^[0-9]*$/.test(value)" />
			</n-form-item>
			<div class="w-full text-center col-span-3">
				<span v-if="errorMessage" class="text-red-500">{{ errorMessage }}</span>
				<n-button type="primary" :loading="isLoading" class="uppercase" @click.prevent="verifyCard">Verify</n-button>
			</div>
		</n-form>
	</div>
</template>
<script setup lang="ts">
import { transformDataToLabelAndValue } from '@/plugins/helpers';
import { usePaymentsStore } from '@/store/Payments';
import { IPaymentInstrument } from '@/types/Payments';
import { FormInst, FormItemRule } from 'naive-ui';

const { modelValue } = defineProps<{
	modelValue: IPaymentInstrument;
}>();

const payment = $ref({ ...modelValue });

const transformedCardTypes = $computed(() => transformDataToLabelAndValue(paymentStore.paymentInfo.CardTypes, 'Prompt'));

const emit = defineEmits<{
	(event: 'update:modelValue', value: IPaymentInstrument): void;
	(event: 'valid'): void;
}>();

let formRef = $ref<FormInst | null>(null);
let isLoading = $ref(false);
let errorMessage = $ref('');

const paymentStore = usePaymentsStore();

async function verifyPayment() {
	isLoading = true;
	try {
		await paymentStore.validatePaymentInstrument(payment);
		emit('update:modelValue', payment);
		emit('valid');
	} catch (error) {
		errorMessage = (error as Error).message;
	} finally {
		isLoading = false;
	}
}

function verifyCard() {
	formRef?.validate((errors) => {
		if (errors?.length) {
			console.error(errors);
			return;
		}
		verifyPayment();
	});
}

const rules = {
	AccountNumber: {
		required: true,
		validator: (_: FormItemRule, value: any) => {
			return value !== undefined && value.length !== 0;
		},
	},
	Expiration: {
		required: true,
		validator: (_: FormItemRule, _value: any) => {
			return payment.ExpirationMonth !== undefined && payment.ExpirationYear !== undefined;
		},
	},
	SecurityCode: {
		required: true,
		validator: (_: FormItemRule, value: any) => {
			return value !== undefined && value.length !== 0;
		},
	},
};
</script>
<style scoped>
:deep(.n-button) {
	width: 100%;
}
</style>
