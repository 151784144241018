<template>
	<FormOverlay
		v-model="isOpenModal"
		model-class="space-y-3 bg-white md:rounded-lg p-6 w-1/4 text-left overflow-hidden shadow-xl transform transition-all xs:my-8"
		:title="title"
		submit-button-text="Search"
		submit-on-enter
		@submit="submitForm"
		@close="closeModal"
	>
		<n-form-item class="col-span-3">
			<n-input v-model:value="searchValue" placeholder="Please input for search" />
		</n-form-item>
	</FormOverlay>
</template>
<script setup lang="ts">
const { modelValue = false, title = '' } = defineProps<{
	modelValue: boolean;
	title: string;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
	(event: 'update:searchValue', value: string): void;
}>();

const isOpenModal = $computed({
	get: () => modelValue,
	set: () => {
		emit('update:modelValue', false);
	},
});

let searchValue = $ref('');

function submitForm() {
	emit('update:searchValue', searchValue);
	closeModal();
}

function closeModal() {
	emit('update:modelValue', false);
}
</script>
