import { Cultures } from '@/modules/validation';
import { Category, CategoryKey, CouponType, CouponTypes, SponsorshipType, Tag } from '@/types/constants';
import { ICountry } from '@/types/Generic';

export function runWithDelay(functionToExecute: TimerHandler): void {
	setTimeout(functionToExecute, 5000);
}

export function changeNamesToUpperCase(
	names: Array<{ id: number; name: string }>
): Array<{ id: number; name: string }> {
	return names.map((item) => {
		return { id: item.id, name: item.name.charAt(0).toUpperCase() + item.name.slice(1).replaceAll('_', ' ') };
	});
}

export function changeNamesToSnakeCase(names: string[]): string[] {
	return names.map((name) => {
		return name.charAt(0).toLowerCase() + name.slice(1).replaceAll(' ', '_');
	});
}

export function scrollToFirstError(errors: { [key: string]: string }): void {
	const firstErrorKey = Object.entries(errors).find(([, value]) => value !== '')?.[0];
	if (firstErrorKey) {
		scrollToId(firstErrorKey.replace('Error', ''));
	}
}

// export function scrollToFirstErrorWithIndex(errors: {[a: string]: string}): void {
//   for (let i = 0; i < a.length; i++) {
//     const firstErrorKey = Object.entries(a[i]).find(([, value]) => value !== '')?.[0];
//     if (firstErrorKey) {
//       scrollToId(`${firstErrorKey.replace('Error', '')}-${i}`);
//       break;
//     }
//   }
// }

export function scrollToId(id: string): void {
	if (!id) return;
	const element = document.getElementById(id);
	if (!element) throw new ReferenceError(`No element found with id: ${id}`);
	element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

export function getRandomId(): string {
	return `DPT_${Math.random().toString(36).substring(2, 15)}`;
}

export function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
	return Object.prototype.hasOwnProperty.call(obj, prop);
}

export async function wait(ms: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getCategoryName(categoryNumber: number) {
	const indexOfCategory = Category[categoryNumber];
	return indexOfCategory;
}

type MapValuesToKeysIfAllowed<T> = {
	[K in keyof T]: T[K] extends PropertyKey ? K : never;
};
type Filter<T> = MapValuesToKeysIfAllowed<T>[keyof T];

export function groupBy<T extends Record<PropertyKey, any>, Key extends Filter<T>>(
	arr: T[],
	key: Key
): Record<T[Key], T[]> {
	return arr.reduce((accumulator, val) => {
		const groupedKey = val[key];
		if (!accumulator[groupedKey]) {
			accumulator[groupedKey] = [];
		}
		accumulator[groupedKey].push(val);
		return accumulator;
	}, {} as Record<T[Key], T[]>);
}

export function convertNumberToPowerOfTwo(number: number) {
	let numberSave = number;
	const powerOfTwo = [];
	while (numberSave > 0) {
		powerOfTwo.push(numberSave % 2);
		numberSave = Math.floor(numberSave / 2);
	}
	return powerOfTwo;
}

export function getTagsByCategoryAndNumber(category: Category, powerOfTwo: Array<number>) {
	let multiplier = 0;
	if (category === Category.Classifieds) {
		multiplier = 8;
		powerOfTwo = powerOfTwo.slice(multiplier, powerOfTwo.length);
	}

	const tags: Tag[] = [];
	powerOfTwo.map((tag, index) => {
		if (tag === 1) {
			tags.push(Tags[2 ** (index + multiplier)] as Tag);
		}
	});
	return tags;
}

export function getTypeOfCoupon(couponType: number): CouponType[] {
	const powerOfTwo = convertNumberToPowerOfTwo(couponType);
	const types: CouponType[] = [];
	powerOfTwo.map((type, index) => {
		if (type === 1) {
			types.push(CouponTypes[2 ** index] as CouponType);
		}
	});
	return types;
}

export function getTypeOfSponsorship(sponsorshipType: number): SponsorshipType[] {
	const powerOfTwo = convertNumberToPowerOfTwo(sponsorshipType);
	const types: SponsorshipType[] = [];
	powerOfTwo.map((type, index) => {
		if (type === 1) {
			types.push(SponsorshipTypes[2 ** index] as SponsorshipType);
		}
	});
	return types;
}

export function convertTagsToNumber(tags: Tag[]) {
	return tags.reduce((accumulator, currentValue) => accumulator + Tags[currentValue] || 0, 0);
}

export function convertTypesToNumber(type: CouponType[]) {
	return type.reduce((accumulator, currentValue) => accumulator + CouponTypes[currentValue] || 0, 0);
}

export function separateMultiwordTag(tag: string) {
	return tag.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function getCategories() {
	return Object.values(Category).filter((x) => typeof x === 'string') as CategoryKey[];
}

export function getCategoryValue(categoryName: string) {
	return Object.entries(Category)
		.filter((x) => typeof x[0] === 'string' && x[0] === capitalizeText(categoryName))
		.flat()[1] as Category;
}

export function getDeepCopy(object: any) {
	return JSON.parse(JSON.stringify(object));
}

export function filenameGenerate(name: string) {
	return `${name.replace(/ /g, '_')}_${new Date().toISOString()}`;
}

export function parseCurrency(input: string, culture: Cultures) {
	const nums = input.replace(/(,|\$|\£|\s)/g, '').trim();
	const regex = /^\d+(\,\d{1,2})?$/;
	if (regex.test(nums)) return Number(nums);
	return nums === '' ? null : Number.NaN;
}

export function formatCurrency(value: number | null, culture: Cultures) {
	if (value === null) return '';
	return culture === 'en-gb' ? `\u{A3} ${value.toLocaleString('en-GB')}` : `${value.toLocaleString('en-US')} \u{24}`;
}

export function moveItemUp(array: any[], index: number) {
	if (index === 0) return;
	const temp = array[index - 1];
	array[index - 1] = array[index];
	array[index] = temp;
	return increaseSequence(array);
}

export function moveItemDown(array: any[], index: number) {
	if (index === array.length - 1) return;
	const temp = array[index + 1];
	array[index + 1] = array[index];
	array[index] = temp;
	return increaseSequence(array);
}

export function deleteItem(array: any[], index: number) {
	array.splice(index, 1);
	return increaseSequence(array);
}

export function increaseSequence(array: any[]) {
	return array.forEach((x, i) => (x.Sequence = i + 1));
}

export function getCultureByCountryID(countries: ICountry[], countryID: number) {
	return (countries.find((c) => c.ID === countryID)?.Culture ?? 'en-us') as Cultures;
}

export function getCountryIDByCulture(countries: ICountry[], culture: Cultures = 'en-us') {
	return countries.find((country) => country.Culture === culture)?.ID;
}

export function getFirstTagName(tag: number, separate: boolean = true) {
	const tags = convertNumberToPowerOfTwo(tag);
	const index = tags.indexOf(1);
	const firstTag = index !== -1 ? Tags[2 ** index] : 'None';
	return separate ? separateMultiwordTag(firstTag) : firstTag;
}

export function getExponent(number: number) {
	return convertNumberToPowerOfTwo(number).indexOf(1) - 1;
}

export function getFieldIfExists<T>(fieldValue: T) {
	return fieldValue ?? 'Unknown';
}

export function capitalizeText(text: string) {
	return text[0].toUpperCase() + text.slice(1);
}

export function transformDataToLabelAndValue(data: any[], labelProperty: string = 'Name') {
	return data.map((item) => {
		return {
			label: item[labelProperty],
			value: item.ID,
		};
	});
}
