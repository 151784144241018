import { ISponsorAdInfo } from './../types/Sponsorship';

export const useAdStore = defineStore('sponsored-ads', {
	state: () => ({
		ads: [] as ISponsorAdInfo[],
		currentAd: {} as ISponsorAdInfo,
	}),
	actions: {
		async getSponsoredAd(): Promise<ISponsorAdInfo | void> {
			try {
				const res = await getSponsoredAd();
				if (res?.ID > 0) {
					this.ads.push(res);
				} 					
				this.currentAd = res;
			} catch (error) {
				throw handleError(error);
			}
		},
	},
});
