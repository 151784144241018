export const AllAuthorType = 15;
export const AllSponsorshipType = 14;
export const DiamondSponsorship = 3;

export enum Category {
	News = 1,
	Blogs = 2,
	Clinical = 3,
	Imaging = 4,
	Management = 5,
	Insurance = 6,
	Collections = 7,
	Transitions = 8,
	Staffing = 9,
	Compliance = 10,
	Marketing = 11,
	Finance = 12,
	Classifieds = 13,
	SponsoredAds = 14,
}

export enum NormalTags {
	Techniques = 1, // (all except classifieds)
	Products = 2, // (all except classifieds)
	BestPractices = 4, // (all except classifieds)
	Automation = 8, // (all except classifieds)
	Economics = 16, // (all except classifieds)
	Announcements = 32, // (all except classifieds)
	Events = 64, // (all except classifieds)
}

export enum ClassifiedTags {
	JobOffers = 256, // (for classifieds)
	JobSeekers = 512, // (for classifieds)
	Partnerships = 1024, // (for classifieds)
	PraceticeSales = 2048, // (for classifieds)
	PracticeBuys = 4096, // (for classifieds)
}

export enum Tags {
	Nothing = 0, // Nothing selected (Other for classifieds)
	Techniques = 1, // (all except classifieds)
	Products = 2, // (all except classifieds)
	BestPractices = 4, // (all except classifieds)
	Automation = 8, // (all except classifieds)
	Economics = 16, // (all except classifieds)
	Announcements = 32, // (all except classifieds)
	Events = 64, // (all except classifieds)
	Empty = 128, // ****(not defined)
	JobOffers = 256, // (for classifieds)
	JobSeekers = 512, // (for classifieds)
	Partnerships = 1024, // (for classifieds)
	PraceticeSales = 2048, // (for classifieds)
	PracticeBuys = 4096, // (for classifieds)
}

export enum AuthorTypes {
	Bloggers = 1,
	Advertisers = 2, // (for classifieds)
	Sponsors = 4,
	Admins = 8,
}

export enum PollInfoTypes {
	Boolean = 1, // (Yes / No)
	Number = 2, // (0..32767)
	Date = 3, // (ISO date string)
	Money = 4, // ($xx.xx)
	Text = 5, // (up to 1024 characters)
}

export enum SponsorshipTypes {
	None = 0,
	Gold = 2,
	Platinum = 4,
	Diamond = 8,
}

export enum SponsorshipFee {
	Gold = 295,
	Platinum = 995,
	Diamond = 2495,
}

export enum CouponTypes {
	Classifieds = 1,
	GoldSponsors = 2,
	PlatinumSponsors = 4,
	DiamondSponsors = 8,
}

export enum CardTypes {
	VISA = 1,
	MASTERCARD = 2,
	AMEX = 3,
	DISCOVER = 4,
	DINERSCLUB = 5,
	JCB = 6,
}

export enum AchTypes {
	Checking = 7,
	Savings = 8,
	BusinessChecking = 9,
	BusinessSavings = 10,
}

export enum PaymentTypes {
	VISA = 1,
	MASTERCARD = 2,
	AMEX = 3,
	DISCOVER = 4,
	DINERSCLUB = 5,
	JCB = 6,
	Checking = 7,
	Savings = 8,
	BusinessChecking = 9,
	BusinessSavings = 10,
}

export enum OrganizationTypes {
	Bloggers = 1,
	Advertisers = 2,
	Sponsors = 4,
	Admins = 8,
}

export enum ImageDimensions {
	AuthorPicture = 480,
	BlogPicture = 768,
}

export enum ImageAspectRatios {
	Standard = 4 / 3,
}

export enum BaseEndpoints {
	Login = 'Login',
	ForgotPassword = 'ForgotPassword',
	CheckSecurity = 'CheckSecurity',
	ResetPassword = 'ResetPassword',
	GetNewsItems = 'GetNewsItems',
	GetNewsItemInfo = 'GetNewsItemInfo',
	LikeNewsItem = 'LikeNewsItem',
	GetSponsorAdInfo = 'GetSponsorAdInfo',
	GetPaymentInfo = 'GetPaymentInfo',
	GetPollInfo = 'GetPollInfo',
	SubmitPollEntry = 'SubmitPollEntry',
	NewAuthorRequest = 'NewAuthorRequest',
	GetCountries = 'GetCountries',
	GetTimeZones = 'GetTimeZones',
	GetActiveSponsors = 'GetActiveSponsors',
	GetStates = 'GetStates',
	GetAuthorInfo = 'GetAuthorInfo',
	SubscriptionRequest = 'SubscriptionRequest',
	ValidatePaymentInstrument = 'ValidatePaymentInstrument',
}

export enum SecureEndpoints {
	GetAuthor = 'GetAuthor',
	GetAuthors = 'GetAuthors',
	GetCoupon = 'GetCoupon',
	GetCoupons = 'GetCoupons',
	GetCouponUsage = 'GetCouponUsage',
	GetOrganization = 'GetOrganization',
	GetOrganizations = 'GetOrganizations',
	GetPaymentsOnFile = 'GetPaymentsOnFile',
	GetPoll = 'GetPoll',
	GetPollResults = 'GetPollResults',
	GetPolls = 'GetPolls',
	GetSponsorships = 'GetSponsorships',
	GetSubmission = 'GetSubmission',
	GetSubmissions = 'GetSubmissions',
	SaveAuthor = 'SaveAuthor',
	SaveCoupon = 'SaveCoupon',
	SaveOrganization = 'SaveOrganization',
	SavePoll = 'SavePoll',
	SaveSponsorship = 'SaveSponsorship',
	SaveSubmission = 'SaveSubmission',
	UpdateSponsorship = 'UpdateSponsorship',
	GetNewsletterSubscriptions = 'GetNewsletterSubscriptions',
	GetForumSubscriptions = 'GetForumSubscriptions',
	GetContactRequests = 'GetContactRequests',
}

export type Tag = keyof typeof Tags;
export type CategoryKey = keyof typeof Category;
export type AuthorType = keyof typeof AuthorTypes;
export type CouponType = keyof typeof CouponTypes;
export type AchType = keyof typeof AchTypes;
export type SponsorshipType = keyof typeof SponsorshipTypes;
