<template>
	<div
		class="flex flex-col w-full p-6 my-4 transition-shadow bg-white shadow-md shadow-stone-300 hover:shadow-lg space-y-4"
	>
		<n-skeleton text size="medium" />
		<div class="flex flex-col">
			<n-skeleton text size="large" :repeat="4" />
		</div>
	</div>
</template>
