import { ICheckSecurity, IForgotPassword, IResetPassword } from '@/types/Auth';
import { IAuthorInfo } from '@/types/Author';
import { AuthorTypes } from '@/types/constants';
import { MD5 } from 'crypto-js';

export interface loginPayload {
	username: string;
	password: string;
}

export async function login({ username, password }: loginPayload, type: AuthorTypes) {
	const loginResponse = await baseService.post<{ CountryID: number; AuthorID: number }>(BaseEndpoints.Login, {
		UserName: username,
		PwdHash: MD5(password).toString(),
		Type: type,
	});

	if (loginResponse?.AuthorID ?? 0 > 0) {
		const [user] = await baseService.post<[IAuthorInfo, number]>(SecureEndpoints.GetAuthor, {
			ID: 0,
		});

		if (user?.ID ?? 0 > 0) {
			localStorage.setItem('AuthorID', String(user.ID));
			user.IsAdmin = (user.Types & AuthorTypes.Admins) == AuthorTypes.Admins;
			user.IsBlogger = user.IsAdmin || (user.Types & AuthorTypes.Bloggers) == AuthorTypes.Bloggers;
			user.IsAdvertiser = user.IsAdmin || (user.Types & AuthorTypes.Advertisers) == AuthorTypes.Advertisers;
			user.IsSponsor = user.IsAdmin || (user.Types & AuthorTypes.Sponsors) == AuthorTypes.Sponsors;
		} else {
			localStorage.setItem('AuthorID', '0');
		}

		return user;
	} else {
		return null;
	}
}

export function ForgotPassword(userInformation: IForgotPassword): Promise<[string, number]> {
	baseService.setIsPayloadCompressed(false);
	return baseService.post(BaseEndpoints.ForgotPassword, userInformation);
}

export function CheckSecurity(securityInformation: ICheckSecurity): Promise<[string, number]> {
	baseService.setIsPayloadCompressed(false);
	return baseService.post(BaseEndpoints.CheckSecurity, securityInformation);
}

export function ResetPassword(resetPasswordInformation: IResetPassword) {
	return baseService.post(BaseEndpoints.ResetPassword, {
		UserName: resetPasswordInformation.UserName,
		PwdHash: MD5(resetPasswordInformation.PwdHash).toString(),
		ResetToken: resetPasswordInformation.ResetToken,
	});
}
