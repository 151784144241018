import { RequestByItemID } from '@/types';
import { IPollInfo, IPollItemRequest, IPollEntry, IPollResults, IPollSave } from './../types/Polls';

export async function getPollInfo(): Promise<IPollInfo> {
	try {
		const pollID = getStorageItem('lastPoll');
		const [pollInfo] = await baseService.post<[IPollInfo, number]>(BaseEndpoints.GetPollInfo, {
			ID: Number(pollID) || 0,
		});
		if (pollInfo?.ID > 0) {
			setStorageItem('lastPoll', pollInfo.ID);
		}
		return pollInfo;
	} catch (error) {
		throw handleError(error);
	}
}

export async function getPoll(ID: number): Promise<IPollInfo> {
	try {
		const [poll] = await baseService.post<[IPollInfo, number]>(SecureEndpoints.GetPoll, {
			ID: Number(ID) || 0,
		});
		if (!poll) {
			throw new Error('No data');
		}
		return poll;
	} catch (error) {
		throw handleError(error);
	}
}

export async function getPolls(pagination: IPollItemRequest): Promise<[IPollInfo[], number]> {
	try {
		return await baseService.post(SecureEndpoints.GetPolls, pagination);
	} catch (error) {
		throw handleError(error);
	}
}

export async function getPollResults(requestID: RequestByItemID): Promise<[IPollResults, number]> {
	try {
		return await baseService.post(SecureEndpoints.GetPollResults, requestID);
	} catch (error) {
		throw handleError(error);
	}
}

export async function savePoll(poll: IPollSave): Promise<IPollSave> {
	try {
		return await baseService.post(SecureEndpoints.SavePoll, { Payload: poll });
	} catch (error) {
		throw handleError(error);
	}
}

export async function submitPollEntry(pollEntry: IPollEntry): Promise<void> {
	try {
		await baseService.post(BaseEndpoints.SubmitPollEntry, { Payload: pollEntry });
	} catch (error) {
		throw handleError(error);
	}
}
