<template>
	<footer class="w-full border-t bg-white sticky top-[100vh]">
		<div class="container flex flex-col items-center w-full mx-auto">
			<div class="flex flex-col py-6 text-center md:flex-row md:text-left md:justify-between">
				<router-link :to="{ name: routeNames.about }" class="px-3 uppercase">About Us</router-link>
				<p class="px-3 uppercase cursor-pointer" @click="isPrivacyModalOpen = true">Privacy Policy</p>
				<p class="px-3 uppercase cursor-pointer" @click="isTermsModalOpen = true">Terms & Conditions</p>
				<router-link :to="{ name: routeNames.sponsors }" class="px-3 uppercase">Sponsors</router-link>
				<router-link :to="{ name: routeNames.classifieds }" class="px-3 uppercase">Advertise</router-link>
				<div class="pb-6 uppercase">&copy; Dental Practice Today</div>
			</div>
		</div>
	</footer>
	<PrivacyModal v-model="isPrivacyModalOpen" />
	<TermsModal v-model="isTermsModalOpen" />
</template>
<script lang="ts" setup>
import { routeNames } from '@/router';

let isPrivacyModalOpen = ref(false);
let isTermsModalOpen = ref(false);
</script>
