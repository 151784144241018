<script lang="ts" setup>
import { useNewsStore } from '@/store/news';
import { getFirstTagName } from '@/plugins/helpers';
import { INewsItem } from '@/types/News';
import { useAuthenticationStore } from '@/store/auth';
import { Category } from '@/types/constants';
import { ISubmissionItem } from '@/types/Submission';
import { isEqual } from 'lodash';
import { usePollStore } from '@/store/polls';
import { useAdStore } from '@/store/sponsored-ads';

const { category } = defineProps<{ category: number }>();

const router = useRouter();
const newsStore = useNewsStore();
const authStore = useAuthenticationStore();
const pollStore = usePollStore();
const sponsorAdStore = useAdStore();
const store = useStore();

let queryTag: number = $ref(0);
let isLoading = $ref(false);
const user = $computed(() => authStore.user);
let defaultSubmissionToCreate = $ref({
	ID: 0,
	TopicID: 0,
	ReplyToID: 0,
	AuthorID: user?.ID,
	Category: category,
	IsEnabled: false,
	Body: '',
	Headline: '',
	Picture: '',
} as ISubmissionItem);
let isSaveSubmissionOpen = $ref(false);
const querySearch = $computed(() => String(router.currentRoute.value.query.search ?? ''));
let actionType = $ref('Save');
let submissionToUpdate: ISubmissionItem = $ref({ ...defaultSubmissionToCreate });
const pagination = $ref({
	Skip: 0,
	Take: 25,
	Search: querySearch,
});
let scrolledValue = $ref(0);
let scrollComponent = $ref<HTMLElement>();
let grouppedNewsByTag: { [key: string]: INewsItem[] } = $ref({});

const searchValue = $computed({
	get: () => pagination.Search,
	set: (value) => {
		router.push({ name: String(router.currentRoute.value.name), query: { search: value } });
		pagination.Search = value;
		pagination.Skip = 0;
		getNews();
	},
});
const news = $computed(() => newsStore.news);
const pageTitle = $computed(() => (category === Category.Blogs ? 'Blog posts' : 'News'));
const createButtonTitle = $computed(() => `Add ${category === Category.Blogs ? 'blog post' : 'news item'}`);
const isBlogger = $computed(() => authStore.user?.IsBlogger);
const isAdmin = $computed(() => authStore.user?.IsAdmin);
const actionTitle = $computed(() => (actionType === 'Save' ? `Add ${pageTitle}` : `Update ${pageTitle}`));
const isAllowedToCreatePost = $computed(() => (category === Category.News && isAdmin) || category === Category.Blogs);
const currentTag = $computed(() => router.currentRoute.value.query?.tag ?? '');
const searchTitle = $computed(() => {
	if (Category.News === category) return 'Search news for:';
	return 'Search blogs for:';
});

function groupNewsByTag() {
	grouppedNewsByTag = {};
	const loadedNews = news;
	loadedNews.forEach((item: INewsItem) => {
		const tagName = getFirstTagName(item.Tags, false);
		if (!grouppedNewsByTag[tagName]) {
			grouppedNewsByTag[tagName] = [];
		}
		grouppedNewsByTag[tagName].push({ ...item });
	});
}

async function getNewsByID(id: number) {
	try {
		const resp = await newsStore.getNewsItemInfo(id);
		submissionToUpdate = {
			ID: resp.ID,
			AuthorID: resp.AuthorID,
			Body: resp.Body,
			Headline: resp.Headline,
			Picture: resp.Picture,
			Location: resp.Location,
			Tags: resp.Tags,
			LinkUrl: resp.LinkUrl,
			NoteToEditor: '',
			IsEnabled: true,
			ReplyToID: resp.ReplyToID,
			Category: resp.Category,
		} as ISubmissionItem;
	} catch (err) {
		console.log(err);
	}
}

async function openUpdateModal(article: INewsItem) {
	await getNewsByID(article.ID);
	actionType = 'Update';

	isSaveSubmissionOpen = true;
}

function submitModal() {
	isSaveSubmissionOpen = false;
	getNews();
}

async function getNews() {
	if (!pagination.Skip) isLoading = true;
	queryTag = Number(router.currentRoute.value.query?.tag ?? 0);
	try {
		await newsStore.getNews({
			Category: category,
			TopicID: 0,
			Starting: null,
			Skip: pagination.Skip,
			Take: pagination.Take,
			Tag: queryTag,
			Search: searchValue,
		});
		groupNewsByTag();
	} catch (err) {
		console.error(err);
	} finally {
		isLoading = false;
	}
}

watch(
	() => [currentTag],
	(value, oldValue) => {
		if (isEqual(value, oldValue)) return;
		if (router.currentRoute.value.meta?.category === category) {
			pagination.Skip = 0;
			getNews();
		}
	},
	{ immediate: true }
);

async function loadMoreData() {
	pagination.Skip += pagination.Take;
	getNews();
}

async function handleScroll() {
	if (isLoading) return;
	let element = scrollComponent as HTMLElement;
	if (element.offsetHeight + element.scrollTop + element.offsetHeight * 0.25 >= element.scrollHeight + scrolledValue) {
		scrolledValue = element.offsetHeight;
		if (newsStore.news.length < pagination.Skip) return;
		loadMoreData();
	}
}

function handleCreateSubmission() {
	if (category === Category.Blogs && !isBlogger && !isAdmin) {
		store.signUpAuthorType = AuthorTypes.Bloggers;
		store.isSignUpModalOpen = true;
		console.log('not logged in');
		return;
	}
	actionType = 'Save';
	submissionToUpdate = { ...defaultSubmissionToCreate };
	isSaveSubmissionOpen = true;
}

onMounted(() => {
	window.addEventListener('scroll', handleScroll);
});
onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll);
});

const isSidebarEmpty = $computed(() => pollStore.currentPoll.ID === 0 && sponsorAdStore.currentAd.ID === 0);
</script>

<template>
	<section
		ref="scrollComponent"
		class="flex flex-col items-center w-full space-y-4 lg:pr-3"
		:class="{
			'md:w-2/3': !isSidebarEmpty,
		}"
	>
		<IndexPageSkeleton
			v-if="isLoading"
			:is-like-visible="category !== Category.News"
			:is-allowed-to-create-post="isAllowedToCreatePost"
		/>
		<div v-else class="flex flex-col w-full">
			<div v-if="isAllowedToCreatePost" class="flex flex-row items-center justify-between w-full my-4">
				<h1 class="text-2xl font-extrabold tracking-tight text-center text-gray-700 capitalize">
					{{ pageTitle }}
				</h1>
				<div class="flex flex-row items-center space-x-5">
					<BaseSearch v-model:search-value="searchValue" :title="searchTitle" />
					<n-button type="primary" @click="handleCreateSubmission">{{ createButtonTitle }}</n-button>
				</div>
			</div>
			<div v-if="category === Category.News && !isAdmin" class="flex justify-end w-full">
				<BaseSearch v-model:search-value="searchValue" :title="searchTitle" />
			</div>
			<template v-for="(value, key) in grouppedNewsByTag" :key="key">
				<TheArticle
					v-for="newsItem in value"
					:key="newsItem.ID"
					:content="newsItem"
					:tag-name="key as string"
					:is-like-visible="category !== Category.News"
					@open-update-modal="openUpdateModal(newsItem)"
				/>
			</template>
			<BaseTable v-if="Object.keys(grouppedNewsByTag).length === 0 && !isLoading" :data="[]" />
		</div>
	</section>
	<SubmissionModal
		v-if="isSaveSubmissionOpen"
		v-model="isSaveSubmissionOpen"
		:title="`${actionTitle} by ${authStore.user?.UserName}`"
		:submission="submissionToUpdate"
		:submit-button-text="actionType"
		@published="getNews()"
		@submit="submitModal"
	/>
</template>
