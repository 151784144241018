import {
	IActiveSponsors,
	IGetOrganizationRequest,
	IOrganizationInfo,
	IActiveSponsorsRequest,
} from '@/types/Organizations';

export async function GetOrganizations(Payload: IGetOrganizationRequest): Promise<[IOrganizationInfo[], number]> {
	try {
		return baseService.post(SecureEndpoints.GetOrganizations, Payload);
	} catch (error) {
		throw handleError(error);
	}
}

export async function GetOrganization(id: number): Promise<[IOrganizationInfo, number]> {
	try {
		return baseService.post(SecureEndpoints.GetOrganization, { ID: id });
	} catch (error) {
		throw handleError(error);
	}
}

export async function SaveOrganization(organization: IOrganizationInfo): Promise<void> {
	try {
		return baseService.post(SecureEndpoints.SaveOrganization, {
			Payload: organization,
		});
	} catch (error) {
		throw handleError(error);
	}
}

export async function GetActiveSponsors(request: IActiveSponsorsRequest): Promise<[IActiveSponsors[], number]> {
	try {
		return baseService.post(BaseEndpoints.GetActiveSponsors, request);
	} catch (error) {
		throw handleError(error);
	}
}
