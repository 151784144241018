<template>
	<BaseOverlay v-model="isOpenModal" :modal-class="modelClass">
		<template #title>
			<slot name="title">
				<div>
					<h1 class="mb-4 text-lg font-medium text-gray-900 capitalize">{{ title }}</h1>
				</div>
			</slot>
		</template>
		<template #body>
			<n-form
				id="form-inner"
				ref="formRef"
				:model="model"
				:disabled="disabled || loading"
				:rules="rules"
				:label-placement="labelPlacement"
				label-width="auto"
				class="container grid grid-cols-3 gap-3 mx-auto"
				@keydown.enter.prevent="submitOnEnter ? submit() : null"
			>
				<slot></slot>
			</n-form>
			<!-- <n-form
				class="grid grid-rows-1 grid-cols-1 sm:grid-rows-[repeat(12,_minmax(0,_1fr))] sm:grid-cols-2 lg:grid-rows-[repeat(8,_minmax(0,_1fr))] lg:grid-cols-3 gap-4 w-full"
			>
				<slot></slot>
			</n-form> -->
		</template>
		<template #footer>
			<slot name="footer"></slot>
			<div class="flex justify-end w-full gap-x-4 items-center">
				<NButton v-if="!isCancelHidden" tertiary type="primary" @click="close">Cancel</NButton>
				<NButton :disabled="disabled || isSubmitDisabled" :loading="loading" type="primary" @click="submit">{{
					submitButtonText
				}}</NButton>
			</div>
		</template>
	</BaseOverlay>
</template>

<script lang="ts" setup>
import { FormRules, FormInst } from 'naive-ui';
import { LabelPlacement } from 'naive-ui/es/form/src/interface';

const {
	modelClass = '',
	modelValue = false,
	loading = false,
	disabled = false,
	title = '',
	rules = [],
	isCancelHidden = false,
	labelPlacement = 'top',
	submitButtonText = 'Submit',
	model = {},
	submitOnEnter = false,
	isSubmitDisabled = false,
} = defineProps<{
	modelClass?: string;
	modelValue: boolean;
	disabled?: boolean;
	loading?: boolean;
	submitButtonText?: string;
	title?: string;
	rules?: FormRules;
	labelPlacement?: LabelPlacement;
	isCancelHidden?: boolean;
	model?: Object;
	submitOnEnter?: boolean;
	isSubmitDisabled?: boolean;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
	(event: 'submit'): void;
	(event: 'close'): void;
}>();

let formRef = $ref<FormInst | null>(null);
let isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => emit('update:modelValue', value),
});
function close() {
	emit('close');
}
function submit() {
	if (rules.length !== 0) {
		formRef?.validate((errors) => {
			if (errors?.length) {
				return;
			}
			emit('submit');
		});
		return;
	}
	emit('submit');
}
</script>
