import { Exception } from './exception';

export const unknownError = new Exception(
	-1,
	'Something went wrong, please try again later or contact customer support.'
);

export enum ErrorType {
	SystemError = -1,
	Success = 0,
	ServerNotAvailable = 1,
	InvalidCredentials = 2,
	InvalidApiKey = 3,
	SessionExpired = 4,
	InvalidData = 5,
	ConfigurationError = 6,
	TransmissionError = 7,
}

export const ErrorCodesMap = {
	'-1': 'SystemError',
	0: 'Success',
	1: 'ServerNotAvailable',
	2: 'InvalidCredentials',
	3: 'InvalidApiKey',
	4: 'SessionExpired',
	5: 'InvalidData',
	6: 'ConfigurationError',
	7: 'TransmissionError',
};
export const ErrorMessagesMap = {
	'-1': 'Something went wrong please try again later or contact customer support.', // SystemError
	0: 'Success', // Success
	1: 'Server is not available, please try again later.', // ServerNotAvailable
	2: 'Invalid credentials.', // InvalidCredentials
	3: 'Invalid API key.', // InvalidApiKey
	4: 'Session expired.', // SessionExpired
	5: 'Invalid data, please double check your inputs.', // InvalidData
	6: 'Configuration error, please contact customer support.', // ConfigurationError
	7: 'Transmission error, please contact customer support.', // TransmissionError
};

export function createException(error: Record<string, string | number>): Exception {
	if(error instanceof Exception) {
		return error;
	}
	if (error?.ErrorCode && error?.ErrorMessage) {
		return new Exception(Number(error?.ErrorCode), String(error?.ErrorMessage));
	}
	if (error?.ErrorCode) {
		return new Exception(Number(error?.ErrorCode), ErrorMessagesMap[error?.ErrorCode as keyof typeof ErrorMessagesMap]);
	}
	return unknownError;
}

export function handleError(error: unknown): Exception {
	return error instanceof Exception ? error : createException(error as Record<string, string | number>);
}
