<template>
	<FormOverlay
		v-model="isOpenModal"
		title="Payment Method"
		:disabled="isLoading || isCardTabActive ? isEmpty(cardInstrument) : !isAchInfoVerified"
		:loading="isLoading"
		:rules="isCardTabActive ? cardRules : achRules"
		:model="isCardTabActive ? cardInstrument : achInstrument"
		model-class="relative lg:max-w-[70%] space-y-3 bg-white md:rounded-lg p-6 text-left overflow-hidden shadow-xl transform transition-all xs:my-8"
		label-placement="left"
		submit-button-text="Select"
		@submit="submitForm"
		@close="closeModal"
	>
		<n-card class="col-span-3">
			<n-tabs
				class="card-tabs"
				default-value="card"
				size="large"
				animated
				pane-style="padding-left: 4px; padding-right: 4px; box-sizing: border-box;"
				@before-leave="beforeLeave"
			>
				<n-tab-pane v-if="paymentInfo.HasCards" name="card" tab="Credit or Debit Card">
					<PaymentCard @valid="setCardCredentials" @load="(value) => (isLoading = value)" />
				</n-tab-pane>
				<n-tab-pane v-if="paymentInfo.HasAch" name="ach" tab="Bank Transfer (ACH)">
					<PaymentACH
						v-model="achInstrument"
						:is-ach-info-verified="isAchInfoVerified"
						@update:culture="selectCulture"
						@verify="verify($event)"
					/>
				</n-tab-pane>
			</n-tabs>
		</n-card>
	</FormOverlay>
</template>
<script lang="ts" setup>
import { useAuthenticationStore } from '@/store/auth';
import { usePaymentsStore } from '@/store/payments';
import { IPaymentInstrument } from '@/types/Payments';
import { NCard, NTabs, NTabPane, FormItemRule } from 'naive-ui';
import { Cultures, isEmail } from '@/modules/validation';
import { isEmpty } from 'lodash';
import { IAuthorInfo } from '@/types/Author';

const { modelValue } = defineProps<{ modelValue: boolean }>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
	(event: 'payment-verified', value: IPaymentInstrument): void;
}>();

const paymentStore = usePaymentsStore();
const authStore = useAuthenticationStore();
const store = useStore();
const { createNotification } = useNotifications();

const paymentInfo = $computed(() => paymentStore.paymentInfo);

const isCardTabActive = $computed(() => activeTab === 'card');
const user = $computed(() => (authStore.isLoggedIn ? authStore.user : (inject('newAuthor') as IAuthorInfo)));

let activeTab = $ref('card');
let isAchInfoVerified = $ref(false);
let selectedCulture: Cultures = $ref(authStore.culture);

let cardInstrument: IPaymentInstrument = $ref({} as IPaymentInstrument);
let defaultAchInstrument: IPaymentInstrument = $ref({
	ID: 0,
	IsAch: true,
	PaymentType: 1,
	AccountNumber: '',
	RoutingAba: '',
	BankName: '',
	BankPhone: '',
	BillingName: (user?.FirstName ? user.FirstName + ' ' : '') + (user?.LastName ?? ''),
	ReceiptEmail: user?.Email ?? '',
	Address: user?.Address ?? '',
	Address2: user?.Address2 ?? '',
	Zip: user?.ZIP ?? '',
	City: user?.City ?? '',
	Notes: '',
	PaymentToken: '',
	SecurityCode: '',
	ExpirationMonth: 0,
	ExpirationYear: 0,
	InstrumentDescription: '',
	CountryID: user?.CountryID ?? 1,
	StateID: user?.StateID ?? 1,
	IsEnabled: true,
} as IPaymentInstrument);

let achInstrument: IPaymentInstrument = $ref({ ...defaultAchInstrument } as IPaymentInstrument);

let isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => emit('update:modelValue', value),
});

let isLoading = $ref(false);

function verify(value: boolean) {
	isAchInfoVerified = value;
}

function selectCulture(culture: string) {
	selectedCulture = culture as Cultures;
}

function setCardCredentials(card: IPaymentInstrument) {
	cardInstrument = card;
}

async function loadStatesByCountryID(_countryID: number) {
	try {
		await store.getStatesByCountryID(achInstrument.CountryID);
	} catch (err) {
		console.error(err);
	}
}

async function beforeLeave(value: string) {
	loadStatesByCountryID(achInstrument.CountryID);
	activeTab = value;
	cardInstrument = {} as IPaymentInstrument;
	return true;
}

async function verifyPayment() {
	try {
		const res = await paymentStore.validatePaymentInstrument(cardInstrument);
		cardInstrument.PaymentToken = String(res[0]);
		emit('payment-verified', cardInstrument);
		closeModal();
	} catch (err) {
		console.error(err);
		const error = err as Error;
		createNotification('error', {
			title: 'Error',
			content: error?.message || 'Please be sure to fill out all required fields.',
		});
	}
}

function submitForm() {
	if (paymentStore.paymentInfo.HasTokens && isCardTabActive) {
		verifyPayment();
		return;
	}
	emit('payment-verified', isCardTabActive ? cardInstrument : achInstrument);
	closeModal();
}

function closeModal() {
	isOpenModal = false;
}

const achRules = {
	City: {
		required: true,
		trigger: 'submit',
	},
	Address: {
		required: true,
		trigger: 'submit',
	},
	Address2: {
		required: true,
		trigger: 'submit',
	},
	Zip: {
		required: true,
		trigger: 'submit',
		validator: (_: FormItemRule, value: any) => {
			return isZip(value, selectedCulture);
		},
	},
	ReceiptEmail: {
		required: true,
		trigger: 'submit',
		validator: (_: FormItemRule, value: any) => {
			return isEmail(value);
		},
	},
	PaymentType: {
		required: true,
		trigger: 'submit',
		validator: (_: FormItemRule, value: any) => {
			return value !== undefined;
		},
	},
	CountryID: {
		trigger: 'submit',
		validator: (_: FormItemRule, value: any) => {
			return value !== undefined;
		},
	},
};

let cardRules = {
	BillingName: {
		required: true,
		trigger: 'submit',
	},
	City: {
		required: true,
		trigger: 'submit',
	},
	Address: {
		required: true,
		trigger: 'submit',
	},
	CountryID: {
		trigger: 'submit',
		validator: (_: FormItemRule, value: any) => {
			return value !== undefined;
		},
	},
	BankPhone: {
		required: true,
		trigger: 'submit',
		validator: (_: FormItemRule, value: any) => {
			return isPhone(value, selectedCulture);
		},
		message: 'Please enter a valid phone number',
	},
	ReceiptEmail: {
		required: true,
		trigger: 'submit',
		validator: (_: FormItemRule, value: any) => {
			return isEmail(value);
		},
		message: 'Please enter a valid email address',
	},
};
</script>
