import { handleError } from '@/plugins/errors';
import { GetNewsItems } from '@/services/news-service';
import { INewsItem, INewsItemRequest } from '@/types/News';
import { defineStore } from 'pinia';

export const useNewsStore = defineStore('department', {
	state: () => ({
		news: [] as INewsItem[],
		count: 0,
	}),
	actions: {
		async getNewsItemInfo(newsID: number) {
			try {
				const [res] = await GetNewsItemInfo(newsID);
				if (!res) {
					throw new Error('News not found');
				}
				return res;
			} catch (error) {
				throw handleError(error);
			}
		},
		async getNews(pagination: INewsItemRequest): Promise<INewsItem[] | void> {
			try {
				const [res, count] = await GetNewsItems(pagination);
				if (!res) {
					this.count = 0;
					return;
				}
				this.count = count;
				pagination.Skip ? (this.news = this.news.concat(res)) : (this.news = res);
			} catch (error) {
				throw handleError(error);
			}
		},
	},
});
