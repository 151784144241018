import { IPaymentInfo, IPaymentInstrument } from '@/types/Payments';
import { IPayloadResponse } from '@/types/Request';
import { IPayments } from '@/types/Payments';

export async function GetPaymentInfo(countryID: number): Promise<[IPaymentInfo, number]> {
	try {
		return await baseService.post(BaseEndpoints.GetPaymentInfo, {
			CountryID: countryID,
		});
	} catch (error) {
		throw handleError(error);
	}
}
export async function ValidatePaymentInstrument(
	paymentInstrument: IPaymentInstrument,
	isPayloadCompressed: boolean
): Promise<[IPayloadResponse<string>, number]> {
	try {
		if (!isPayloadCompressed) baseService.setIsPayloadCompressed(false);
		return await baseService.post(BaseEndpoints.ValidatePaymentInstrument, {
			Payload: paymentInstrument,
		});
	} catch (error) {
		throw handleError(error);
	}
}

export async function GetPaymentsOnFile(ID: number): Promise<[IPayments[], number]> {
	try {
		return baseService.post(SecureEndpoints.GetPaymentsOnFile, {
			ID: ID,
		});
	} catch (error) {
		throw handleError(error);
	}
}
