<template>
	<div class="flex flex-col items-center w-full">
		<h1 class="text-2xl font-bold text-center text-gray-500 md:-mt-2 mt-8 mb-4">Login</h1>
		<LoginForm
			:author-type="Number(idFromRoute)"
			class="w-1/3 min-w-[250px] p-10 transition-shadow bg-white shadow-md shadow-stone-300 hover:shadow-lg"
			@open-register-modal="isInviteUserModalVisible = true"
			@open-forgot-modal="navigateToForgotPassword"
		/>
		<SignUpModal
			v-if="isInviteUserModalVisible"
			v-model="isInviteUserModalVisible"
			:author-type="Number(idFromRoute)"
		/>
	</div>
</template>

<script setup lang="ts">
import router from '@/router';
import { useAuthenticationStore } from '@/store/auth';
import { usePaymentsStore } from '@/store/Payments';

let paymentStore = usePaymentsStore();
let isInviteUserModalVisible = $ref(false);
let authStore = useAuthenticationStore();

const idFromRoute = $computed(() => String(router.currentRoute.value.params.id));

function navigateToForgotPassword() {
	router.push({ name: routeNames['forgot-password'] });
}

async function loadPaymentInfo(countryID: number) {
	try {
		await paymentStore.getPaymentInfo(countryID);
	} catch (error) {
		console.log(error);
	}
}

onMounted(async () => {
	if (
		!Object.keys(AuthorTypes).includes(idFromRoute) ||
		Number(idFromRoute) === AuthorTypes.Admins ||
		authStore.isLoggedIn
	)
		router.push({ name: 'home' });

	await loadPaymentInfo(1);
});
</script>
