<template>
	<n-data-table
		:loading="loading"
		:columns="columns"
		:data="data"
		:bordered="false"
		virtual-scroll
		max-height="60vh"
		@scroll="handleScroll"
	/>
</template>
<script lang="ts" setup>
import { DataTableColumns } from 'naive-ui';

const {
	loading = false,
	columns = [],
	data,
} = defineProps<{
	loading?: boolean;
	columns?: DataTableColumns<any>;
	data: Array<any>;
}>();

const emit = defineEmits<{
	(event: 'loadData'): void;
}>();

let scrolledTop = $ref(0);

function handleScroll(event: Event) {
	const target = event.target as HTMLElement;
	if (window.innerHeight + scrolledTop < target.scrollTop + target.scrollTop * 0.25) {
		scrolledTop = target.scrollTop;
		emit('loadData');
	}
}
</script>
<style scoped>
@media only screen and (max-width: 768px) {
	:deep(.n-data-table-table),
	:deep(.n-data-table-thead),
	:deep(.n-data-table-tbody),
	:deep(.n-data-table-th),
	:deep(.n-data-table-td),
	:deep(.n-data-table-tr) {
		display: block !important;
	}

	:deep(.n-data-table-thead) {
		position: absolute !important;
		top: -9999px !important;
		left: -9999px !important;
	}

	:deep(.n-data-table-td) {
		border: none !important;
		border-bottom: 1px solid #eee !important;
		position: relative !important;
		padding-left: 50% !important;
		white-space: normal !important;
		text-align: left !important;
	}

	:deep(.n-data-table-td:before) {
		position: absolute !important;
		top: 28% !important;
		left: 6px !important;
		width: 45% !important;
		padding-right: 10px !important;
		white-space: nowrap !important;
		text-align: left !important;
		font-weight: bold !important;
	}

	:deep(.n-data-table-td:before) {
		content: attr(data-col-key) !important;
	}

	:deep(.n-data-table-tbody > tr:last-child) {
		border-top: 2px solid #ccc !important;
	}
}

@media only screen and (min-width: 768px) {
	:deep(.n-data-table-tbody .n-data-table-tr) {
		height: 100px;
	}
}
</style>
