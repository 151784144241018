import { decompressFromEncodedURIComponent, compressToEncodedURIComponent } from './lzString.js';

export type StorageKey = keyof typeof storageVariables;
export type StorageFunction = (key: StorageKey, value?: unknown) => string | null | void;

export enum storageVariables {
	token = 'token',
	user = 'user',
	userSettings = 'userSettings',
	lastAd = 'lastAd',
	countries = 'countries',
	defaultCountryId = "defaultCountryId",
	timezones = 'timezones',
	defaultTimezoneId = "defaultTimezoneId",	
	states = 'states',
	defaultStateId = 'defaultStateId',
	lastPoll = 'lastPoll',
	culture = 'culture',
}

export function clearStorage() {
	localStorage.clear();
}

export function getStorageItem<ReturnType>(key: StorageKey): ReturnType {
	return getItem(key);
}
export function setStorageItem(key: StorageKey, value: unknown) {
	return setItem(key, value);
}
export function removeStorageItem(key: StorageKey) {
	return removeItem(key);
}

function getItem(key: StorageKey) {
	try {
		validateKey(key);
		const compressedStorageData = localStorage.getItem(compressToEncodedURIComponent(key));
		if (!compressedStorageData) {
			return '';
		}
		try {
			return JSON.parse(decompressFromEncodedURIComponent(compressedStorageData));
		} catch {
			//handle the case where we maybe try to access key/value pairs that are not compressed, not advised though
			return localStorage.getItem(key);
		}
	} catch (error) {
		throw new Error(`Failed to get value of ${key}. Reason: ${error}`);
	}
}

function setItem(key: StorageKey, value: unknown) {
	validateKey(key);
	validateValue(value);
	return localStorage.setItem(compressToEncodedURIComponent(key), compressToEncodedURIComponent(JSON.stringify(value)));
}

function removeItem(key: StorageKey) {
	validateKey(key);
	return localStorage.removeItem(compressToEncodedURIComponent(key));
}

function validateKey(key: string) {
	if (!key) {
		throw new Error(`A key is required, your key: ${key}`);
	}
}

function validateValue(value: unknown) {
	if (!value) {
		throw new Error(`No value was provided, your value: ${value}`);
	}
}
