import { RequestByItemID } from '@/types';
import { ISubmissionItem, ISubmissionItemPayload, ISubmissionItemRequest } from '@/types/Submission';

export async function GetSubmission(request: RequestByItemID): Promise<[ISubmissionItem, number]> {
	try {
		return baseService.post(SecureEndpoints.GetSubmission, request);
	} catch (error) {
		throw handleError(error);
	}
}

export async function GetSubmissions(pagination: ISubmissionItemRequest): Promise<[ISubmissionItem[], number]> {
	try {
		return baseService.post(SecureEndpoints.GetSubmissions, pagination);
	} catch (error) {
		throw handleError(error);
	}
}

export async function SaveSubmission(payload: ISubmissionItemPayload): Promise<[ISubmissionItem, number]> {
	try {
		return baseService.post(SecureEndpoints.SaveSubmission, { Payload: payload });
	} catch (error) {
		throw handleError(error);
	}
}
