<template>
	<div
		v-if="!isCardVerified && paymentStore.paymentInfo.HasTokens"
		ref="tokenWrapper"
		class="token-wrapper token-item space-y-4 w-min flex flex-col justify-center"
		:class="paymentError ? 'max-h-[460px]' : 'max-h-[450px]'"
	>
		<div id="token-cards"></div>

		<div class="token-row">
			<label for="token-number">Card Number:</label>
			<div id="token-number" class="token-field"></div>
		</div>

		<div class="token-row">
			<label for="token-expiration">Expires:</label>
			<div id="token-expiration" class="token-field"></div>
		</div>

		<div class="token-row">
			<label for="token-cvv">Security Code:</label>
			<div id="token-cvv" class="token-field"></div>
		</div>

		<span v-if="paymentError" class="text-red-500">{{ paymentError }}</span>

		<div id="token-submit"></div>
	</div>

	<PaymentWithoutToken v-else-if="!isCardVerified" v-model="paymentInstrument" @valid="setPaymentValue" />

	<div v-else-if="isCardVerified" class="flex flex-col justify-center">
		<h1 class="text-xl text-center mb-2">Bill To:</h1>
		<n-form-item label="Name on card:" path="BillingName">
			<n-input v-model:value="paymentInstrument.BillingName" />
		</n-form-item>
		<n-form-item label="Address:" path="Address">
			<n-input v-model:value="paymentInstrument.Address" />
		</n-form-item>
		<n-form-item label="Country:" path="CountryID">
			<n-select
				v-model:value="paymentInstrument.CountryID"
				:options="transformedCountries"
				placeholder="Please select your country"
				:on-update:value="selectCountry"
			/>
		</n-form-item>
		<n-form-item :label="paymentInstrument.CountryID === 1 ? 'City, ST, ZIP: ' : 'City, ST, Postal Code:'" path="City">
			<div
				class="flex flex-col space-y-4 w-full"
				:class="{
					'ml-8': paymentInstrument.CountryID !== 1,
				}"
			>
				<n-input v-model:value="paymentInstrument.City" />
				<n-select
					v-model:value="paymentInstrument.StateID"
					:options="transformedStates"
					placeholder="Please select your state"
					:on-update:value="selectState"
				/>
				<n-input
					v-model:value="paymentInstrument.Zip"
					v-maska="{
						mask: getZipPattern,
						tokens: {
							Z: {
								pattern: /[0-9|A-Za-z|' ']/,
							},
						},
					}"
					:placeholder="zipFormat(selectedCulture)"
				/>
			</div>
		</n-form-item>
		<n-form-item label="Issuer Phone:" path="BankPhone">
			<n-input v-model:value="paymentInstrument.BankPhone" />
		</n-form-item>
		<n-form-item label="Receipt Email:" path="ReceiptEmail">
			<n-input v-model:value="paymentInstrument.ReceiptEmail" />
		</n-form-item>
		<n-button type="primary" class="w-full" @click="showPreviousStep">Back</n-button>
	</div>
</template>
<script lang="ts" setup>
import { usePaymentsStore } from '@/store/payments';
import { IPaymentInstrument } from '@/types/Payments';
import { useAuthenticationStore } from '@/store/auth';
import { DropdownOptions } from '@/types';
import { usePortico } from '@/composables/usePortico';
import { Cultures, zipFormat } from '@/modules/validation';
import { IAuthorInfo } from '@/types/Author';
import { NFormItem, NInput, NSelect } from 'naive-ui';

const emit = defineEmits<{
	(event: 'valid', value: IPaymentInstrument): void;
	(event: 'load', value: boolean): void;
}>();

const paymentStore = usePaymentsStore();
const authStore = useAuthenticationStore();
const store = useStore();

const { createTokenizer, paymentCredentials, paymentError, isLoading } = $(usePortico());

const user = $computed(() => (authStore.isLoggedIn ? authStore.user : (inject('newAuthor') as IAuthorInfo)));

let tokenWrapper: HTMLElement | undefined = $ref();
let paymentInstrument: IPaymentInstrument = $ref({
	ID: 0,
	IsAch: false,
	RoutingAba: '123',
	InstrumentDescription: '123',
	BankName: '123',
	BankPhone: user?.Phone || '',
	BillingName: user ? (user?.FirstName ?? '') + ' ' + (user?.LastName ?? '') : '',
	CountryID: user?.CountryID || 1,
	Address: user?.Address || '',
	Address2: user?.Address2 || '',
	City: user?.City || '',
	StateID: user?.StateID || 1,
	ReceiptEmail: user?.Email || '',
	Notes: '123',
	IsEnabled: true,
	PaymentType: 1,
	Zip: user?.ZIP || '',
} as IPaymentInstrument);
let isCardVerified = $ref(false);

const transformedCountries = $computed(() => transformDataToLabelAndValue(store.countries));

const transformedStates = $computed(() => transformDataToLabelAndValue(store.states));

let selectedCulture: Cultures = 'en-us';

const selectedCountryName = $computed(() => {
	return store.countries.find((country) => country.ID === paymentInstrument.CountryID)?.Name ?? '';
});

const getZipPattern = $computed(() =>
	selectedCountryName === 'United Kingdom'
		? 'SS#Z #SS'
		: zipFormat(selectedCulture).replace(/9/g, '#').replace(/A/g, 'S')
);

const getCardTypePrompt = $computed(() =>
	paymentStore.paymentInfo.CardTypes.map((cardType: DropdownOptions) => cardType.Prompt)
);

function findCultureByCountryID(countryID: number) {
	return (store.countries.find((country) => country.ID === countryID)?.Culture ?? 'en-us') as Cultures;
}

async function loadStatesByCountryID(selectedID: number) {
	try {
		await store.getStatesByCountryID(selectedID);
	} catch (error) {
		console.log(error);
	}
}

async function selectCountry(selectedID: number) {
	paymentInstrument.CountryID = selectedID;
	selectedCulture = findCultureByCountryID(selectedID);
	await loadStatesByCountryID(selectedID);
	paymentInstrument.StateID = store.states.find((state) => state.IsDefault)?.ID ?? store.states[0].ID;
}

function selectState(selectedID: number) {
	paymentInstrument.StateID = selectedID;
}

const publicKey = $computed(() => paymentStore.paymentInfo.PublicKey);

onBeforeUnmount(unloadPorticoHtml);

function unloadPorticoHtml() {
	if (tokenWrapper) tokenWrapper.innerHTML = '';
}

async function setPaymentValue() {
	isCardVerified = true;
	await selectCountry(paymentInstrument.CountryID);
	paymentInstrument.StateID = user?.StateID ?? 1;
	paymentInstrument.PaymentToken = '';
	emit('valid', paymentInstrument);
}

function showPreviousStep() {
	emit('valid', {} as IPaymentInstrument);
	isCardVerified = false;
}

watchEffect(() => {
	emit('load', isLoading);
});

watch($$(paymentCredentials), async (value) => {
	emit('load', false);
	if (!value.PaymentToken) return;
	await selectCountry(paymentInstrument.CountryID);
	isCardVerified = true;

	paymentInstrument.StateID = user?.StateID ?? 1;
	paymentInstrument.PaymentToken = value.PaymentToken;
	paymentInstrument.PaymentType = value.PaymentType;
	paymentInstrument.AccountNumber = value.LastFour;
	paymentInstrument.ExpirationMonth = value.ExpirationMonth.split(' ')[0];
	paymentInstrument.ExpirationYear = value.ExpirationYear.split(' ')[1];

	unloadPorticoHtml();
	emit('valid', paymentInstrument);
});

onMounted(() => {
	if (paymentStore.paymentInfo.HasTokens) {
		createTokenizer(publicKey, getCardTypePrompt);
		paymentStore.isPayloadCompressed = false;
		return;
	}
	paymentStore.isPayloadCompressed = true;
});
</script>
<style scoped>
#token-cards {
	background: transparent url(https://media.visualpractice.io/images/cards.png) no-repeat center;
	height: 40px;
	background-size: 280px 34px;
	margin-bottom: 7px;
}

:deep(.n-form-item-label) {
	width: 100%;
}
</style>
