import { useAuthenticationStore } from './store/auth';
import { createHead } from '@vueuse/head';
import App from './App.vue';
import { maska } from 'maska';
import router from './router';
import './assets/index.postcss';
import './assets/index.scss';

const meta = document.createElement('meta');
meta.name = 'naive-ui-style';
document.head.appendChild(meta);

const head = createHead();
const app = createApp(App);
app.use(head);

const pinia = createPinia();
pinia.use(({ store }) => {
	store.router = markRaw(router);
});
app.use(pinia);
app.directive('maska', maska);
const authStore = useAuthenticationStore();
authStore.autoLogin().finally(() => {
	app.use(router);
	router.isReady().then(() => {
		app.mount('#app');
	});
});
