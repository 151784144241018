export function useImageManipulation() {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	async function cropReduceImage(
		src: string,
		aspectRatio: number,
		maxHeight: number,
		skipDataUrl?: boolean,
		rotateImage?: boolean
	) {
		if (!canvas || !ctx) return;
		const img = new Image();
		img.src = src;
		const load = (src: string) =>
			new Promise((resolve) => {
				const img = new Image();
				img.onload = () => resolve({ src, ratio: img.naturalWidth / img.naturalHeight });
				img.src = src;
			});

		await load(src);

		const maxWidth = maxHeight * aspectRatio;

		let canvasWidth = img.naturalWidth;
		let canvasHeight = img.naturalHeight;

		const targetAspect = aspectRatio;

		let sX = 0;
		let sY = 0;
		let dX = 0;
		let dY = 0;

		if (img && (img.naturalWidth > maxWidth || img.naturalHeight > maxHeight)) {
			if (img.naturalWidth > img.naturalHeight) {
				// Landscape mode
				const aspect = img.naturalWidth / img.naturalHeight;
				canvasWidth = img.naturalWidth > maxWidth ? maxWidth : img.naturalWidth;
				canvasHeight = canvasWidth / aspectRatio;

				if (canvasHeight > img.naturalHeight) {
					canvasHeight = img.naturalHeight;
					canvasWidth = canvasHeight * aspectRatio;
				}

				sX = aspect > targetAspect ? (img.naturalWidth - canvasWidth) / 2 : 0;
				sY = aspect > targetAspect ? (img.naturalHeight - canvasHeight) / 2 : 0;

				// Calculate the destination centering offset, if needed
				dX = aspect < targetAspect ? (canvasWidth - img.naturalWidth * aspectRatio) / 2 : 0;
			} else {
				// Portrait mode
				const aspect = img.naturalHeight / img.naturalWidth;

				canvasHeight = img.naturalHeight > maxHeight ? maxHeight : img.naturalHeight;
				canvasWidth = canvasHeight * aspectRatio;

				if (canvasWidth > img.naturalWidth) {
					canvasWidth = img.naturalWidth;
					canvasHeight = canvasWidth / aspectRatio;
				}

				sY = aspect > targetAspect ? (img.naturalHeight - canvasHeight) / 2 : 0;
				sX = aspect > targetAspect ? (img.naturalWidth - canvasWidth) / 2 : 0;

				// Calculate the destination centering offset, if needed
				dY = aspect < targetAspect ? (canvasHeight - img.naturalHeight * aspectRatio) / 2 : 0;
			}
		}

		canvas.width = canvasWidth;
		canvas.height = canvasHeight;

		if (dX > 0) {
			// Add a transparent bar on the left side
			ctx.beginPath();
			ctx.rect(0, 0, dX, canvasHeight);
			ctx.fillStyle = 'transparent';
			ctx.fill();

			// Add a transparent bar on the right side
			ctx.beginPath();
			ctx.rect(canvasWidth - dX, 0, dX, canvasHeight);
			ctx.fillStyle = 'transparent';
			ctx.fill();
		} else if (dY > 0) {
			// Add a transparent bar on the top
			ctx.beginPath();
			ctx.rect(0, 0, canvasWidth, dY);
			ctx.fillStyle = 'transparent';
			ctx.fill();

			// Add a transparent bar on the bottom
			ctx.beginPath();
			ctx.rect(0, canvasHeight - dY, canvasWidth, dY);
			ctx.fillStyle = 'transparent';
			ctx.fill();
		}

		ctx.drawImage(
			img,
			sX,
			sY,
			img.naturalWidth - 2 * sX,
			img.naturalHeight - 2 * sY,
			dX,
			dY,
			canvas.width - 2 * dX,
			canvas.height - 2 * dY
		);

		return skipDataUrl ?? false ? null : rotateImage ? rotate(img, rotateImage) : canvas.toDataURL('png');
	}

	function rotate(img: HTMLImageElement, isLeft = false) {
		if (!canvas || !ctx) return;

		canvas.width = img.naturalHeight;
		canvas.height = img.naturalWidth;
		ctx.clearRect(0, 0, canvas.width, canvas.height);

		ctx.save();
		ctx.rotate(isLeft ? -Math.PI / 2 : Math.PI / 2);
		ctx.drawImage(img, isLeft ? -img.naturalWidth : 0, isLeft ? 0 : -img.naturalHeight);
		ctx.restore();

		return canvas.toDataURL('png');
	}
	return { cropReduceImage, rotate };
}
