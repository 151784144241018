<template>
	<AlertModalSkeleton v-model="isOpenModal" title="Privacy Policy" modal-type="info" is-close-icon-visible>
		<template #body>
			<div class="space-y-4">
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Overview</h2>
					<p>
						In order to sell you software products and services and to reduce the risk of fraud, Dental Practice Today
						(“we”, “us”, “our”) must ask you to provide us information about yourself and, at times, other related
						information (such as your credit or debit card account information). This Privacy Policy describes the
						information we collect and how we use that information. We take the privacy of your personal information
						very seriously and will use your information only in accordance with the terms of this Privacy Policy. We
						will not sell or rent your personally identifiable information or a list of our customers to any third
						parties. However, as described in more detail in Part C below, there are limited circumstances in which some
						of your information will be shared with third parties, under strict restrictions, so it is important for you
						to review this Privacy Policy. By accepting the Privacy Policy and License Agreement included with your
						software, you expressly consent to our use and disclosure of your personal information in the manner
						described in this Privacy Policy. This Privacy Policy is incorporated into and subject to the terms of the
						software License Agreement included with your software. This Privacy Policy has taken effect on 
						January 15<sup>th</sup>, 2023. This Privacy Policy was last updated on January 23<sup>th</sup>, 2023. 
						Please note that your personal information will be stored and processed on our computers in the United States. 
						The laws on holding personal data in the United States may be less stringent than the laws of your particular 
						jurisdiction but we will hold and transmit your personal information in a safe, confidential and secure environment. 
						If you object to your personal information being transferred or used as described in this Privacy Policy, 
						please do not access this website and please do not purchase our products and / or our services.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Notification of Changes</h2>
					<p>
						This policy may be revised over time as new features are added to the services and / or software products we
						sell or as we incorporate suggestions from our customers. If we are going to use or disclose your personally
						identifiable information in a manner materially different from that stated at the time we collected the
						information, you will have a choice as to whether or not we use or disclose your information in this new
						manner. Any material changes will be effective only after we provide you by e-mail with at least 30 days’
						notice of the amended Privacy Policy (if you have returned, resold, or otherwise disposed of all your
						software products purchased from us and you have discontinued all of your outstanding services provided by
						us, you will not be contacted to notify you of the amended Policy and your personal information will not be
						used or disclosed in this new manner). We will post the amended Privacy Policy on our Web site so that you
						can always review what information we gather, how we might use that information, and whether we will
						disclose it to anyone. Please check our web site at any time for the most current version of our Privacy
						Policy.
					</p>
				</div>

				<div>
					<h1 class="text-2xl font-extrabold tracking-tight text-gray-700 capitalize">Information We Collect</h1>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Required Information</h2>
					<p>
						To purchase a software product or service from us, you must provide your name, company, address, phone
						number, and e-mail address. In order to make payments electronically, you must provide credit card or debit
						account information, credit card verification code or debit card PIN, account holder’s name, and account
						holder’s billing address and telephone. This required information is necessary for us to process
						transactions, protect you against credit card fraud, and to contact you should the need arise in
						administering your account. If you purchase our products or services from an authorized reseller, the
						reseller will only share the information needed to deliver the product and / or provide the service you
						purchased with us, such as your name, company, address, phone number, and e-mail address. We will not
						receive any payment information from our resellers. Please check the reseller’s Privacy Policy for more
						details.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Additional Verification</h2>
					<p>
						If we cannot verify the information that you provide, we ask you to send us additional information to help
						verify your information.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Web Site Traffic Information</h2>
					<p>
						Because of the way that World Wide Web communication standards work, when you arrive at or leave our Web
						site, we automatically receive your IP address, and the Web address of the site that you came from or are
						going to. We might also collect information on which pages of our Web site you visit while you are on our
						Web site, the type of browser you use and the times you access our Web site. We use this information only to
						try to understand our customers’ preferences better and to manage the load on our servers, so as to improve
						our service and your experience with us. We do not track the Web sites that you visit before or after you
						leave our web site.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Our Use of “Cookies”</h2>
					<p>
						“Cookies” are small files of data that reside on your computer and allow us to recognize you as a customer
						if you return to our web site using the same computer and browser. We might send a “session cookie” to your
						computer if and when you log in to your account on our web site by entering your login ID and password.
						These cookies allow us to recognize you if you visit multiple pages in our site during the same session, so
						that you don’t need to re-enter your password multiple times. After their built-in inactivity timeout
						elapses, these session cookies expire and no longer have any effect.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">
						Customer Service Correspondence
					</h2>
					<p>
						If you send us correspondence, including e-mails and faxes, we retain such information in the records of
						your account. We will also retain customer service correspondence and other correspondence from us to you.
						We retain these records in order to measure and improve our customer service, and to investigate potential
						fraud and violations of our License Agreement. We may, over time, delete these records if permitted by law.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">
						Questionnaires, Surveys and Profile Data
					</h2>
					<p>
						From time to time, we might offer optional questionnaires and surveys to our users for such purposes as
						collecting demographic information or assessing users’ interests and needs. The use of the information
						collected will be explained in detail in the survey itself. If we collect personally identifiable
						information from our users in these questionnaires and surveys, the users will be given notice of how the
						information will be used prior to their participation in the survey or questionnaire.
					</p>
				</div>
				<div>
					<h1 class="text-2xl font-extrabold tracking-tight text-gray-700 capitalize">
						Our Use and Disclosure of Information
					</h1>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Internal Uses</h2>
					<p>
						We collect, store and process your personal information on servers located in the United States. We use the
						information we collect about you in order 1) to sell our software products, to provide our services, and to
						process your transactions, 2) to provide customer support, 3) to determine your eligibility to receive
						offers for special discounts, features, products, or services, and 4) to improve our products and services.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Disclosure to Third Parties</h2>
					<p>
						We will not sell or rent any of your personally identifiable information to third parties. We will not share
						any of your personally identifiable information with third parties except in the limited circumstances
						described below, or with your express permission. These third parties are limited by law or by contract from
						using the information for secondary purposes beyond the purposes for which the information is shared. We
						share information with companies that help us process the transactions you request and protect our
						customers’ transactions from fraud, such as sharing your credit card number with a credit card payment
						processor or a service that screens for lost and stolen card numbers. Additionally, if you go into a
						negative balance and owe us money, we may share information with processing companies including collection
						agencies. We may disclose the information we collect, as described in Section B above (but not your credit
						or debit card information) to companies that perform marketing services on our behalf. These companies are
						subject to confidentiality agreements with us and other legal restrictions that prohibit using the
						information except to market our specified products or services, unless you have affirmatively agreed or
						given your prior permission for other uses. We disclose information in response to a subpoena, warrant,
						court order, levy, attachment, order of a court-appointed receiver or other comparable legal process,
						including subpoenas from private parties in a civil action. We disclose information in response to a
						subpoena, warrant, court order, levy, attachment, order of a court-appointed receiver or other comparable
						legal process, including subpoenas from private parties in a civil action. We may share aggregated
						statistical data with our business partners or for public relations. For example, we may disclose that a
						specific percentage of our customers are based in Illinois. However, this aggregated information is not tied
						to personally identifiable information. As with any other business, it is possible that in the future we
						could merge with or be acquired by another company. If such an acquisition occurs, the successor company
						would have access to the information maintained by us, including customer account information, but would
						continue to be bound by this Privacy Policy unless and until it is amended as described in Section A above.
						We might share your information with our joint ventures to help coordinate the products and services we
						provide to you, enforce our terms and conditions, and promote trust and safety.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">
						Our Contacts with our Customers
					</h2>
					<p>
						We communicate with our customers on a regular basis via e-mail to provide our services, and we also
						communicate by phone to resolve customer complaints. We use your e-mail address to confirm your purchase of
						our products or services, to send information about important changes to our products and services, to send
						notices of maintenance service expiration and renewal, and to send other notices and disclosures required by
						law. Generally, users cannot opt out of these communications, but they will be primarily informational in
						nature rather than promotional. In connection with independent audits of our financial statements and
						operations, the auditors may seek to contact a sample of our customers to confirm that our records are
						accurate. However, these auditors cannot use personally identifiable information for any secondary purposes.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Internet Address Information</h2>
					<p>
						We may use IP addresses, browser types and access times to analyze trends, administer the site, improve site
						performance and gather broad demographic information for aggregate use.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Information Security</h2>
					<p>
						We are committed to handling your customer information with high standards of information security. Your
						credit or debit card information is transferred in encrypted form (banking – grade SSL 256-bit or higher
						technology) to our credit card payment processor directly; we may also store the last 4 digits of your card
						number, expiration date, and transaction confirmation information we receive form our credit card processor
						as proof of payment. We restrict access to your personally identifiable information to employees who need to
						know that information in order to provide products or services to you. We maintain physical, electronic, and
						procedural safeguards to guard your nonpublic personal information. Any e-mail or other communication asking
						you to provide sensitive account information via email, or linking to a website prompting you for sensitive
						account information with a URL that does not begin with https://dentalpracticetoday.com/ should be treated as
						unauthorized and suspicious and should be reported to us immediately.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">
						Accessing and Changing Your Information
					</h2>
					<p>
						You can review the personal information you provided to us and make any desired changes to such information
						at any time by contacting us via phone. If you have returned, resold, or otherwise disposed of all your
						software products purchased from us and you have discontinued all of your outstanding maintenance services
						provided by us, we will mark your account in our database as “Closed”, but will keep your account
						information in our database. However, if we mark your account “Closed”, your personally identifiable
						information will not be used by us for any further purposes, nor sold or shared with third parties, except
						as necessary to prevent fraud and assist law enforcement, or as required by law.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">
						California Residents: Your California Privacy Rights
					</h2>
					<p>
						Under the California Consumer Privacy Act of 2018 (“CCPA“), California residents have certain rights around
						the collection, use, and sharing of their personal information. We do not sell your personal information and
						will not do so in the future without providing you with notice and an opportunity to opt-out of such sale as
						required by law. Similarly, we do not offer financial incentives associated with our collection, use, or
						disclosure of your personal information. We collect various categories of personal information, including
						identifiers, commercial information, internet or other electronic network or device activity information,
						geolocation data, and professional information. A more detailed description of the information we collect
						and how we use it is provided above, including a description of the categories of third parties with whom we
						share personal information, and what information may be shared under different circumstances. what personal
						information has been collected about you, and to access that information. You also have the right to request
						deletion of your personal information, though exceptions under the CCPA may allow us to retain and use
						certain personal information notwithstanding your deletion request. You can learn more about how to submit a
						data rights request through our Customer Support service. You may also send your request by email to
						info at dentalpracticetoday.com. We will not discriminate against you for If you are a resident of California, you
						have the right to request to know exercising your rights under the CCPA. An agent may submit a request on
						your behalf, but you must verify that your agent is authorized to do so. Separate from the CCPA,
						California’s Shine the Light law gives California residents the right to ask companies what personal
						information they share with third parties for those third parties’ direct marketing purposes. We do not
						disclose your personal information to third parties for the purpose of marketing their goods or services to
						you unless you request such disclosure. If you have any questions regarding this policy, or would like to
						change your preferences, you may contact us at the address listed at the end of this Policy.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">
						Nevada Residents: Your Nevada Privacy Rights
					</h2>
					<p>
						Separate from the CCPA, California’s Shine the Light law gives California residents the right to ask
						companies what personal information they share with third parties for those third parties’ direct marketing
						purposes. We do not disclose your personal information to third parties for the purpose of marketing their
						goods or services to you unless you request such disclosure. If you have any questions regarding this
						policy, or would like to change your preferences, you may contact us at the address listed at the end of
						this Policy.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">
						European Residents: Your Privacy Rights and International Data Transfer
					</h2>
					<p>
						If you are a European Resident, you have the right to access your personal data, and the right to request
						that we correct, update, or delete your personal data. You can object to the processing of your personal
						information, ask us to restrict processing of your personal information, and request portability of your
						personal information. Additional information regarding your exercise of these rights is available through
						our Customer Support service. Similarly, if we have collected and processed your personal information with
						your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the
						lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your
						personal information conducted in reliance on lawful processing grounds other than consent. We will provide
						you with a reasonable means to view and change your profile information and you can opt-out of marketing
						communications at any time by clicking on the “unsubscribe” or “opt-out” link in the marketing emails we
						send you. If you have any questions or comments about the processing of your personal information, you may
						contact us as described at the end of this Policy. For European Residents, please note that the personal
						information we obtain from or about you for the purposes described in this Privacy Policy will be
						transferred, processed, and stored in the United States of America, outside of the EEA or Switzerland. We
						take the privacy of our users seriously and therefore take steps to safeguard your information, including
						ensuring an adequate level of data protection in accordance with E.U. standards in effect as of the date of
						this Privacy Policy. These steps include use of the European Commission’s Standard Contractual Clauses for
						transfers of personal information between us and our third-party service providers and partners, and further
						details can be provided upon request.
					</p>
				</div>
				<div>
					<h2 class="text-lg font-extrabold tracking-tight text-gray-700 capitalize">Contacting Us</h2>
					<p>
						If you have any questions about this privacy statement, our information practices, or your dealings with us,
						you can contact us by email via info at dentalpracticetoday.com, by phone at (847) 756-7962 Monday through Friday between the hours of 9:00am and 4:00pm CST (with the
						exception of legal holidays), or by postal mail at Dental Practice Today, 1600 Golf Road Suite 1200, Rolling
						Meadows, IL 60008, United States of America.
					</p>
				</div>
			</div>
		</template>
	</AlertModalSkeleton>
</template>
<script lang="ts" setup>
const { modelValue = true } = defineProps<{
	modelValue: boolean;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
	(event: 'close', value: boolean): void;
}>();

const isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => {
		emit('update:modelValue', value), emit('close', value);
	},
});
</script>
