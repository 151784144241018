<template>
	<n-dropdown :options="filterCurrentCulture" trigger="click" :render-label="renderLabel" @select="handleCultureSelect">
		<country-flag rounded :country="culture?.iconRef" />
	</n-dropdown>
</template>
<script lang="ts" setup>
import { Cultures } from '@/modules/validation';
import { useAuthenticationStore } from '@/store/auth';
import { DropdownOption, NDropdown } from 'naive-ui';
import CountryFlag from 'vue-country-flag-next';

const authStore = useAuthenticationStore();
const selectedCulture = $computed(() => authStore.culture);
const culture = $computed(() => {
	const cultureOption = options.find((option) => option.key === selectedCulture);
	return cultureOption;
});

const filterCurrentCulture = $computed(() => {
	return options.filter((option) => option.key !== selectedCulture);
});

function renderIcon(code: string) {
	return () => {
		return h(CountryFlag, {
			country: code,
			size: 'normal',
			rounded: true,
		});
	};
}

function handleCultureSelect(culture: string) {
	authStore.setCulture(culture as Cultures);
}

function renderLabel(option: DropdownOption) {
	return h(
		'div',
		{ class: 'mt-1' },
		{
			default: () => option.label,
		}
	);
}

const options = [
	{
		label: 'USA',
		iconRef: 'us',
		key: 'en-us',
		icon: renderIcon('us'),
	},
	{
		label: 'CAN',
		key: 'en-ca',
		iconRef: 'ca',
		icon: renderIcon('ca'),
	},
	{
		label: 'AUS',
		key: 'en-au',
		iconRef: 'au',
		icon: renderIcon('au'),
	},
	{
		label: 'NZ',
		key: 'en-nz',
		iconRef: 'nz',
		icon: renderIcon('nz'),
	},
	{
		label: 'UK',
		key: 'en-gb',
		iconRef: 'gb',
		icon: renderIcon('gb'),
	},
];
</script>
