import { ICountry, IState, ITimeZone } from '@/types/Generic';

export async function getCountries(): Promise<ICountry[]> {
	try {
		const [res] = await baseService.post<[ICountry[], number]>(BaseEndpoints.GetCountries, {});
		if (!res) {
			throw new Error('No data');
		}
		return res;
	} catch (error) {
		throw handleError(error);
	}
}

export async function getTimezones(id: number): Promise<ITimeZone[]> {
	try {
		const [res] = await baseService.post<[ITimeZone[], number]>(BaseEndpoints.GetTimeZones, {
			CountryID: id,
		});
		if (!res) {
			throw new Error('No data');
		}
		return res;
	} catch (error) {
		throw handleError(error);
	}
}

export async function getStates(id: number): Promise<IState[]> {
	try {
		const [res] = await baseService.post<[IState[], number]>(BaseEndpoints.GetStates, {
			CountryID: id,
		});
		if (!res) {
			throw new Error('No data');
		}
		return res;
	} catch (error) {
		throw handleError(error);
	}
}
