<template>
	<n-form-item class="col-span-3">
		<div class="flex items-center justify-between w-full">
			<div>List this {{ categoryName }} for:</div>
			<div class="flex flex-col xl:flex xl:flex-row xl:space-x-6">
				<n-checkbox
					v-for="tag in paymentInformation"
					:key="tag.text"
					:checked="isPaymentSelected(tag.text)"
					@click="selectPayment(tag)"
					><span :class="isPaymentSelected(tag.text) ? 'text-primary' : ''">{{ tag.text }}</span>
				</n-checkbox>
			</div>
		</div>
	</n-form-item>
	<!-- <n-form-item v-if="isSubmissionSponsored" class="col-span-3">
		<div class="flex justify-between w-full">
			<p>Credits available: 12</p>
			<p>Credits needed: 4</p>
		</div>
	</n-form-item> -->
	<n-form-item class="w-full col-span-3">
		<div class="flex flex-row justify-between w-full">
			<n-checkbox v-model:checked="isCouponCodeApplied">Apply Coupon</n-checkbox>
			<n-input
				v-model:value="couponCode"
				placeholder="Enter coupon code"
				:disabled="!isCouponCodeApplied"
				@update:value="(value) => emit('coupon-code', value)"
			/>
		</div>
	</n-form-item>
	<div v-if="!isCouponCodeApplied" class="col-span-3">
		<n-form-item class="col-span-3">
			<n-checkbox v-model:checked="isTermsAccepted" @update:checked="emit('terms-accepted', isTermsAccepted)"
				>I hereby confirm that I have authority to make this purchase on behalf of the advertising organization, and I
				acknowledge that I have read and understood the terms and conditions of service listed
				<a href="#">here</a>.</n-checkbox
			>
		</n-form-item>
		<n-form-item class="col-span-3 my-4">
			<div class="flex items-center justify-between w-full space-x-3">
				<div v-if="paymentOnFile">
					{{ paymentInformationText }}
				</div>
				<n-button type="primary" @click="isModalVisible = true"> Payment Information </n-button>
				<div>Payment amount: {{ formatMoney(getPaymentAmount, culture) }}</div>
			</div>
		</n-form-item>
	</div>
	<n-form-item v-if="isSubmissionSponsored" class="col-span-3">
		<n-input
			type="textarea"
			size="small"
			:autosize="{
				minRows: 3,
				maxRows: 5,
			}"
			:value="sponsorAdNotification"
			:rows="3"
			readonly
		/>
	</n-form-item>
	<PaymentTabs v-if="isModalVisible" v-model="isModalVisible" @payment-verified="handlePaymentVerified" />
</template>
<script lang="ts" setup>
import { useAuthenticationStore } from '@/store/auth';
import { usePaymentsStore } from '@/store/payments';
import { CardTypes } from '@/types/constants';
import PaymentTabs from './PaymentTabs.vue';
import { IPaymentInstrument } from '@/types/Payments';
import { formatMoney } from '@/modules/validation';
import { useOrganizations } from '@/store/organizations';

const props = defineProps<{
	categoryName: string;
	selectedClassifiedTag?: number;
}>();

const emit = defineEmits<{
	(event: 'terms-accepted', value: boolean): void;
	(event: 'coupon-code', value: string): void;
	(event: 'publishing-term', value: number): void;
	(event: 'publishing-fees', value: number): void;
	(event: 'payment-verified', value: IPaymentInstrument | null): void;
}>();

let paymentOnFile: IPaymentInstrument | null = $ref(null);

const authStore = useAuthenticationStore();
const paymentStore = usePaymentsStore();
const store = useStore();

let couponCode = $ref('');

const culture = $computed(() => authStore.culture);

let isModalVisible = $ref(false);

let isTermsAccepted = $ref(false);

let isCouponCodeApplied = $ref(false);

let isLoading = ref(false);

const getCountryByCulture = $computed(() => {
	store.countries.find((country) => country.Culture === culture);
});

const getCurrency = $computed(() => (culture === 'en-gb' ? '£' : '$'));

type PaymentType = {
	text: string;
	value: number;
	duration: number;
};

let paymentInformation: PaymentType[] = $ref([]);

let selectedPayment: PaymentType = $ref({} as PaymentType);

let getPaymentAmount = computed(() => selectedPayment?.value ?? 'No payment selected');

const isAdmin = $computed(() => authStore.user?.IsAdmin);

let isSubmissionSponsored = computed(() => props.categoryName === 'sponsor ad');

const accountNumber = $computed(() => {
	return paymentOnFile?.AccountNumber != null
		? paymentOnFile.AccountNumber.length > 4
			? paymentOnFile.AccountNumber.slice(-4)
			: paymentOnFile?.AccountNumber
		: '';
});

const paymentInformationText = $computed(() =>
	paymentOnFile && !paymentOnFile.IsAch
		? `Payment via: ${CardTypes[paymentOnFile.PaymentType]}  *${accountNumber} Exp.
					${paymentOnFile.ExpirationMonth}/${String(paymentOnFile.ExpirationYear)?.slice(-2)}`
		: `Payment via: ${AchTypes[paymentOnFile?.PaymentType ?? 7]} *${accountNumber}`
);

const notificationBlockText = computed(
	() =>
		`Upon our successful review of your ${props.categoryName} submission, a ${getCurrency}${selectedPayment.value} non-refundable fee will be charged to your selected form of payment above to cover the listing of your ${props.categoryName} for ${selectedPayment.duration} days. This time period can't be extended; you must place your ad again if you subsequently would like to continue posting this ad with us.`
);

function selectPayment(payment: PaymentType) {
	selectedPayment = payment;
	emit('publishing-term', payment?.duration);
	emit('publishing-fees', payment?.value);
}

function isPaymentSelected(paymentText: string): boolean {
	return paymentText === selectedPayment.text;
}

async function getPaymentInfo() {
	isLoading.value = true;
	try {
		await paymentStore.getPaymentInfo(getCountryByCulture ?? 1);
	} catch (error) {
		console.error(error);
	} finally {
		isLoading.value = false;
	}
}

function handlePaymentVerified(payment: IPaymentInstrument | null) {
	paymentOnFile = payment;
	emit('payment-verified', payment);
}

const sponsorAdNotification =
	'By continuing, you acknowledge that published sponsor ads are rotated using a priority algorithm based on your level of sponsorship as web site visitors navigate through the website; the number of impressions your ad will receive will be a function of your sponsorship level (the higher your sponsorship level the higher the frequency).';

type Fee = {
	Type: number;
	Prompt: string;
	Term: number;
	Fee: number;
};

function loadFeesIntoPaymentInformation(fees: Fee[]) {
	fees.forEach((fee: Fee) => {
		paymentInformation.push({
			text:
				props.categoryName === 'classified ad'
					? fee.Prompt.substring(fee.Prompt.indexOf(' '), fee.Prompt.length)
					: fee.Prompt,
			value: fee.Fee,
			duration: fee.Term,
		});
	});
}

const filterSponsorFeesByOrganization = $computed(() =>
	paymentStore.paymentInfo.SponsorAdFees.filter(
		(item) => item.Type === (isAdmin ? 2 : 2 ** organizationStore.currentOrganization.Sponsorship)
	)
);

const filterClassifiedFeesByTag = $computed(() =>
	paymentStore.paymentInfo?.ClassifiedFees?.filter((item) => item.Type === props.selectedClassifiedTag)
);

function filterFeesByAdCategory(isSubmissionSponsored: boolean) {
	return isSubmissionSponsored ? filterSponsorFeesByOrganization : filterClassifiedFeesByTag;
}

function loadFees() {
	if (paymentInformation.length > 0) return;
	const fees = filterFeesByAdCategory(isSubmissionSponsored.value);
	loadFeesIntoPaymentInformation(fees);

	selectPayment(paymentInformation[0]);
}

watch(
	() => props.selectedClassifiedTag,
	() => {
		paymentInformation = [];
		loadFees();
	}
);
const organizationStore = useOrganizations();

onBeforeMount(async () => {
	await getPaymentInfo();
	await organizationStore.getOrganizationInfo(authStore.user?.OrganizationID ?? 0);
	loadFees();
});
</script>
<style scoped>
.n-form-item {
	grid-template-rows: 0;
}
:deep(.n-form-item-feedback-wrapper) {
	grid-area: blank;
}
</style>
