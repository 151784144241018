<template>
	<aside class="flex flex-col items-center w-full md:w-1/3 lg:pl-3">
		<div
			v-if="randomPoll?.ID > 0"
			class="!flex flex-col w-full p-6 my-4 transition-shadow bg-white shadow-md shadow-stone-300 hover:shadow-lg"
		>
			<label class="text-base font-medium text-gray-900">
				{{ randomPoll?.Header }}
			</label>
			<fieldset class="my-6">
				<legend class="sr-only">{{ randomPoll?.Header }}</legend>
				<div class="flex flex-col space-y-3 max-w-[90%] 2xl:max-w-[55%] lg:max-w-[70%]">
					<div v-for="item in randomPoll?.Items" :key="item.Item">
						<n-checkbox v-if="item.Type === PollInfoTypes.Boolean" v-model:checked="item.Value">
							{{ item.Item }}<span v-if="item.IsRequired" class="text-[#D03050]">*</span>
						</n-checkbox>
						<div v-else-if="item.Type === PollInfoTypes.Number" class="flex flex-col">
							<p>{{ item.Item }}<span v-if="item.IsRequired" class="text-[#D03050]">*</span></p>
							<n-input-number v-model:value="item.Value" placeholder="Enter a number" />
						</div>

						<div v-else-if="item.Type === PollInfoTypes.Date" class="flex flex-col">
							<p>{{ item.Item }}<span v-if="item.IsRequired" class="text-[#D03050]">*</span></p>
							<n-date-picker
								v-model:formatted-value="item.Value"
								:format="getDateFormat"
								value-format="yyyy-MM-dd"
								type="date"
							/>
						</div>

						<div v-else-if="item.Type === PollInfoTypes.Money" class="flex flex-col">
							<p>{{ item.Item }}<span v-if="item.IsRequired" class="text-[#D03050]">*</span></p>
							<n-input-number
								v-model:value="item.Value"
								class="w-full"
								:default-value="10"
								:parse="(value) => parseCurrency(value, getCulture)"
								:format="(value) => formatCurrency(value ?? 0, getCulture)"
							/>
						</div>
						<div v-else-if="item.Type === PollInfoTypes.Text" class="flex flex-col">
							<p>{{ item.Item }}<span v-if="item.IsRequired" class="text-[#D03050]">*</span></p>
							<n-input v-model:value="item.Value" class="w-full" placeholder="Enter a text" />
						</div>
					</div>
				</div>
			</fieldset>
			<div class="flex flex-row w-full justify-end">
				<n-button type="primary" :disabled="isPollEmpty" @click="handleSubmit">
					<span class="font-bold uppercase"> Submit </span>
				</n-button>
			</div>
		</div>
		<SponsorAds />
	</aside>
	<SubmitPollModal v-model="isSubmitPollModalVisible" :poll-entry="randomPoll" @on-success="showSuccessNotification" />
</template>

<script lang="ts" setup>
import { useAuthenticationStore } from '@/store/auth';
import { usePollStore } from '@/store/polls';
import { IPollInfo, IPollItem } from '@/types/Polls';
import { PollInfoTypes } from '@/types/constants';
import { NCheckbox, NDatePicker, useNotification } from 'naive-ui';
import { parseCurrency, formatCurrency } from '@/plugins/helpers';
import { dateFormat } from '@/modules/validation';

const authStore = useAuthenticationStore();
const pollStore = usePollStore();
const notifications = useNotification();

let randomPoll: IPollInfo = $ref({} as IPollInfo);
let isSubmitPollModalVisible = $ref(false);
const isPollEmpty = $computed(() => !randomPoll?.Items.every((item) => (item.IsRequired ? item.Value : true)));
const getCulture = $computed(() => authStore.culture);
const getDateFormat = $computed(() => dateFormat(getCulture).replace(/DD/g, 'dd').replace(/YYYY/g, 'yyyy'));

function showSuccessNotification() {
	loadPoll();
	notifications.success({
		title: 'Success',
		duration: 3000,
		description: 'Your poll has been submitted successfully',
	});
}

function handleSubmit() {
	isSubmitPollModalVisible = true;
}

function transformPoll() {
	randomPoll.Items =
		randomPoll?.Items?.map((value: IPollItem) => {
			return { ...value, Value: null, Comment: '' };
		}) ?? [];
}

function loadPoll() {
	randomPoll = pollStore.currentPoll;
	transformPoll();
}

watch(
	() => [authStore.culture, pollStore.currentPoll],
	() => {
		loadPoll();
	},
	{ immediate: true }
);

onMounted(pollStore.getPollInfo);
</script>
