import LZString from "lz-string";
const { compressToEncodedURIComponent, decompressFromEncodedURIComponent } =
  LZString;
export function compressPayload<T>(payload: T): string {
  return compressToEncodedURIComponent(JSON.stringify(payload));
}

export function decompressPayload<T>(payload: string): T {
  return JSON.parse(String(decompressFromEncodedURIComponent(payload)));
}
export { compressToEncodedURIComponent, decompressFromEncodedURIComponent };
