<template>
	<AlertModalSkeleton v-model="isOpenModal" :title="`Sign up as ${authorType} was successful`" modal-type="success">
		<template #body>
			<div class="flex flex-col w-full col-span-3 space-y-4">
				<p>
					<slot name="salutation"></slot>
				</p>
				<p>
					<slot name="content">
						Your {{ messageByType[authorType].type }} registration request has been received, and it will be reviewed
						shortly. You will be notified by email as soon as your {{ messageByType[authorType].type }} registration is
						active so you can start {{ messageByType[authorType].message }}.
					</slot>
				</p>
				<div>
					<p>Sincerely,</p>
					<p>Your friends at <b>Dental Practice Today</b>.</p>
				</div>
			</div>
		</template></AlertModalSkeleton
	>
</template>

<script lang="ts" setup>
const { modelValue = true, authorType } = defineProps<{
	modelValue: boolean;
	authorType: string;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
	(event: 'close', value: boolean): void;
}>();

const messageByType: { [key: string]: { type: string; message: string } } = {
	Author: {
		type: 'author',
		message: 'blogging, posting and replying to forum articles',
	},
	Advertiser: {
		type: 'advertiser',
		message: 'placing classified ads',
	},
	Sponsor: {
		type: 'sponsorships',
		message: 'posting classifieds and placing ads',
	},
};

let isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => {
		emit('update:modelValue', value), emit('close', value);
	},
});
</script>
