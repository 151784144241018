<template>
	<FormOverlay
		v-model="isOpenModal"
		title="Submit poll entry"
		:loading="isLoading"
		:disabled="isLoading"
		:model="userInformation"
		:rules="rules"
		model-class="relative lg:max-w-[70%] w-1/ space-y-3 bg-white md:rounded-lg p-6 text-left overflow-hidden shadow-xl transform transition-all xs:my-8"
		@submit="submitForm"
		@close="closeModal"
	>
		<n-form-item class="col-span-3" path="Email" label="Enter your address to receive the results of this poll:"
			><n-input v-model:value="userInformation.Email" placeholder="Email address"
		/></n-form-item>
		<n-form-item class="col-span-3" path="Comment" label="Additional comment:">
			<n-input v-model:value="userInformation.Comments" placeholder="Comment" />
		</n-form-item>
		<n-form-item class="col-span-3 -mt-4">
			<n-checkbox v-model:checked="userInformation.IsNewsletter">Also subscribe to our monthly newsletter</n-checkbox>
		</n-form-item>
	</FormOverlay>
</template>
<script lang="ts" setup>
import { useAuthenticationStore } from '@/store/auth';
import { usePollStore } from '@/store/polls';
import { IPollInfo, IPollEntry } from '@/types/Polls';
import { FormItemRule } from 'naive-ui';

const { modelValue = false, pollEntry } = defineProps<{
	modelValue: boolean;
	pollEntry: IPollInfo;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
	(event: 'onSuccess'): void;
}>();

const authStore = useAuthenticationStore();
const pollStore = usePollStore();

const user = $computed(() => authStore.user);

let userInformation = $ref({
	Email: user?.Email || '',
	Comments: '',
	IsNewsletter: true,
});

let pollEntryToSubmit: IPollEntry = $ref({} as IPollEntry);

let isLoading = $ref(false);

let isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => emit('update:modelValue', value),
});

function closeModal() {
	isOpenModal = false;
	userInformation = {} as IPollEntry;
}

async function submitForm() {
	pollEntryToSubmit = {
		...pollEntry,
		...userInformation,
	};
	try {
		isLoading = true;
		await pollStore.submitPollEntry(pollEntryToSubmit);
		closeModal();
		emit('onSuccess');
	} catch (error) {
		console.error(error);
	} finally {
		isLoading = false;
	}
}

const rules = {
	Email: {
		required: true,
		message: 'Please input your email',
		trigger: 'submit',
		validator: (rules: FormItemRule, value: string) => {
			return isEmail(value);
		},
	},
	Comment: {
		message: 'Please input your comment',
		trigger: 'submit',
	},
};
</script>
<style scoped>
.n-form-item {
	grid-template-rows: 0;
}
</style>
