import { GetPaymentInfo, ValidatePaymentInstrument } from '@/services/payment-service';
import { IPaymentInfo, IPaymentCredentials, IPaymentInstrument } from '@/types/Payments';
import { IPayloadResponse } from '@/types/Request';
import { GetPaymentsOnFile } from '@/services/payment-service';
import { IPayments } from '@/types/Payments';
export const usePaymentsStore = defineStore('payments', {
	state: () => ({
		paymentInfo: {} as IPaymentInfo,
		paymentError: '',
		paymentCredentials: {} as IPaymentCredentials,
		payments: [] as IPayments[],
		currentLoadedCountry: -1,
		isPayloadCompressed: true,
	}),
	actions: {
		async getPaymentInfo(countryID: number): Promise<void> {
			try {
				if (this.currentLoadedCountry === countryID) return;
				this.currentLoadedCountry = countryID;
				const [res] = await GetPaymentInfo(countryID);
				this.paymentInfo = res;
			} catch (error) {
				throw handleError(error);
			}
		},
		async validatePaymentInstrument(credentials: IPaymentInstrument): Promise<[IPayloadResponse<string>, number]> {
			try {
				const result = await ValidatePaymentInstrument(credentials, this.isPayloadCompressed);
				return result;
			} catch (error) {
				throw handleError(error);
			}
		},
		async getPaymentsOnFile(ID: number): Promise<void> {
			try {
				const [res] = await GetPaymentsOnFile(ID);
				this.payments = res;
			} catch (error) {
				throw handleError(error);
			}
		},
	},
});
