/* eslint-disable no-useless-escape */
export type Language = 'en';
export type Regions = 'us' | 'ca' | 'gb' | 'nz' | 'au';
export type Currency = 'USD' | 'CAD' | 'GBP' | 'NZD';
export type Cultures = `${Language}-${Regions}`;
export type BankAccountTypes = 'AMEX' | 'VISA' | 'DISCOVER' | 'MASTERCARD' | 'DINERS CLUB' | 'JCB';
export enum BankAccounts {
	AMEX = 1,
	VISA = 2,
	DISCOVER = 4,
	MASTERCARD = 6,
	DINERS_CLUB = 8,
	JCB = 17,
}

export function isNumber(str: string): boolean {
	if (str) {
		const regex = /^\d{1,10}$/;
		return regex.test(str);
	}
	return false;
}
export function isMoney(str: string) {
	if (str) {
		const regex = /^[+]?[\\$]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;
		return regex.test(str);
	}
	return false;
}
export function isEmail(str: string) {
	if (str) {
		const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(str);
	}
	return false;
}
export function isPhone(str: string, culture: Cultures = 'en-us') {
	if (str) {
		let regex: RegExp;
		switch (culture) {
			case 'en-us':
				regex = /^(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?))[2-9]\d{2}[- ]?\d{4}$/;
				break;

			case 'en-nz':
				regex = /^[0]\d[- .]?\d{3}[- .]?\d{4}\d?\d?$/;
				break;

			case 'en-au':
				regex = /^[0]\d[- .]?\d{4}[- .]?\d{4}$/;
				break;

			case 'en-ca':
				regex = /^(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?))[2-9]\d{2}[- ]?\d{4}$/;
				break;

			case 'en-gb':
				regex =
					/^[0](1((1\d|\d1)[- .]?\d{3}[- .]?\d{4}|(\d{3}[- .]?\d{5}\d?|\d{4}[- .]?\d{4}\d?))|2\d[- .]?\d{4}[- .]?\d{4}|(3|8|9)\d{2}[- .]?\d{3}[- .]?\d{4}|5\d{3}[- .]?\d{6}|7\d{3}[- .]?\d{6})$/;
				break;

			default:
				regex = /^\d{7}\d?\d?$/;
				break;
		}
		return regex.test(str);
	}
	return false;
}
export function phoneFormat(culture: Cultures = 'en-us') {
	let strOut = '';
	switch (culture) {
		case 'en-us':
			strOut = '(999) 999-9999';
			break;

		case 'en-nz':
			strOut = '(09) 999 9999';
			break;

		case 'en-au':
			strOut = '09 9999 9999';
			break;

		case 'en-ca':
			strOut = '(999) 999-9999';
			break;

		case 'en-gb':
			strOut = '09[999] 999[999] [9999]';
			break;
	}
	return strOut;
}
export function getMoney(str: string) {
	let money = 0;

	if (isMoney(str)) {
		money = parseFloat(str.replace('$', '').replaceAll(',', ''));
	}

	return money;
}
export function formatMoney(money: number, culture: Cultures = 'en-us') {
	if(typeof money !== 'number') {
		return '';
	}
	let intl;
	switch (culture) {
		case 'en-us':
			intl = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
			break;

		case 'en-nz':
			intl = Intl.NumberFormat('en-NZ', { style: 'currency', currency: 'NZD' });
			break;

		case 'en-au':
			intl = Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' });
			break;

		case 'en-ca':
			intl = Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });
			break;

		case 'en-gb':
			intl = Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
			break;

		default:
			intl = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
			break;
	}

	return intl.format(money);
}
export function formatPhone(str: string, culture: Cultures = 'en-us') {
	let strOut = ('' + str).replace(/\D/g, '');
	if(str) {	
		switch (culture) {
		case 'en-us':
			if (strOut[0] != '1') {
				if (strOut.length > 10) {
					strOut = ''.concat(
						'(',
						strOut.substring(0, 3),
						') ',
						strOut.substring(3, 6),
						'-',
						strOut.substring(6, 10),
						' x',
						strOut.substring(10)
					);
				} else if (strOut.length > 6) {
					strOut = ''.concat('(', strOut.substring(0, 3), ') ', strOut.substring(3, 6), '-', strOut.substring(6));
				} else if (strOut.length > 3) {
					strOut = ''.concat(strOut.substring(0, 3), '-', strOut.substring(3));
				}
			}
			break;

		case 'en-nz':
			if (strOut[0] == '0') {
				if (strOut.length > 9) {
					if (strOut[1] == '2') {
						strOut = ''.concat(
							'(',
							strOut.substring(0, 2),
							') ',
							strOut.substring(2, 5),
							' ',
							strOut.substring(5, Math.min(strOut.length, 11))
						);
					} else {
						strOut = ''.concat(
							'(',
							strOut.substring(0, 2),
							') ',
							strOut.substring(2, 5),
							' ',
							strOut.substring(5, Math.min(strOut.length, 9)),
							strOut.length > 9 ? ' x' + strOut.substring(9) : ''
						);
					}
				} else if (strOut.length > 5) {
					strOut = ''.concat('(', strOut.substring(0, 2), ') ', strOut.substring(2, 5), ' ', strOut.substring(5));
				} else if (strOut.length > 3) {
					strOut = ''.concat(strOut.substring(0, 3), ' ', strOut.substring(3));
				}
			}
			break;

		case 'en-au':
			if (strOut[0] == '0') {
				if (strOut.length > 10) {
					strOut = ''.concat(
						strOut.substring(0, 2),
						' ',
						strOut.substring(2, 6),
						' ',
						strOut.substring(6, 10),
						' x',
						strOut.substring(10)
					);
				} else if (strOut.length > 6) {
					strOut = ''.concat(strOut.substring(0, 2), ' ', strOut.substring(2, 6), ' ', strOut.substring(6));
				} else if (strOut.length > 4) {
					strOut = ''.concat(strOut.substring(0, 4), ' ', strOut.substring(4));
				}
			}
			break;

		case 'en-ca':
			if (strOut[0] != '1') {
				if (strOut.length > 10) {
					strOut = ''.concat(
						'(',
						strOut.substring(0, 3),
						') ',
						strOut.substring(3, 6),
						'-',
						strOut.substring(6, 10),
						' x',
						strOut.substring(10)
					);
				} else if (strOut.length > 6) {
					strOut = ''.concat('(', strOut.substring(0, 3), ') ', strOut.substring(3, 6), '-', strOut.substring(6));
				} else if (strOut.length > 3) {
					strOut = ''.concat(strOut.substring(0, 3), '-', strOut.substring(3));
				}
			}
			break;

		case 'en-gb':
			if (strOut[0] == '0') {
				switch (strOut[1]) {
					case '1':
						if (strOut[2] == '1' || strOut[3] == '1') {
							if (strOut.length >= 11) {
								strOut = ''.concat(
									strOut.substring(0, 4),
									' ',
									strOut.substring(4, 7),
									' ',
									strOut.substring(7, 11),
									strOut.length > 11 ? ' x' + strOut.substring(11) : ''
								);
							}
						} else if (strOut.length >= 10) {
							strOut = ''.concat(
								strOut.substring(0, 5),
								' ',
								strOut.substring(5, Math.min(strOut.length, 11)),
								strOut.length > 11 ? ' x' + strOut.substring(11) : ''
							);
						}
						break;

					case '2':
						if (strOut.length >= 11) {
							strOut = ''.concat(
								strOut.substring(0, 3),
								' ',
								strOut.substring(3, 7),
								' ',
								strOut.substring(7, 11),
								strOut.length > 11 ? ' x' + strOut.substring(11) : ''
							);
						}
						break;

					case '3':
					case '8':
					case '9':
						if (strOut.length >= 11) {
							strOut = ''.concat(
								strOut.substring(0, 4),
								' ',
								strOut.substring(4, 7),
								' ',
								strOut.substring(7, 11),
								strOut.length > 11 ? ' x' + strOut.substring(11) : ''
							);
						}
						break;

					case '5':
					case '7':
						if (strOut.length >= 11) {
							strOut = ''.concat(strOut.substring(0, 5), ' ', strOut.substring(5, 11));
						}
						break;
				}
			}
			break;
		}
	}
	return strOut;
}
export function isMobile(str: string, culture: Cultures = 'en-us'): boolean {
	if (str) {
		let regex: RegExp;
		switch (culture) {
			case 'en-us':
				regex = /^(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?))[2-9]\d{2}[- ]?\d{4}$/;
				break;

			case 'en-nz':
				regex = /^[0][2][- .]?\d{3}[- .]?\d{4}\d?\d?$/;
				break;

			case 'en-au':
				regex = /^[0][4-5][- .]?\d{5}[- .]?\d{3}$/;
				break;

			case 'en-ca':
				regex = /^(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?))[2-9]\d{2}[- ]?\d{4}$/;
				break;

			case 'en-gb':
				regex = /^[0][7]\d{3}[- .]?\d{6}$/;
				break;

			default:
				regex = /^\d{7}\d?\d?$/;
				break;
		}
		return regex.test(str);
	}
	return false;
}
export function mobileFormat(culture: Cultures = 'en-us'): string {
	let strOut = '';
	switch (culture) {
		case 'en-us':
			strOut = '(999) 999-9999';
			break;

		case 'en-nz':
			strOut = '(02) 999 9999';
			break;

		case 'en-au':
			strOut = '0[4|5] 99999 999';
			break;

		case 'en-ca':
			strOut = '(999) 999-9999';
			break;

		case 'en-gb':
			strOut = '07999 999999';
			break;
	}
	return strOut;
}
export function formatMobile(str: string, culture: Cultures = 'en-us'): string {
	let strOut = ('' + str).replace(/\D/g, '');
	switch (culture) {
		case 'en-us':
			if (strOut[0] != '1') {
				if (strOut.length > 6) {
					strOut = ''.concat(
						'(',
						strOut.substring(0, 3),
						') ',
						strOut.substring(3, 6),
						'-',
						strOut.substring(6, Math.min(strOut.length, 10))
					);
				} else if (strOut.length > 3) {
					strOut = ''.concat(strOut.substring(0, 3), '-', strOut.substring(3));
				}
			}
			break;

		case 'en-nz':
			if (strOut[0] == '0') {
				if (strOut.length > 5) {
					strOut = ''.concat(
						'(',
						strOut.substring(0, 2),
						') ',
						strOut.substring(2, 5),
						' ',
						strOut.substring(5, Math.min(strOut.length, 11))
					);
				} else if (strOut.length > 3) {
					strOut = ''.concat(strOut.substring(0, 3), ' ', strOut.substring(3));
				}
			}
			break;

		case 'en-au':
			if (strOut[0] == '0') {
				if (strOut.length > 7) {
					strOut = ''.concat(
						strOut.substring(0, 2),
						' ',
						strOut.substring(2, 7),
						' ',
						strOut.substring(7, Math.min(strOut.length, 10))
					);
				} else if (strOut.length > 5) {
					strOut = ''.concat(strOut.substring(0, 5), ' ', strOut.substring(5));
				}
			}
			break;

		case 'en-ca':
			if (strOut[0] != '1') {
				if (strOut.length > 6) {
					strOut = ''.concat(
						'(',
						strOut.substring(0, 3),
						') ',
						strOut.substring(3, 6),
						'-',
						strOut.substring(6, Math.min(strOut.length, 10))
					);
				} else if (strOut.length > 3) {
					strOut = ''.concat(strOut.substring(0, 3), '-', strOut.substring(3));
				}
			}
			break;

		case 'en-gb':
			if (strOut[0] == '0') {
				if (strOut.length > 5) {
					strOut = ''.concat(strOut.substring(0, 5), ' ', strOut.substring(5, Math.min(strOut.length, 11)));
				}
			}
			break;
	}

	return strOut;
}
export function isZip(str: string, culture: Cultures = 'en-us'): boolean {
	if (str) {
		let regex: RegExp;
		switch (culture) {
			case 'en-us':
				regex = /^\d{5}(?:-?(\d{4})?)?$/;
				break;

			case 'en-nz':
				regex = /^\d{4}$/;
				break;

			case 'en-au':
				regex = /^\d{4}$/;
				break;

			case 'en-ca':
				regex = /^[A-Z]\d[A-Z] ?\d[A-Z]\d$/;
				break;

			case 'en-gb':
				regex = /^[A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;
				break;

			default:
				regex = /^\d{4}\d?$/;
				break;
		}
		return regex.test(str);
	}
	return false;
}
export function zipFormat(culture: Cultures = 'en-us'): string {
	let strOut = '';
	switch (culture) {
		case 'en-us':
			strOut = '99999-9999';
			break;

		case 'en-nz':
			strOut = '9999';
			break;

		case 'en-au':
			strOut = '9999';
			break;

		case 'en-ca':
			strOut = 'A9A 9A9';
			break;

		case 'en-gb':
			strOut = 'A[A]9[A|9] 9AA';
			break;
	}
	return strOut;
}
export function formatZip(str: string, culture: Cultures = 'en-us'): string {
	let strOut = '' + str;
	if(str) {
		switch (culture) {
			case 'en-us':
				strOut = strOut.replace(/\D/g, '');
				if (strOut.length > 5) {
					strOut = ''.concat(strOut.substring(0, 5), '-', strOut.substring(5, Math.min(strOut.length, 9)));
				}
				break;

			case 'en-nz':
				strOut = strOut.replace(/\D/g, '');
				if (strOut.length > 4) {
					strOut = strOut.substring(0, 4);
				}
				break;

			case 'en-au':
				strOut = strOut.replace(/\D/g, '');
				if (strOut.length === 4) {
					strOut = strOut.substring(0, 4);
				}
				break;

			case 'en-ca':
				strOut = strOut.replace(/(_|\W)/g, '');
				if (strOut.length > 3) {
					strOut = ''.concat(strOut.substring(0, 3), ' ', strOut.substring(3, Math.min(strOut.length, 6)));
				}
				break;

			case 'en-gb':
				strOut = strOut.replace(/(_|\W)/g, '');
				if (strOut.length > 3) {
					strOut = ''.concat(
						strOut.substring(0, strOut.length - 3),
						' ',
						strOut.substring(strOut.length - 3, Math.min(strOut.length, 6))
					);
				}
				break;
		}
	}
	return strOut;
}
export function isSsn(str: string, culture: Cultures = 'en-us'): boolean {
	if (str) {
		let regex;
		switch (culture) {
			case 'en-us':
				regex = /^\d{3}-?\d{2}-?\d{4}$/;
				break;

			case 'en-nz':
				regex = /^([a-hj-np-zA-HJ-NP-Z]){3}\d{4}$/;
				break;

			case 'en-au':
				regex = /^\d{4} ?\d{5} ?\d$/;
				break;

			case 'en-ca':
				regex = /^\d{3} ?\d{3} ?\d{3}$/;
				break;

			case 'en-gb':
				regex = /^\d{3} ?\d{3} ?\d{4}$/;
				break;

			default:
				regex = /^\d{9}\d?$/;
				break;
		}
		return regex.test(str);
	}
	return false;
}
export function ssnFormat(culture: Cultures = 'en-us'): string {
	let strOut = '';
	switch (culture) {
		case 'en-us':
			strOut = '999-99-9999';
			break;

		case 'en-nz':
			strOut = 'AAA9999';
			break;

		case 'en-au':
			strOut = '9999 99999 9';
			break;

		case 'en-ca':
			strOut = '999 9999';
			break;

		case 'en-gb':
			strOut = '999 999 9999';
			break;
	}
	return strOut;
}
export function formatSsn(str: string, culture: Cultures = 'en-us'): string {
	let strOut = '' + str;
	switch (culture) {
		case 'en-us':
			strOut = strOut.replace(/\D/g, '');
			if (strOut.length > 5) {
				strOut = ''.concat(
					strOut.substring(0, 3),
					'-',
					strOut.substring(3, 5),
					'-',
					strOut.substring(5, Math.min(strOut.length, 9))
				);
			}
			break;

		case 'en-nz':
			strOut = strOut.replace(/(_|\W)/g, '');
			if (strOut.length > 7) {
				strOut = strOut.substring(0, 7);
			}
			break;

		case 'en-au':
			strOut = strOut.replace(/\D/g, '');
			if (strOut.length > 9) {
				strOut = ''.concat(strOut.substring(0, 4), ' ', strOut.substring(4, 9), ' ', strOut.substring(9, 10));
			}
			break;

		case 'en-ca':
			strOut = strOut.replace(/\D/g, '');
			if (strOut.length > 6) {
				strOut = ''.concat(
					strOut.substring(0, 3),
					' ',
					strOut.substring(3, 6),
					' ',
					strOut.substring(6, Math.min(strOut.length, 9))
				);
			}
			break;

		case 'en-gb':
			strOut = strOut.replace(/\D/g, '');
			if (strOut.length > 6) {
				strOut = ''.concat(
					strOut.substring(0, 3),
					' ',
					strOut.substring(3, 6),
					' ',
					strOut.substring(6, Math.min(strOut.length, 10))
				);
			}
			break;
	}
	return strOut;
}
export function isDate(str: string, culture: Cultures = 'en-us'): boolean {
	if (str) {
		let regex: RegExp;
		switch (culture) {
			case 'en-us':
				regex =
					/((0[13578]|1[02])[\/.]31[\/.](19|20)[0-9]{2})|((01|0[3-9]|1[0-2])[\/.](29|30)[\/.](19|20)[0-9]{2})|((0[1-9]|1[0-2])[\/.](0[1-9]|1[0-9]|2[0-8])[\/.](19|20)[0-9]{2})|((02)[\/.]29[\/.](((19|20)(04|08|[2468][048]|[13579][26]))|2000))/;
				break;
			case 'en-nz':
				regex =
					/(31[\/.](0[13578]|1[02])[\/.](19|20)[0-9]{2})|(29|30)[\/.]((01|0[3-9]|1[0-2])[\/.](19|20)[0-9]{2})|(0[1-9]|1[0-9]|2[0-8])[\/.]((0[1-9]|1[0-2])[\/.](19|20)[0-9]{2})|29[\/.]((02)[\/.](((19|20)(04|08|[2468][048]|[13579][26]))|2000))/;
				break;
			case 'en-au':
				regex =
					/(31[\/.](0[13578]|1[02])[\/.](19|20)[0-9]{2})|(29|30)[\/.]((01|0[3-9]|1[0-2])[\/.](19|20)[0-9]{2})|(0[1-9]|1[0-9]|2[0-8])[\/.]((0[1-9]|1[0-2])[\/.](19|20)[0-9]{2})|29[\/.]((02)[\/.](((19|20)(04|08|[2468][048]|[13579][26]))|2000))/;
				break;
			case 'en-ca':
				regex =
					/((18|19|20)[0-9]{2}[\-.](0[13578]|1[02])[\-.](0[1-9]|[12][0-9]|3[01]))|(18|19|20)[0-9]{2}[\-.](0[469]|11)[\-.](0[1-9]|[12][0-9]|30)|(18|19|20)[0-9]{2}[\-.](02)[\-.](0[1-9]|1[0-9]|2[0-8])|(((18|19|20)(04|08|[2468][048]|[13579][26]))|2000)[\-.](02)[\-.]29/;
				break;

			case 'en-gb':
				regex =
					/(31[\/.](0[13578]|1[02])[\/.](19|20)[0-9]{2})|(29|30)[\/.]((01|0[3-9]|1[0-2])[\/.](19|20)[0-9]{2})|(0[1-9]|1[0-9]|2[0-8])[\/.]((0[1-9]|1[0-2])[\/.](19|20)[0-9]{2})|29[\/.]((02)[\/.](((19|20)(04|08|[2468][048]|[13579][26]))|2000))/;
				break;
			default:
				regex =
					/((18|19|20)[0-9]{2}[\-.](0[13578]|1[02])[\-.](0[1-9]|[12][0-9]|3[01]))|(18|19|20)[0-9]{2}[\-.](0[469]|11)[\-.](0[1-9]|[12][0-9]|30)|(18|19|20)[0-9]{2}[\-.](02)[\-.](0[1-9]|1[0-9]|2[0-8])|(((18|19|20)(04|08|[2468][048]|[13579][26]))|2000)[\-.](02)[\-.]29/;
				break;
		}
		return regex.test(str);
	}
	return false;
}
export function dateFormat(culture: Cultures = 'en-us'): string {
	let strOut = '';
	switch (culture) {
		case 'en-us':
			strOut = 'MM/DD/YYYY';
			break;

		case 'en-nz':
			strOut = 'DD/MM/YYYY';
			break;

		case 'en-au':
			strOut = 'DD/MM/YYYY';
			break;

		case 'en-ca':
			strOut = 'YYYY-MM-DD';
			break;

		case 'en-gb':
			strOut = 'DD/MM/YYYY';
			break;
	}
	return strOut;
}
export function isTime(str: string, culture: Cultures = 'en-us'): boolean {
	if (str) {
		let regex: RegExp;
		switch (culture) {
			case 'en-us':
				regex =
					/(0[1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|([1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|(1[0-2]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))/;
				break;

			case 'en-nz':
				regex = /^(0?\d|[01]\d|2[0-3]):?([0-5]\d)$/;
				break;

			case 'en-au':
				regex =
					/(0[1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|([1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|(1[0-2]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))/;
				break;

			case 'en-ca':
				regex = /^(0?\d|[01]\d|2[0-3]):?([0-5]\d)$/;
				break;
			case 'en-gb':
				regex =
					/(0[1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|([1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|(1[0-2]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))/;
				break;

			default:
				regex = /^(0?\d|[01]\d|2[0-3]):?([0-5]\d)$/;
				break;
		}
		return regex.test(str);
	}
	return false;
}
export function timeFormat(culture: Cultures = 'en-us'): string {
	let strOut = '';
	switch (culture) {
		case 'en-us':
			strOut = 'HH:MM AM/PM';
			break;

		case 'en-nz':
			strOut = 'HH:MM';
			break;

		case 'en-au':
			strOut = 'HH:MM AM/PM';
			break;

		case 'en-ca':
			strOut = 'HH:MM';
			break;
		case 'en-gb':
			strOut = 'HH:MM AM/PM';
			break;
	}
	return strOut;
}
export function lunhCheck(str: string) {
	let bRet = false;
	if (str) {
		const len = str.length;
		const oddoeven = len & 1;
		let sum = 0;
		for (let count = 0; count < len; count++) {
			let digit = parseInt(str.substring(count, count + 1));
			if (((count & 1) ^ oddoeven) == 0) {
				// double up every second digit starting with the second on the right
				digit *= 2;
				if (digit > 9) digit -= 9; //constis is -10 (to add LSD) +1 (to add MSB which is always 1 here)
			}
			sum += digit;
		}
		bRet = sum % 10 == 0;
	}

	return bRet;
}
export function isCardNumber(str: string, type: BankAccounts) {
	let bRet = false;
	if (str) {
		str = str.replace(/\D/g, '');
		switch (type) {
			case 1: // AMEX
				if (str.length == 15 && (str.startsWith('34') || str.startsWith('37'))) {
					bRet = lunhCheck(str);
				}
				break;

			case 2: // VISA
				if ((str.length == 13 || str.length == 16) && str.startsWith('4')) {
					bRet = lunhCheck(str);
				}
				break;

			case 4: // DISCOVER
				if (str.length == 16 && str.startsWith('6011')) {
					bRet = lunhCheck(str);
				}
				break;

			case 6: // MASTERCARD
				if (
					str.length == 16 &&
					(str.startsWith('51') ||
						str.startsWith('52') ||
						str.startsWith('53') ||
						str.startsWith('54') ||
						str.startsWith('55') ||
						str.startsWith('22') ||
						str.startsWith('23') ||
						str.startsWith('24') ||
						str.startsWith('25') ||
						str.startsWith('26') ||
						str.startsWith('27'))
				) {
					bRet = lunhCheck(str);
				}
				break;

			case 8: // DINERS CLUB
				if (
					str.length == 14 &&
					(str.startsWith('300') ||
						str.startsWith('301') ||
						str.startsWith('302') ||
						str.startsWith('303') ||
						str.startsWith('304') ||
						str.startsWith('305') ||
						str.startsWith('36') ||
						str.startsWith('38'))
				) {
					bRet = lunhCheck(str);
				}
				break;

			case 17: // JCB
				if (
					(str.length == 16 && str.startsWith('35')) ||
					(str.length == 15 && (str.startsWith('2131') || str.startsWith('1800')))
				) {
					bRet = lunhCheck(str);
				}
				break;

			default:
				break;
		}
	}
	return bRet;
}
export function isCardCvc(str: string, type: BankAccounts) {
	let bRet = false;
	if (str) {
		switch (type) {
			case 1: // AMEX
				bRet = /^\d{4}$/.test(str);
				break;

			case 2: // VISA
			case 6: // MASTERCARD
				bRet = /^\d{3,4}$/.test(str);
				break;

			case 4: // DISCOVER
			case 8: // DINERS CLUB
			case 17: // JCB
				bRet = /^\d{3}$/.test(str);
				break;

			default:
				break;
		}
	}
	return bRet;
}
export function isRoutingAba(str: string) {
	let bRet = false;
	if (str) {
		bRet = /^\d{9}$/.test(str);
	}
	return bRet;
}
export function isAchAccount(str: string) {
	let bRet = false;
	if (str) {
		bRet = /^\d{6,17}$/.test(str);
	}
	return bRet;
}
export function fixCase(str: string) {
	return str != null && str.length > 0
		? str.length >= 3
			? str.charAt(0).toUpperCase() + str.charAt(1).toLowerCase() + str.substr(2)
			: str.length >= 2
			? str.charAt(0).toUpperCase() + str.charAt(1).toLowerCase()
			: str.charAt(0).toUpperCase()
		: '';
}

export function isURL(str: string) {
	if (str) {
		return /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(str);
	}

	return true;
}
