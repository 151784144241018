<template>
	<FormOverlay
		v-model="isOpenModal"
		:loading="isLoading"
		:title="getTitle"
		:rules="rules"
		:model="selectedSubmission"
		:submit-button-text="submitButtonText"
		@submit="submitForm"
		@close="closeModal"
	>
		<n-form-item class="col-span-3">
			<n-input v-model:value="selectedSubmission.Headline" :disabled="isThisForumPost" />
		</n-form-item>

		<n-form-item v-if="getTagsBySubmissionCategory.length" class="col-span-3">
			<div class="w-full flex justify-between flex-col xl:flex-row">
				<n-checkbox
					v-for="tag in getTagsBySubmissionCategory"
					:key="tag"
					:checked="isTagSelected(tag)"
					:disabled="isThisForumPost"
					@click="addTagToSelectedTags(tag)"
					><span :class="isTagSelected(tag) ? 'text-primary' : ''">{{ separateMultiwordTag(tag) }}</span>
				</n-checkbox>
			</div>
		</n-form-item>

		<n-form-item class="col-span-3">
			<div class="flex flex-row col-span-3 space-x-2 xl:space-x-16 justify-between">
				<div class="col-span-2">
					<UploadImage v-model="selectedSubmission.Picture" />
				</div>
				<div class="flex flex-col col-span-1 max-h-[50%] w-full">
					<QuillEditor v-model:content="selectedSubmission.Body" content-type="html" toolbar="full" />
				</div>
			</div>
		</n-form-item>

		<n-form-item class="col-span-3">
			<n-input v-model:value="selectedSubmission.LinkUrl" :status="URLStatus" placeholder="Link URL" />
		</n-form-item>
		<PaymentInformation
			v-if="isPaymentEnabled"
			:selected-classified-tag="convertTagsToNumber(selectedTags)"
			:category-name="getCategoryNameBySubmissionCategory"
			@publishing-fees="(value) => (selectedSubmission.PublishingFee = value)"
			@publishing-term="(value) => (selectedSubmission.PublishingTerm = value)"
			@coupon-code="(value) => (selectedSubmission.CouponCode = value)"
			@payment-verified="(value) => (selectedSubmission.PaymentOnFile = value)"
			@terms-accepted="(value) => (isTermsAccepted = value)"
		/>
		<n-form-item class="col-span-3">
			<n-input v-model:value="selectedSubmission.NoteToEditor" placeholder="Note to editor" />
		</n-form-item>
		<n-form-item class="col-span-3">
			<n-input v-model:value="selectedSubmission.Location" placeholder="Location" />
		</n-form-item>
		<div class="flex col-span-3 justify-between">
			<n-form-item v-if="isAdmin && submitButtonText === 'Update'">
				<p>Status: {{ selectedSubmission.IsEnabled ? 'Published' : 'Submitted' }}</p>
			</n-form-item>
		</div>
		<template #footer>
			<div v-if="submitButtonText === 'Update'" class="flex justify-start">
				<n-form-item>
					<NButton type="primary" @click="handleSubmissionPublish()">
						<span>{{ selectedSubmission.IsEnabled ? 'Unpublish' : 'Publish' }}</span>
					</NButton>
				</n-form-item>
			</div>
		</template>
	</FormOverlay>
</template>

<script lang="ts" setup>
import {
	convertNumberToPowerOfTwo,
	separateMultiwordTag,
	convertTagsToNumber,
	getTagsByCategoryAndNumber,
	getDeepCopy,
} from '@/plugins/helpers';
import { ISubmissionItem } from '@/types/Submission';
import { useSubmissionStore } from '@/store/submissions';
import { Tag, Category } from '@/types/constants';
import { isURL } from '@/modules/validation';
import { useAuthenticationStore } from '@/store/auth';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { useNotifications } from '@/composables/useNotifications';
import { FormItemRule } from 'naive-ui';

const {
	modelValue = false,
	submission,
	title = '',
	type = 'update',
	isThisForumPost = false,
	submitButtonText = 'Save',
} = defineProps<{
	modelValue: boolean;
	submission: ISubmissionItem;
	title?: string;
	type?: string;
	isThisForumPost?: boolean;
	submitButtonText?: string;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
	(event: 'submit', value: ISubmissionItem): void;
	(event: 'published'): void;
}>();

const authStore = useAuthenticationStore();

let isTermsAccepted = $ref(false);

async function handleSubmissionPublish() {
	selectedSubmission.IsEnabled = !selectedSubmission.IsEnabled;
	submission.IsEnabled = !submission.IsEnabled;
	await handleFormSubmit(submission);
	emit('published');
}

async function submitForm() {
	selectedSubmission.Tags = convertTagsToNumber(selectedTags);
	await handleFormSubmit(selectedSubmission, true);
}

const submissionStore = useSubmissionStore();
const { createNotification } = useNotifications();

let selectedSubmission: ISubmissionItem = $ref(getDeepCopy(submission));

let isLoading = $ref(false);

let URLStatus = $ref<'success' | 'error' | undefined>('success');

const isPaymentEnabled = $computed(() => type !== 'update' && !isThisForumPost && !isBlogPost);

const isClassifiedAd = $computed(() => selectedSubmission.Category === Category['Classifieds']);

const loadSelectedTags = $computed(() => {
	let powerOfTwo: number[] = convertNumberToPowerOfTwo(selectedSubmission.Tags);
	const tags = getTagsByCategoryAndNumber(selectedSubmission.Category, powerOfTwo);
	if (tags.length && !getTagsBySubmissionCategory.length) return tags;

	tags.push(getTagsBySubmissionCategory[0]);

	return tags;
});

let isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => emit('update:modelValue', value),
});

let isAdmin = $computed(() => authStore.user?.IsAdmin);

let isBlogPost = $computed(() => getCategoryNameBySubmissionCategory === 'blog post');

let getCategoryNameBySubmissionCategory = $computed(() => {
	if (selectedSubmission.Category === Category['Classifieds']) return 'classified ad';

	if (selectedSubmission.Category === Category['SponsoredAds']) return 'sponsor ad';

	return 'blog post';
});

let getTitle = $computed(() => title ?? `Update ${getCategoryName} by ${selectedSubmission.Author}`);

function isTagSelected(tag: Tag) {
	return selectedTags.includes(tag);
}

let getTagsBySubmissionCategory = $computed(() => {
	if (selectedSubmission.Category === Category['Classifieds'])
		return Object.values(ClassifiedTags).filter((tag) => typeof tag === 'string') as Tag[];

	if (selectedSubmission.Category === Category['SponsoredAds']) return [];

	return Object.values(NormalTags).filter((tag) => typeof tag === 'string') as Tag[];
});

let selectedTags: Tag[] = $ref(loadSelectedTags);

function addTagToSelectedTags(tag: Tag) {
	if (isThisForumPost) return;

	if (selectedTags.includes(tag)) {
		selectedTags = selectedTags.filter((selectedTag) => selectedTag !== tag);
		if (!selectedTags.length) selectedTags.push(getTagsBySubmissionCategory[0]);
		return;
	}

	if (isClassifiedAd) selectedTags = [];

	selectedTags.push(tag);
}

function checkPaymentStatus(submissionToSubmit: ISubmissionItem) {
	submissionToSubmit.CouponCode ? (submissionToSubmit.PaymentOnFile = null) : (submissionToSubmit.CouponCode = null);
	if (
		isPaymentEnabled &&
		!isTermsAccepted &&
		(!submissionToSubmit.CouponCode || submissionToSubmit.CouponCode === '')
	) {
		createNotification('error', {
			title: 'Error',
			content: 'You must accept the terms and conditions',
		});
		isLoading = false;
		return false;
	}
	return true;
}

function showErrorNotification(submissionToSubmit: ISubmissionItem, error: Error) {
	if (submissionToSubmit.PaymentOnFile === null && submissionToSubmit.CouponCode !== undefined) {
		createNotification('error', {
			title: 'Error',
			content: 'Invalid coupon code or coupon code already used',
		});
		return;
	}
	createNotification('error', {
		title: 'Error',
		content: error.message ?? 'There was an error saving your submission',
	});
}

async function handleFormSubmit(submissionToSubmit: ISubmissionItem, hideModal = false) {
	isLoading = true;
	if (!checkPaymentStatus(submissionToSubmit)) return;
	try {
		type === 'update'
			? await submissionStore.updateSubmission(submissionToSubmit)
			: await submissionStore.saveSubmission(submissionToSubmit);

		if (hideModal) {
			emit('submit', selectedSubmission);
			closeModal();
		}
	} catch (error) {
		console.error(error);
		showErrorNotification(submissionToSubmit, error as Error);
	} finally {
		isLoading = false;
	}
}

function closeModal() {
	selectedSubmission = getDeepCopy(submission);
	isOpenModal = false;
}

const rules = {
	LinkUrl: {
		validator: (_: FormItemRule, value: string) => {
			return value ? isURL(value) : true;
		},
	},
};
</script>
<style scoped>
.n-form-item {
	grid-template-rows: 0;
}

:deep(.n-form-item-feedback-wrapper) {
	display: none;
}
</style>
