<template>
	<MagnifyingGlassIcon class="w-6 h-6 cursor-pointer" @click="openSearchModal" />
	<SearchModal
		v-if="isOpenModal"
		v-model="isOpenModal"
		:title="title"
		@update:search-value="(value) => emit('update:searchValue', value)"
	/>
</template>
<script setup lang="ts">
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import SearchModal from '@/components/Modal/SearchModal.vue';

const { title = '' } = defineProps<{
	title?: string;
	searchValue: string;
}>();

const emit = defineEmits<{
	(event: 'update:searchValue', value: string): void;
}>();

let isOpenModal = $ref(false);

function openSearchModal() {
	isOpenModal = true;
}
</script>
