<template>
	<TransitionRoot as="template" :show="open">
		<Dialog class="relative z-10" @close="open = false">
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed z-10 inset-0 overflow-y-auto">
				<div class="flex items-end sm:items-center justify-center min-h-full text-center xs:p-0">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							:class="
								modalClass ||
								'relative lg:max-w-[70%] w-full space-y-3 bg-white md:rounded-lg p-6 text-left overflow-hidden shadow-xl transform transition-all xs:my-8'
							"
						>
							<div class="flex">
								<slot name="title"></slot>
							</div>
							<div class="flex w-full">
								<slot name="body"></slot>
							</div>
							<div class="flex w-full flex-col">
								<slot name="footer"></slot>
							</div>
						</div>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, TransitionChild, TransitionRoot } from '@headlessui/vue';

const { modelValue = false, modalClass } = defineProps<{
	modelValue: boolean;
	modalClass?: string;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
}>();

const open = $computed({
	get: () => modelValue,
	set: (value) => emit('update:modelValue', value),
});
</script>
