<template>
	<FormOverlay
		v-model="isOpenModal"
		:title="`Sign up as a new ${getSignUpTitle}`"
		:loading="isLoading"
		:disabled="isLoading"
		:model="newAuthor"
		:rules="rules"
		@submit="submitForm"
		@close="closeModal"
	>
		<n-form-item class="row-span-2 mx-auto text-center">
			<UploadImage v-model="newAuthor.Picture" is-author-image />
		</n-form-item>
		<n-form-item label="Organization" path="Organization">
			<n-input v-model:value="newAuthor.Organization" placeholder="Organization" />
		</n-form-item>
		<n-form-item label="Country" path="CountryID">
			<n-select
				v-model:value="newAuthor.CountryID"
				:options="transformedCountries"
				placeholder="Please select your country"
				:on-update:value="handleCountryChange"
			/>
		</n-form-item>	

		<n-form-item label="Username" path="UserName">
			<n-input v-model:value="newAuthor.UserName" placeholder="Username" />
		</n-form-item>
		<n-form-item label="Login Name" path="LoginName">
			<n-input v-model:value="newAuthor.LoginName" placeholder="Login Name" />
		</n-form-item>	

		<n-form-item label="Salutation" path="Salutation">
			<n-input v-model:value="newAuthor.Salutation" placeholder="Salutation" />
		</n-form-item>
		<n-form-item label="First Name" path="FirstName">
			<n-input v-model:value="newAuthor.FirstName" placeholder="First Name" />
		</n-form-item>
		<n-form-item label="Middle Name" path="MiddleName">
			<n-input v-model:value="newAuthor.MiddleName" placeholder="Middle Name" />
		</n-form-item>

		<n-form-item label="Last Name" path="LastName">
			<n-input v-model:value="newAuthor.LastName" placeholder="Last Name" />
		</n-form-item>
		<n-form-item label="Suffix" path="Suffix">
			<n-input v-model:value="newAuthor.Suffix" placeholder="Suffix" />
		</n-form-item>
		<n-form-item label="Email" path="Email">
			<n-input v-model:value="newAuthor.Email" placeholder="Email" />
		</n-form-item>	

		<n-form-item label="Password" path="PwdHash">
			<n-input v-model:value="newAuthor.PwdHash" placeholder="Password" type="password" show-password-on="mousedown" />
		</n-form-item>
		<n-form-item label="Confirm Password" path="ConfirmPassword">
			<n-input
				v-model:value="newAuthor.ConfirmPassword"
				placeholder="Confirm Password"
				type="password"
				show-password-on="mousedown"
			/>
		</n-form-item>	
		<n-form-item label="Mobile" path="Phone">
			<n-input
				v-model:value="newAuthor.Phone"
				v-maska="{
					mask: getMobilePattern,
					tokens: {
						Z: {
							pattern: '4|5',
						},
					},
				}"
				:placeholder="mobileFormat(selectedCulture)"
			/>
		</n-form-item>		

		<n-form-item label="Security Question" path="SecurityQuestion">
			<n-input v-model:value="newAuthor.SecurityQuestion" placeholder="Security Question" type="text" />
		</n-form-item>
		<n-form-item label="Security Answer" path="SecurityAnswerHash">
			<n-input v-model:value="newAuthor.SecurityAnswerHash" placeholder="Security Answer" type="text" />
		</n-form-item>		
		<n-form-item label="Office Phone" path="Office">
			<n-input v-model:value="newAuthor.Office" v-maska="getPhonePattern" :placeholder="phoneFormat(selectedCulture)" />
		</n-form-item>	

		<n-form-item label="Address" path="Address">
			<n-input v-model:value="newAuthor.Address" placeholder="Address" />
		</n-form-item>
		<n-form-item label="Address2" path="Address2">
			<n-input v-model:value="newAuthor.Address2" placeholder="Address2" />
		</n-form-item>	
		<n-form-item label="Timezone" path="TimezoneID">
			<n-select
				v-model:value="newAuthor.TimezoneID"
				:options="transformedTimezones"
				:disabled="!isSelectedCountry"
				placeholder="Please select your timezone"
			/>
		</n-form-item>	

		<n-form-item label="City" path="City">
			<n-input v-model:value="newAuthor.City" placeholder="City" />
		</n-form-item>	
		<n-form-item label="State" path="StateID">
			<n-select
				v-model:value="newAuthor.StateID"
				:disabled="!isSelectedCountry"
				:options="transformedStates"
				placeholder="Please select your state"
			/>
		</n-form-item>	
		<n-form-item :label="selectedCulture === 'en-us' ? 'Zip' : 'Postal Code'" path="ZIP">
			<n-input
				v-model:value="newAuthor.ZIP"
				v-maska="{
					mask: getZipPattern,
					tokens: {
						Z: {
							pattern: /[0-9|A-Za-z|' ']/,
						},
					},
				}"
				:placeholder="zipFormat(selectedCulture)"
			/>
		</n-form-item>	


		<n-form-item label="County" path="County">
			<n-input v-model:value="newAuthor.County" placeholder="County" />
		</n-form-item>	
		<n-form-item class="col-span-2" label="Website" path="Website">
			<n-input v-model:value="newAuthor.Website" placeholder="Website" />
		</n-form-item>	

		<n-form-item class="col-span-3" label="Summary" path="Summary">
			<n-input
				v-model:value="newAuthor.Summary"
				type="textarea"
				placeholder="Summary"
				class="col-span-1 row-span-3 sm:col-span-2 lg:row-span-3 lg:col-span-3"
			/>
		</n-form-item>
		<SelectSponsorship
			v-if="isSponsor"
			v-model="sponsorship"
			:country="newAuthor.CountryID"
			@terms-accepted="(value) => (isTermsAccepted = value)"
			@coupon-code="(value) => (newAuthor.CouponCode = value)"
			@payment-verified="(value) => (newAuthor.PaymentOnFile = value)"
		/>
	</FormOverlay>
	<SignUpReceived
		v-if="isSignUpReceived"
		v-model="isSignUpReceived"
		:title="`New ${getSignUpTitle} application for ${newAuthor.UserName}`"
		:author-type="getSignUpTitle"
		@close="signUpReceivedClose"
	>
		<template #salutation
			><p>
				{{ newAuthor.Salutation ?? 'Dear' }}
				<b>{{ newAuthor.Suffix }} {{ newAuthor.FirstName }} {{ newAuthor.MiddleName }} {{ newAuthor.LastName }}</b
				>,
			</p></template
		>
	</SignUpReceived>
</template>
<script lang="ts" setup>
import { useAuthors } from '@/store/authors';
import { IAuthorSignUp } from '@/types/Author';
import { DropdownMixedOption } from 'naive-ui/es/dropdown/src/interface';
import { Cultures, isURL } from '@/modules/validation';
import SignUpReceived from './SignUpReceived.vue';
import SelectSponsorship from './Sponsorship/SelectSponsorship.vue';
import { getCultureByCountryID } from '@/plugins/helpers';
import { useAuthenticationStore } from '@/store/auth';
import { zipFormat, mobileFormat, phoneFormat } from '@/modules/validation';
import { useNotifications } from '@/composables/useNotifications';
import { ISponsorshipSelection } from '@/types/Sponsorship';
import { FormItemRule, NFormItem, NInput, NSelect } from 'naive-ui';
import { transformDataToLabelAndValue } from '@/plugins/helpers';
import { AuthorTypes } from '@/types/constants';
const { modelValue = false, authorType } = defineProps<{
	modelValue: boolean;
	authorType: AuthorTypes;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
}>();

let authorStore = useAuthors();
const authStore = useAuthenticationStore();
let mainStore = useStore();
const { createNotification } = useNotifications();

let sponsorship: ISponsorshipSelection = $ref({} as ISponsorshipSelection);
let isTermsAccepted = $ref(false);
let isSignUpReceived: boolean = $ref(false);
let selectedCulture: Cultures = $ref('en-us');
let transformedCountries: DropdownMixedOption[] = $ref([]);
let defaultCountryID = 1;
let transformedStates: DropdownMixedOption[] = $ref([]);
let defaultStateID = 20;
let transformedTimezones: DropdownMixedOption[] = $ref([]);
let defaultTimezoneID = 2;
let isLoading = $ref(false);
const user = $computed(() => authStore.user);
let defaultUserWithPayment: IAuthorSignUp = $ref({
	UserName: user?.UserName ?? '',
	LoginName: user?.LoginName ?? '',	
	PwdHash: '',
	SecurityQuestion: '',
	SecurityAnswerHash: '',
	Types: authorType,
	PaymentOnFile: null,
	Sponsorship: 0,
	SponsorPeriod: 12,
	FirstName: user?.FirstName ?? '',
	MiddleName: user?.MiddleName ?? '',
	LastName: user?.LastName ?? '',
	Suffix: user?.Suffix ?? '',
	Salutation: user?.Salutation ?? '',
	Email: user?.Email ?? '',
	Phone: formatPhone(user?.Phone ?? ''),
	Website: user?.Website ?? '',
	CountryID: user?.CountryID,
	StateID: user?.StateID,
	TimezoneID: user?.TimezoneID,	
	City: user?.City ?? '',
	Address: user?.Address ?? '',
	Address2: user?.Address2 ?? '',
	ZIP: formatZip(user?.ZIP ?? ''),
	Office: formatPhone(user?.Office ?? ''),	

} as IAuthorSignUp);

let newAuthor: IAuthorSignUp = $ref({ ...defaultUserWithPayment });
provide('newAuthor', newAuthor);

const getZipPattern = $computed(() =>
	selectedCulture === 'en-gb' ? 'SS#Z #SS' : zipFormat(selectedCulture).replace(/9/g, '#').replace(/A/g, 'S')
);
const getMobilePattern = $computed(() =>
	selectedCulture === 'en-au'
		? '0Z ##### ###'
		: mobileFormat(selectedCulture).replace(/9/g, '#').replace(/\[/g, '').replace(/\]/g, '')
);
const getPhonePattern = $computed(() =>
	phoneFormat(selectedCulture).replace(/9/g, '#').replace(/\[/g, '').replace(/\]/g, '')
);

const isAdvertiser = $computed(() => authorType === AuthorTypes.Advertisers);
const isSponsor = $computed(() => authorType === AuthorTypes.Sponsors);
const isAdmin = $computed(() => authorType === AuthorTypes.Admins);

const getSignUpTitle = $computed(() => {
	if (isSponsor) return 'Sponsor';
	if (isAdvertiser) return 'Advertiser';
	if (isAdmin) return 'Admin';	
	return 'Author';
});

let isSelectedCountry = $computed(() => newAuthor.CountryID);

let isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => emit('update:modelValue', value),
});

function transformCountries() {
	transformedCountries = transformDataToLabelAndValue(mainStore.countries);
	defaultCountryID = mainStore.defaultCountryId;
	newAuthor.CountryID = newAuthor.CountryID ?? defaultCountryID
}

async function loadStates() {
	isLoading = true;
	try {
		return getStates(newAuthor.CountryID ?? defaultCountryID);
	} catch (error) {
		console.log(error);
		return [];
	} finally {
		isLoading = false;
	}
}

async function loadTimezones() {
	isLoading = true;
	try {
		return getTimezones(newAuthor.CountryID ?? defaultCountryID);
	} catch (error) {
		console.log(error);
		return [];
	} finally {
		isLoading = false;
	}
}

const transformStates = async function () {
	if (!isSelectedCountry) return;
	const returnedStates = await loadStates();
	transformedStates = transformDataToLabelAndValue(returnedStates);
	defaultStateID = returnedStates.find (item => { item.IsDefault } )?.ID ?? mainStore.defaultStateId ?? undefined
	newAuthor.StateID = newAuthor.StateID ?? defaultStateID
};

const transformTimezones = async function () {
	if (!isSelectedCountry) return;
	const returnedTimezones = await loadTimezones();
	transformedTimezones = transformDataToLabelAndValue(returnedTimezones);
	defaultTimezoneID = returnedTimezones.find (item => { item.IsDefault } )?.ID ?? mainStore.defaultTimezoneId ?? undefined
	newAuthor.TimezoneID = newAuthor.TimezoneID ?? defaultTimezoneID
};

function signUpReceivedClose() {
	setAuthorToDefault();
	setDefaultCulture();
	isSignUpReceived = false;
	closeModal();
}

function setAuthorToDefault() {
	defaultUserWithPayment = {
	UserName: '',
	PwdHash: '',
	SecurityQuestion: '',
	SecurityAnswerHash: '',
	Types: authorType,
	PaymentOnFile: null,
	Sponsorship: 0,
	SponsorPeriod: 12,
	FirstName: '',
	MiddleName: '',
	LastName: '',
	Suffix: '',
	Salutation: '',
	Email: '',
	Phone: '',
	Website: '',
	CountryID: defaultCountryID,
	StateID: defaultStateID,
	TimezoneID: defaultTimezoneID,
	City: '',
	Address: '',
	Address2: '',
	ZIP: '',
	LoginName: '',
	} as IAuthorSignUp;
	newAuthor = { ...defaultUserWithPayment };
}

function findCultureIndexByCulture(culture: Cultures) {
	return mainStore.countries.findIndex((country) => country.Culture === culture);
}

async function setDefaultCulture() {
	const culture = authStore.culture;
	const cultureIndex = findCultureIndexByCulture(culture);
	newAuthor.CountryID = mainStore.countries[cultureIndex].ID;
	selectedCulture = culture as Cultures;
	await Promise.all([transformTimezones(), transformStates()]);
}

function closeModal() {
	isOpenModal = false;
}

function checkSponsorship() {
	if (!isSponsor) return;
	if (!isTermsAccepted && (newAuthor.CouponCode === '' || newAuthor.CouponCode === undefined)) {
		createNotification('error', {
			title: 'Error',
			content: 'Please accept the terms and conditions',
		});
		return;
	}
	newAuthor.Sponsorship = sponsorship.id;
	newAuthor.SponsorFee = sponsorship.value;
}

async function submitForm() {
	checkSponsorship();
	isLoading = true;
	try {
		newAuthor.ZIP?.replaceAll("-", "");
		await authorStore.createNewAuthorRequest(newAuthor);
		isSignUpReceived = true;
	} catch (error) {
		createNotification('error', {
			title: 'Error',
			content: String(error),
		});
	} finally {
		isLoading = false;
	}
}

let handleCountryChange = async function (value: number, _: DropdownMixedOption) {
	if (newAuthor.CountryID === value) return;
	newAuthor.CountryID = value;

	newAuthor.ZIP = '';
	newAuthor.Phone = '';
	newAuthor.Office = '';
	selectedCulture = getCultureByCountryID(mainStore.countries, newAuthor.CountryID);
	await Promise.all([transformTimezones(), transformStates()]);
};

let rules = {
	UserName: {
		required: true,
		message: 'Please input your username',
		trigger: 'submit',
	},
	LoginName: {
		required: true,
		message: 'Please input your login name',
		trigger: 'submit',
	},
	PwdHash: {
		required: true,
		message: 'Please input your password',
		trigger: 'submit',
	},
	SecurityQuestion: {
		required: true,
		message: 'Please input your security question',
		trigger: 'submit',
	},
	SecurityAnswerHash: {
		required: true,
		message: 'Please input your security answer',
		trigger: 'submit',
	},
	FirstName: {
		required: true,
		message: 'Please input your first name',
		trigger: 'submit',
	},
	LastName: {
		required: true,
		message: 'Please input your last name',
		trigger: 'submit',
	},
	Organization: {
		required: true,
		message: 'Please input your organization',
		trigger: 'submit',
	},
	CountryID: {
		required: true,
		message: 'Please select your country',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			return value !== undefined;
		},
	},
	TimezoneID: {
		required: true,
		message: 'Please select your timezone',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			return value !== undefined;
		},
	},
	Address: {
		required: true,
		message: 'Please input your address',
		trigger: 'submit',
	},
	City: {
		required: true,
		message: 'Please input your city',
		trigger: 'submit',
	},
	StateID: {
		required: true,
		validator: (_: FormItemRule, value: string) => {
			return value !== undefined;
		},
		message: 'Please select your state',
		trigger: 'submit',
	},
	Timezone: {
		required: true,
		message: 'Please input your timezone',
		trigger: 'submit',
	},
	ZIP: {
		required: true,
		message: 'Please input your zip',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			return isZip(value, selectedCulture);
		},
	},
	Email: {
		required: true,
		message: 'Please input your email',
		trigger: 'submit',
	},
	Office: {
		message: 'Please input your office phone',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			if (!value) return true;
			return isPhone(value, selectedCulture);
		},
	},
	Phone: {
		required: true,
		message: 'Please input your phone',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			return isMobile(value, selectedCulture);
		},
	},
	Summary: {
		required: true,
		message: 'Please input your summary',
		trigger: 'submit',
	},
	ConfirmPassword: {
		required: true,
		message: 'Please input your confirm password',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			return value === newAuthor.PwdHash;
		},
	},
	Website: {
		message: 'Please input a valid website',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			return value === '' || isURL(value);
		},
	},
};

watch(() => authStore.culture, setDefaultCulture, { immediate: true });

watch(
	() => authorType,
	() => {
		newAuthor.Types = authorType;
	},
	{ immediate: true }
);

onMounted(() => transformCountries());
</script>
<style scoped>
:deep(
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active
	) {
	-webkit-box-shadow: 0 0 0 30px white inset;
}
</style>
