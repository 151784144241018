<script lang="ts" setup>
import router, { adminRouteNames } from '@/router';
import { useAuthenticationStore } from '@/store/auth';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { Bars3Icon, UserCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { NAvatar, NDropdown } from 'naive-ui';
import { RouterLink } from 'vue-router';

const { isSmaller } = useBreakpoints(breakpointsTailwind);
const isSmall = $computed(() => isSmaller('md'));

const store = useStore();
const authStore = useAuthenticationStore();
const authorType = $computed(() => store.signUpAuthorType);

type RouteList = {
	title?: string;
	to: string;
	children?: RouteList[];
	key?: string;
	label?: string;
	params?: {
		category: string;
	};
};

let isAuthorUpdateOpen = $ref(false);

let isSignUpModalOpen = $computed({
	get: () => store.isSignUpModalOpen,
	set: (value: boolean) => (store.isSignUpModalOpen = value),
});

const user = $computed(() => authStore.user);
const options = $computed(() => [
	{
		label: `${user?.FirstName} ${user?.LastName}`,
		key: 'username',
		props: {
			class: 'pb-4',
		},
	},
	{
		type: 'divider',
		key: 'd1',
	},
	{
		label: 'Update Profile',
		key: 'profile',
		props: {
			onClick: () => {
				isAuthorUpdateOpen = true;
			},
		},
	},
	{
		label: 'Logout',
		key: 'logout',
		props: {
			onClick: authStore.logout,
		},
	},
]);
const categories: RouteList = {
	title: 'Forums',
	to: routeNames.forums,
	children: [
		{
			label: 'Clinical',
			key: 'Clinical',
			to: '/clinical',
			params: {
				category: 'clinical',
			},
		},
		{
			label: 'Imaging',
			key: 'Imaging',
			to: '/imaging',
			params: {
				category: 'imaging',
			},
		},
		{
			label: 'Management',
			key: 'Management',
			to: '/management',
			params: {
				category: 'management',
			},
		},
		{
			label: 'Insurance',
			key: 'Insurance',
			to: '/insurance',
			params: {
				category: 'insurance',
			},
		},
		{
			label: 'Collections',
			key: 'Collections',
			to: '/collections',
			params: {
				category: 'collections',
			},
		},
		{
			label: 'Transitions',
			key: 'Transitions',
			to: '/transitions',
			params: {
				category: 'transitions',
			},
		},
		{
			label: 'Staffing',
			key: 'Staffing',
			to: '/staffing',
			params: {
				category: 'staffing',
			},
		},
		{
			label: 'Compliance',
			key: 'Compliance',
			to: '/compliance',
			params: {
				category: 'compliance',
			},
		},
		{
			label: 'Marketing',
			key: 'Marketing',
			to: '/marketing',
			params: {
				category: 'marketing',
			},
		},
		{
			label: 'Finance',
			key: 'Finance',
			to: '/finance',
			params: {
				category: 'finance',
			},
		},
	],
};

const administration: RouteList = {
	title: 'Administration',
	to: '/admin',
	children: [
		{
			label: 'Users',
			key: 'Users',
			to: adminRouteNames.users,
		},
		{
			label: 'Submissions',
			key: 'Submissions',
			to: adminRouteNames.submissions,
		},
		{
			label: 'Polls',
			key: 'Polls',
			to: adminRouteNames.polls,
		},
		{
			label: 'Coupons',
			key: 'Coupons',
			to: adminRouteNames.coupons,
		},
		{
			label: 'Sponsorships',
			key: 'Sponsorships',
			to: adminRouteNames.sponsorships,
		},
		{
			label: 'Downloads',
			key: 'Downloads',
			to: adminRouteNames.downloads,
		},
	],
};
let navItems = $computed(() => {
	const items: RouteList[] = [
		{
			title: 'News',
			to: routeNames.home,
		},
		{
			title: 'Blogs',
			to: routeNames.blogs,
		},
		{ ...categories },
		{
			title: 'Classifieds',
			to: routeNames.classifieds,
		},
		{
			title: 'Sponsors',
			to: routeNames.sponsors,
		},
		{
			title: 'About',
			to: routeNames.about,
		},
	];
	if ((user?.Types ?? 1) >= AuthorTypes.Admins) {
		items.push(administration);
	}
	return items;
});

let categoryOpened: string[] = $ref([]);

const loginPageDisabled = ['login', 'adminIndex', 'forgot-password'];

const getCurrentRoute = $computed(() => router.currentRoute.value);
const routeMeta = $computed(() => getCurrentRoute.meta);

const isLoginPage = $computed(() => loginPageDisabled.includes(String(getCurrentRoute.name)));
const getAuthorType = $computed(() => routeMeta.authorType);

function findIndexOfCategory(category: string) {
	return categoryOpened.findIndex((item) => item === category);
}

function addToVisibleList(category: string) {
	const index = findIndexOfCategory(category);
	index !== -1 ? categoryOpened.splice(index, 1) : categoryOpened.push(category);
}

function handleSelect(category: string, item: string | number) {
	const selected = [...(administration.children ?? []), ...(categories.children ?? [])]?.find(
		(categ) => categ.key === item
	);
	if (!category) return;
	router.push({
		name: category === 'administration' ? selected?.to : category,
		...(selected?.params?.category && {
			params: {
				category: selected?.params?.category,
			},
		}),
	});
}

function isCategorySelected(routeName: string) {
	if (routeName !== 'forums') return false;
	const currentRoute = getCurrentRoute;
	const category = currentRoute.params?.category;
	const selected = [...(categories.children ?? [])]?.find((categ) => categ?.params?.category === category);
	return selected;
}

function redirectToLoginPage() {
	router.push({ name: 'login', params: { id: getAuthorType } });
}
</script>

<template>
	<Disclosure v-slot="{ open }" as="nav" class="bg-white shadow">
		<div :class="!isSmall ? 'container mx-auto items-end' : 'flex flex-row justify-between items-center'">
			<div class="lg:hidden">
				<RouterLink :key="String(isSmall)" to="/">
					<img
						v-if="isSmall"
						height="50"
						class="h-[50px]"
						src="@/assets/images/dpt-logo-vertical-blue.svg"
						alt="dental_practice_logo"
					/>
					<img
						v-else
						height="50"
						class="h-[50px]"
						src="@/assets/images/dpt-logo-horizontal-blue.svg"
						alt="dental_practice_logo"
					/>
				</RouterLink>
			</div>
			<div class="flex justify-between h-16">
				<div class="flex">
					<div class="hidden md:flex md:space-x-8">
						<!-- Current: "border-teal-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
						<template v-for="navitem in navItems" :key="navitem.title">
							<n-dropdown
								v-if="navitem?.children"
								placement="bottom-start"
								trigger="hover"
								size="medium"
								:options="navitem?.children"
								@select="handleSelect(navitem.title ? navitem.title.toLowerCase() : '', $event)"
							>
								<span
									class="inline-flex items-center px-1 pt-1 font-medium border-b-2 border-transparent text-md"
									:class="{
										'text-primary':
											router.currentRoute.value.path.includes(navitem.to) || isCategorySelected(navitem.to),
									}"
								>
									{{ navitem.title }}
								</span>
							</n-dropdown>
							<router-link
								v-else-if="navitem"
								:to="{ name: String(navitem.to) }"
								:class="{
									'border-teal-500 text-gray-900': router.currentRoute.value.name === navitem.to,
								}"
								class="inline-flex items-center px-1 pt-1 font-medium border-b-2 border-transparent text-md"
							>
								<span :class="{ 'text-primary': router.currentRoute.value.name === navitem.to }"
									>{{ navitem.title }}
								</span>
							</router-link>
						</template>
					</div>
				</div>
				<div class="hidden space-x-6 md:ml-6 md:flex md:items-center md:justify-center">
					<div>
						<DropdownWithFlag />
					</div>
					<div v-if="user">
						<p>Hello, {{ user.FirstName }}</p>
					</div>
					<div>
						<n-dropdown
							v-if="authStore.isLoggedIn"
							placement="bottom-start"
							trigger="click"
							size="medium"
							:options="options"
						>
							<div class="flex flex-col items-center justify-center ml-auto">
								<n-avatar v-if="user?.Picture" round class="w-10 h-10 rounded" :src="user?.Picture" />
								<UserCircleIcon v-else class="w-10 h-10 text-gray-500 rounded" />
							</div>
						</n-dropdown>
						<div
							v-else-if="!isLoginPage"
							class="flex flex-col items-center justify-center ml-auto"
							@click="redirectToLoginPage()"
						>
							<UserCircleIcon class="w-10 h-10 text-gray-500 rounded" />
						</div>
					</div>

					<UpdateAuthorModal
						v-if="isAuthorUpdateOpen && user"
						v-model="isAuthorUpdateOpen"
						:author="user"
						:edit-profile="true"
					/>
				</div>
				<div class="flex items-center -mr-2 space-x-2 md:hidden">
					<div v-if="!user">
						<DropdownWithFlag />
					</div>
					<UserCircleIcon v-if="!user" class="w-10 h-10 mr-3 text-gray-500 rounded" @click="redirectToLoginPage()" />
					<!-- Mobile menu button -->
					<DisclosureButton
						class="inline-flex items-center justify-center p-2 mr-4 text-gray-400 rounded-md md:mr-0 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
					>
						<span class="sr-only">Open main menu</span>
						<Bars3Icon v-if="!open" class="block w-6 h-6" aria-hidden="true" />
						<XMarkIcon v-else class="block w-6 h-6" aria-hidden="true" />
					</DisclosureButton>
				</div>
			</div>
		</div>

		<DisclosurePanel class="md:hidden">
			<div class="w-full pt-2 pb-3 space-y-1">
				<div v-for="navitem in navItems" :key="navitem.title">
					<div v-if="navitem?.children">
						<div
							class="flex justify-start text-base font-medium text-gray-500"
							@click="addToVisibleList(navitem.title ?? '')"
						>
							<span
								class="flex flex-row items-center w-full py-2 pl-3 pr-4 space-x-4 text-base font-medium text-gray-500 border-l-4 border-transparent cursor-pointer hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
							>
								<p>{{ navitem.title }}</p>
								<ChevronUpIcon v-if="findIndexOfCategory(navitem.title ?? '') !== -1" class="w-4 h-4" />
								<ChevronDownIcon v-else class="w-4 h-4" />
							</span>
						</div>
						<div v-if="findIndexOfCategory(navitem.title ?? '') !== -1">
							<RouterLink
								v-for="child in navitem.children"
								:key="child.key"
								v-slot="{ href }"
								:to="{
									...(child.params
										? { name: navitem.to, params: { category: child.params?.category } }
										: { name: child.to }),
								}"
							>
								<DisclosureButton as="router-link" :href="href">
									<span
										class="block py-2 pl-3 pr-4 ml-4 text-base font-medium text-gray-500 border-l-4 border-transparent hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
										:class="
											getCurrentRoute.name === child.to ||
											(getCurrentRoute.name === navitem.to &&
												getCurrentRoute.params.category === child.params?.category)
												? 'bg-gray-100 text-gray-900'
												: ''
										"
									>
										{{ child.label }}
									</span>
								</DisclosureButton>
							</RouterLink>
						</div>
					</div>
					<RouterLink v-else v-slot="{ href }" :to="{ name: navitem.to }">
						<DisclosureButton
							as="router-link"
							:href="href"
							class="block py-2 pl-3 pr-4 text-base font-medium text-gray-500 border-l-4 border-transparent hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
							:class="getCurrentRoute.name === navitem.to ? 'bg-gray-100 text-gray-900' : ''"
							>{{ navitem.title }}</DisclosureButton
						>
					</RouterLink>
				</div>
			</div>
			<div v-if="user" class="pt-4 pb-3 border-t border-gray-200">
				<div class="flex items-center px-4">
					<div class="flex-shrink-0">
						<img class="w-10 h-10 rounded-full" :src="user?.Picture" alt="Profile picture" />
					</div>
					<div class="ml-3">
						<div class="text-base font-medium text-gray-800">{{ user?.FirstName }} {{ user?.LastName }}</div>
						<div class="text-sm font-medium text-gray-500">{{ user?.Email }}</div>
					</div>
					<button
						type="button"
						class="flex-shrink-0 p-1 ml-auto text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
					>
						<span class="sr-only">Change country</span>
						<DropdownWithFlag />
					</button>
				</div>
				<div class="mt-3 space-y-1">
					<DisclosureButton
						as="router-link"
						class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
						@click="isAuthorUpdateOpen = true"
						>Update profile</DisclosureButton
					>
					<DisclosureButton
						as="router-link"
						class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
						@click="authStore.logout()"
						>Sign out</DisclosureButton
					>
				</div>
			</div>
		</DisclosurePanel>
	</Disclosure>
	<SignUpModal
			v-if="isSignUpModalOpen"
			v-model="isSignUpModalOpen"
			:author-type="authorType"
	/>	
</template>

<style lang="scss" scoped>
.full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}
</style>
