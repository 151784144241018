<template>
	<n-config-provider id="main" class="min-h-screen" :theme-overrides="themeOverrides">
		<n-message-provider>
			<n-notification-provider>
				<n-loading-bar-provider>
					<TheHeader />
					<TheNavbar />
					<div class="container flex flex-wrap mx-auto md:py-6">
						<RouterView></RouterView>
						<TheSidebar v-if="!isAdmin" />
					</div>
					<TheFooter />
				</n-loading-bar-provider>
			</n-notification-provider>
		</n-message-provider>
	</n-config-provider>
</template>

<script setup lang="ts">
import { NConfigProvider, GlobalThemeOverrides } from 'naive-ui';

const route = useRoute();
const router = useRouter();

const isAdmin = $computed(() => router.currentRoute.value.meta?.authorType === AuthorTypes.Admins);
const currentHead = reactive({
	title: computed(() => route?.meta?.title || 'DENTAL PRACTICE TODAY'),
	meta: [
		{
			name: 'description',
			content: "The dental industry's free information sharing platform",
		},
	],
});
useHead(currentHead);

const store = useStore();
store.initApp();

const themeOverrides: GlobalThemeOverrides = {
	common: {
		primaryColor: '#4169e1',
	},
	Button: {
		color: '#4169e1',
		colorPrimary: '#4169e1',
		colorPressedPrimary: '#0040FF',
		colorInfo: '#4169e1',
		colorHoverInfo: '#7996ed',
		colorFocusInfo: '#4169e1',
		colorHoverPrimary: '#7996ed',
		borderHover: '#7996ed',
		borderHoverPrimary: '#7996ed',
		borderFocusPrimary: '#4169e1',
		colorFocusPrimary: '#4169e1',
	},
	Input: {
		borderHover: '#7996ed',
		borderFocus: '#4169e1',
	},
	Tag: {
		colorHoverCheckable: '#FFFFFF',
		colorCheckedHover: '#4169e1',
		colorBordered: '#4169e1',
	},
	InternalSelection: {
		borderActive: '#4169e1',
		borderFocus: '#4169e1',
		borderHover: '#7996ed',
	},
};
</script>
