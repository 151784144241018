import { IAuthorInfo, IAuthorItemRequest } from './../types/Author';
import { IAuthorSignUp } from '@/types/Author';
import { MD5 } from 'crypto-js';
import { ItemID } from '@/types';

export function getAuthors(pagination: IAuthorItemRequest) {
	return baseService.post<[IAuthorInfo[], number]>(SecureEndpoints.GetAuthors, pagination);
}

export function getAuthor(authorID: ItemID) {
	return baseService.post<[IAuthorInfo]>(SecureEndpoints.GetAuthor, { ID: authorID });
}

export function getAuthorInfo(authorID: ItemID) {
	return baseService.post<[IAuthorInfo, number]>(BaseEndpoints.GetAuthorInfo, {
		ID: authorID,
	});
}

export async function saveAuthor(payload: IAuthorInfo) {
	const res = await baseService.post(SecureEndpoints.SaveAuthor, { Payload: payload });
	return res;
}

export async function createNewAuthorRequest(payload: IAuthorSignUp): Promise<[IAuthorSignUp, number]> {
	try {
		const requestData = { ...payload };
		if (payload.PwdHash !== payload.ConfirmPassword) {
			throw new Error('Password and confirm password does not match');
		}
		if (payload.PwdHash) {
			requestData.PwdHash = MD5(requestData.PwdHash ?? '').toString();
			delete requestData.ConfirmPassword;
		}
		if (payload.SecurityAnswerHash) {
			requestData.SecurityAnswerHash = MD5(requestData.SecurityAnswerHash ?? '').toString();
		}
		return baseService.post<[IAuthorSignUp, number]>(BaseEndpoints.NewAuthorRequest, {
			Payload: requestData,
		});
	} catch (error) {
		throw handleError(error);
	}
}
