<template>
	<n-select
		:options="transformedOrganizations"
		:value="defaultOrganizationId"
		@update:value="handleOrganizationSelect"
	/>
</template>
<script lang="ts" setup>
import { useOrganizations } from '@/store/organizations';
import { IOrganization } from '@/types/Organizations';
import { DropdownOption } from 'naive-ui';

const { defaultOrganizationId } = defineProps<{
	defaultOrganizationId?: number;
}>();

const emit = defineEmits<{
	(event: 'onSelect', value: DropdownOption): void;
}>();

const organizationStore = useOrganizations();

let isLoading = $ref(false);

const organizations = $computed(() => organizationStore.organizations);

const transformedOrganizations = $computed(() => {
	return organizations
		.filter((organization: IOrganization) => Number(organization.Types) !== AllAuthorType)
		.map((organization: IOrganization) => {
			return {
				label: organization.Name,
				value: organization.ID,
			};
		});
});

function handleOrganizationSelect(_: number, option: DropdownOption | null) {
	emit('onSelect', {
		label: option?.label,
		key: option?.value as string,
	});
}

async function loadOrganizations() {
	isLoading = true;
	try {
		await organizationStore.getOrganizations(pagination);
	} catch (err) {
		console.error(err);
	} finally {
		isLoading = false;
	}
}

onBeforeMount(async () => {
	await loadOrganizations();
});

const pagination = {
	Types: AllAuthorType,
	Skip: 0,
	Take: 10000,
	IsEnabled: null,
};
</script>
