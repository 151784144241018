import { AuthorTypes } from './types/constants';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import IndexPage from '@/pages/IndexPage.vue';
import { useAuthenticationStore } from './store/auth';
import LoginPage from './pages/LoginPage.vue';

export enum routeNames {
	home = 'home',
	login = 'login',
	'forgot-password' = 'forgot-password',
	about = 'about',
	post = 'post',
	topic = 'topic',
	contact = 'contact',

	authors = 'authors',
	blogs = 'blogs',
	forums = 'forums',
	adinfo = 'adinfo',
	sponsors = 'sponsors',
	sponsorads = 'sponsorads',
	classifieds = 'classifieds',
	'not-found' = 'not-found',
}
export enum adminRouteNames {
	admin = 'admin',
	adminIndex = 'adminIndex',
	users = 'users',
	submissions = 'submissions',
	polls = 'polls',
	coupons = 'coupons',
	sponsorships = 'sponsorships',
	downloads = 'downloads',
}

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		name: routeNames.home,
		component: IndexPage,
		meta: {
			title: 'News - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Bloggers,
			category: Category.News,
		},
	},
	{
		path: '/login/:id',
		name: routeNames.login,
		component: LoginPage,
		meta: {
			title: 'Login - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Admins,
		},
	},
	{
		path: '/forgot-password',
		name: routeNames['forgot-password'],
		component: () => import('@/pages/ForgotPassword.vue'),
		meta: {
			title: 'Forgot Password - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Admins,
		},
	},
	{
		path: '/posts/:id',
		name: routeNames.post,
		component: () => import('@/pages/SinglePost.vue'),
		meta: {
			title: 'Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Bloggers,
		},
	},
	{
		path: '/blogs',
		name: routeNames.blogs,
		component: () => import('@/pages/BlogPage.vue'),
		meta: {
			title: 'Blog - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Bloggers,
			category: Category.Blogs,
		},
	},
	{
		path: '/sponsors',
		name: routeNames.sponsors,
		component: () => import('@/pages/SponsorPageProxy.vue'),
		meta: {
			title: 'Sponsors - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Sponsors,
		},
	},
	{
		path: '/classifieds',
		name: routeNames.classifieds,
		component: () => import('@/pages/ClassifiedsPage.vue'),
		meta: {
			title: 'Classifieds - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Advertisers,
		},
	},
	{
		path: '/forum/:category',
		name: routeNames.forums,
		component: () => import('@/pages/ForumPage.vue'),
		meta: {
			title: 'Forum - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Bloggers,
		},
	},
	{
		path: '/forum/:category/:id',
		name: routeNames.topic,
		component: () => import('@/pages/TopicPage.vue'),
		meta: {
			title: 'Topic - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Bloggers,	
		},
	},
	{
		path: '/about',
		name: routeNames.about,
		component: () => import('@/pages/AboutPage.vue'),
		meta: {
			title: 'About - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Bloggers,
		},
	},
	{
		path: '/admin',
		name: adminRouteNames.admin,
		redirect: { name: adminRouteNames.adminIndex },
		meta: {
			title: 'Admin - Dental Practice Today',
			requiresAuth: true,
			authorType: AuthorTypes.Admins,
		},
		children: [
			{
				path: '',
				name: adminRouteNames.adminIndex,
				component: () => import('@/pages/admin/IndexPage.vue'),
				meta: {
					title: 'Admin Access - Dental Practice Today',
					requiresAuth: true,
					authorType: AuthorTypes.Admins,
				},
			},
			{
				path: 'users',
				name: adminRouteNames.users,
				component: () => import('@/pages/admin/UsersPage.vue'),
				meta: {
					title: 'User Administration - Dental Practice Today',
					requiresAuth: true,
					authorType: AuthorTypes.Admins,
				},
			},
			{
				path: 'submissions',
				name: adminRouteNames.submissions,
				component: () => import('@/pages/admin/SubmissionsPage.vue'),
				meta: {
					title: 'Submission Administration - Dental Practice Today',
					requiresAuth: true,
					authorType: AuthorTypes.Admins,
				},
			},
			{
				path: 'polls',
				name: adminRouteNames.polls,
				component: () => import('@/pages/admin/PollsPage.vue'),
				meta: {
					title: 'Poll Administration - Dental Practice Today',
					requiresAuth: true,
					authorType: AuthorTypes.Admins,
				},
			},
			{
				path: 'coupons',
				name: adminRouteNames.coupons,
				component: () => import('@/pages/admin/CouponsPage.vue'),
				meta: {
					title: 'Coupon Administration - Dental Practice Today',
					requiresAuth: true,
					authorType: AuthorTypes.Admins,
				},
			},
			{
				path: 'sponsorships',
				name: adminRouteNames.sponsorships,
				component: () => import('@/pages/admin/SponsorshipPage.vue'),
				meta: {
					title: 'Sponsorships Administration - Dental Practice Today',
					requiresAuth: true,
					authorType: AuthorTypes.Admins,
				},
			},
			{
				path: 'downloads',
				name: adminRouteNames.downloads,
				component: () => import('@/pages/admin/DownloadsPage.vue'),
				meta: {
					title: 'Downloads Administration - Dental Practice Today',
					requiresAuth: true,
					authorType: AuthorTypes.Admins,
				},	
			},
		],
	},
	{
		path: '/:pathMatch(.*)*',
		name: routeNames['not-found'],
		component: () => import('@/pages/NotFound.vue'),
		meta: {
			title: 'Page Not Found - Dental Practice Today',
			requiresAuth: false,
			authorType: AuthorTypes.Bloggers,
		},
	},
];
const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}
		return { top: 0, behavior: 'smooth' };
	},
});

router.beforeEach((to) => {
	const store = useAuthenticationStore();
	if (store.isLoggedIn && store.redirectAfterLogin) {
		router.push(store.redirectAfterLogin);
		store.redirectAfterLogin = '';
	}
	const userType = store.user?.Types ?? { Types: 1 };
	const requiredAuthorType = to.meta.authorType ?? 1;
	const adminRoutes = Object.values(adminRouteNames).filter(
		(route) => route !== 'admin' && route !== 'adminIndex'
	) as string[];
	if (userType < requiredAuthorType && adminRoutes.includes(String(to.name))) {
		store.redirectAfterLogin = to.fullPath;
		return { name: adminRouteNames.admin };
	}
	if (
		![adminRouteNames.adminIndex, routeNames.home].includes(String(to.name) as adminRouteNames | routeNames) &&
		to.meta.requiresAuth &&
		!store.isLoggedIn
	) {
		return { name: to.meta.authorType === AuthorTypes.Admins ? adminRouteNames.adminIndex : routeNames.home };
	}
	if (store.isLoggedIn && to.name === adminRouteNames.adminIndex) {
		return { name: adminRouteNames.users };
	}
	if (store.isLoggedIn && to.name == routeNames['forgot-password']) {
		return { name: routeNames.home };
	}
});

export default router;
