<template>
	<div v-if="isAchInfoVerified">
		<label for="country">Bill To:</label>
		<n-form-item class="col-span-3 w-full" path="CountryID">
			<n-select
				v-model:value="achInstrument.CountryID"
				:options="transformedCountries"
				placeholder="Please select your country"
				:on-update:value="selectCountry"
			/>
		</n-form-item>
		<n-form-item
			class="col-span-3"
			:label="achInstrument.CountryID == 1 ? 'City, ST, ZIP: ' : 'City, ST, Postal Code:'"
			path="City"
		>
			<div class="flex flex-col space-y-4 w-full">
				<n-input v-model:value="achInstrument.City" />
				<n-select
					v-model:value="achInstrument.StateID"
					:options="transformedStates"
					placeholder="Please select your state"
					:on-update:value="selectState"
				/>
				<n-input
					v-model:value="achInstrument.Zip"
					v-maska="{
						mask: getZipPattern,
						tokens: {
							Z: {
								pattern: /[0-9|A-Za-z|' ']/,
							},
						},
					}"
					:placeholder="zipFormat(selectedCulture)"
				/>
			</div>
		</n-form-item>
		<n-form-item class="col-span-3" label="Unit or Suite:" path="Address2">
			<n-input v-model:value="achInstrument.Address2" />
		</n-form-item>
		<n-form-item class="col-span-3" label="Bank Name:" path="BankName">
			<n-input v-model:value="achInstrument.BankName" />
		</n-form-item>
		<n-form-item class="col-span-3" label="Bank Phone:" path="BankPhone">
			<n-input v-model:value="achInstrument.BankPhone" />
		</n-form-item>
		<n-form-item class="col-span-3" label="Receipt Email:" path="ReceiptEmail">
			<n-input v-model:value="achInstrument.ReceiptEmail" />
		</n-form-item>
		<n-button type="primary" class="w-full" @click="showPreviousStep">Back</n-button>
	</div>
	<div v-else>
		<n-form ref="formRef" :rules="rules" :model="achInstrument">
			<n-form-item class="col-span-3" label="Account Type:" path="PaymentType">
				<n-select
					v-model:value="achInstrument.PaymentType"
					:options="transformedAchTypes"
					placeholder="Please select your Account Type"
				/>
			</n-form-item>
			<n-form-item label="Routing / ABA:" path="RoutingAba">
				<n-input v-model:value="achInstrument.RoutingAba" :maxlength="9" />
			</n-form-item>
			<n-form-item label="Account Number:" path="AccountNumber">
				<n-input v-model:value="achInstrument.AccountNumber" :maxlength="17" />
			</n-form-item>
			<n-form-item label="Account Owner:" path="BillingName">
				<n-input v-model:value="achInstrument.BillingName" />
			</n-form-item>
			<n-form-item label="Billing Address:" path="Address">
				<n-input v-model:value="achInstrument.Address" />
			</n-form-item>
			<div>
				<span v-if="errorMessage" class="text-red-500">{{ errorMessage }}</span>
			</div>
			<div class="w-full text-center">
				<n-button type="primary" class="uppercase" :loading="isLoading" @click.prevent="verifyAch()">Verify</n-button>
			</div>
		</n-form>
	</div>
</template>
<script lang="ts" setup>
import { usePaymentsStore } from '@/store/payments';
import { IPaymentInstrument } from '@/types/Payments';
import { AchTypes } from '@/types/constants';
import { FormInst, FormItemRule, NButton, NForm, NFormItem, NInput, NSelect } from 'naive-ui';
import { Cultures, zipFormat } from '@/modules/validation';
import { transformDataToLabelAndValue } from '@/plugins/helpers';

const { modelValue, isAchInfoVerified } = defineProps<{ modelValue: IPaymentInstrument; isAchInfoVerified: boolean }>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: IPaymentInstrument): void;
	(event: 'update:culture', value: string): void;
	(event: 'verify', value: boolean): void;
}>();

const formRef = $ref<FormInst | null>(null);
const paymentStore = usePaymentsStore();
const store = useStore();
const achInstrument: IPaymentInstrument = $ref({ ...modelValue } as IPaymentInstrument);
let selectedCulture: Cultures = 'en-us';
let isLoading = $ref(false);
let errorMessage = $ref('');

const getZipPattern = $computed(() =>
	selectedCountryName === 'United Kingdom'
		? 'SS#Z #SS'
		: zipFormat(selectedCulture).replace(/9/g, '#').replace(/A/g, 'S')
);

const transformedAchTypes = $computed(() => transformDataToLabelAndValue(paymentStore.paymentInfo.AchTypes, 'Prompt'));

const transformedCountries = $computed(() => transformDataToLabelAndValue(store.countries));

const transformedStates = $computed(() => transformDataToLabelAndValue(store.states));

const selectedCountryName = $computed(
	() => store.countries.find((country) => country.ID === achInstrument.CountryID)?.Name ?? ''
);

async function loadStatesByCountryID(selectedID: number) {
	try {
		await store.getStatesByCountryID(selectedID);
	} catch (error) {
		console.log(error);
	}
}

function findCultureByCountryID(selectedID: number) {
	return (store.countries.find((country) => country.ID === selectedID)?.Culture ?? 'en-us') as Cultures;
}

async function selectCountry(selectedID: number) {
	achInstrument.CountryID = selectedID;
	selectedCulture = findCultureByCountryID(selectedID);
	emit('update:culture', selectedCulture);
	await loadStatesByCountryID(selectedID);
	achInstrument.StateID = store.states.find((state) => state.IsDefault)?.ID ?? store.states[0].ID;
}

function selectState(stateID: number) {
	achInstrument.StateID = stateID;
}

async function validatePaymentInstrument() {
	isLoading = true;
	try {
		await paymentStore.validatePaymentInstrument(achInstrument);
		emit('update:modelValue', achInstrument);
		emit('verify', true);
	} catch (err) {
		const error = err as Error;
		errorMessage = error.message;
	} finally {
		isLoading = false;
	}
}

function verifyAch() {
	formRef?.validate((errors) => {
		if (errors?.length) {
			console.error(errors);
			return;
		}
		validatePaymentInstrument();
	});
}

function showPreviousStep() {
	emit('verify', false);
}

watchEffect(() => {
	emit('update:modelValue', achInstrument);
});

onMounted(async () => {
	paymentStore.isPayloadCompressed = true;
	achInstrument.PaymentType = AchTypes.Checking;
	achInstrument.IsEnabled = true;
	achInstrument.ExpirationMonth = 0;
	achInstrument.ExpirationYear = 0;
	selectState(paymentStore.paymentInfo.DefaultStateID);
});

const rules = {
	RoutingAba: {
		required: true,
		trigger: 'submit',
		message: 'Please enter a valid routing number.',
		validator: (_: FormItemRule, value: string) => {
			return isRoutingAba(value);
		},
	},
	AccountNumber: {
		required: true,
		trigger: 'submit',
		message: 'Please enter a valid account number.',
		validator: (_: FormItemRule, value: string) => {
			return isAchAccount(value);
		},
	},
};
</script>
<style scoped>
:deep(.n-form-item-label),
:deep(.n-form-item-label--right-mark) {
	width: 100% !important;
}
:deep(.n-button) {
	width: 100%;
}
</style>
