<template>
	<FormOverlay
		v-model="isOpenModal"
		:title="`Update user information for ${author.FirstName} ${author.LastName}`"
		:loading="isLoading"
		:disabled="isLoading"
		:rules="rules"
		:model="updatedAuthor"
		submit-button-text="Save"
		@submit="submitForm"
		@close="closeModal"
	>
		<n-form-item class="row-span-3 mx-auto">
			<UploadImage v-model="updatedAuthor.Picture" is-author-image />
		</n-form-item>
		<n-form-item label="Salutation" path="Salutation">
			<n-input v-model:value="updatedAuthor.Salutation" placeholder="Salutation" />
		</n-form-item>
		<n-form-item label="Organization" path="OrganizationID">
			<OrganizationDropdown
				:default-organization-id="updatedAuthor.OrganizationID"
				@on-select="handleOrganizationSelect"
			/>
		</n-form-item>
		<n-form-item label="First Name" path="FirstName">
			<n-input v-model:value="updatedAuthor.FirstName" placeholder="FirstName" />
		</n-form-item>
		<n-form-item label="Middle Name" path="MiddleName">
			<n-input v-model:value="updatedAuthor.MiddleName" placeholder="MiddleName" />
		</n-form-item>
		<n-form-item label="Last Name" path="LastName">
			<n-input v-model:value="updatedAuthor.LastName" placeholder="LastName" />
		</n-form-item>
		<n-form-item label="Suffix" path="Suffix">
			<n-input v-model:value="updatedAuthor.Suffix" placeholder="Suffix" />
		</n-form-item>
		<n-form-item label="Phone" path="Phone">
			<n-input
				v-model:value="updatedAuthor.Phone"
				v-maska="{
					mask: getMobilePattern,
					tokens: {
						Z: {
							pattern: '4|5',
						},
					},
				}"
				:placeholder="mobileFormat(selectedCulture)"
			/>
		</n-form-item>
		<n-form-item label="Website" path="Website">
			<n-input v-model:value="updatedAuthor.Website" placeholder="Website" />
		</n-form-item>
		<n-form-item class="col-span-1" label="Email" path="Email">
			<n-input v-model:value="updatedAuthor.Email" placeholder="Email" />
		</n-form-item>
		<n-form-item label="LoginName" path="LoginName">
			<n-input v-model:value="updatedAuthor.LoginName" placeholder="LoginName" />
		</n-form-item>
		<n-form-item v-if="editProfile" label="Username" path="UserName">
			<n-input v-model:value="updatedAuthor.UserName" placeholder="Username" />
		</n-form-item>
		<n-form-item v-if="editProfile" label="Password" path="PwdHash">
			<n-input
				v-model:value="updatedAuthor.PwdHash"
				placeholder="Password"
				type="password"
				show-password-on="mousedown"
			/>
		</n-form-item>
		<n-form-item v-if="editProfile" label="Confirm Password" path="ConfirmPassword">
			<n-input
				v-model:value="updatedAuthor.ConfirmPassword"
				placeholder="Confirm Password"
				type="password"
				show-password-on="mousedown"
			/>
		</n-form-item>
		<n-form-item v-if="editProfile" label="Security Question" path="SecurityQuestion">
			<n-input v-model:value="updatedAuthor.SecurityQuestion" placeholder="Security Question" type="text" />
		</n-form-item>
		<n-form-item v-if="editProfile" class="col-span-1" label="Security Answer" path="SecurityAnswerHash">
			<n-input v-model:value="updatedAuthor.SecurityAnswerHash" placeholder="Security Answer" type="text" />
		</n-form-item>
		<n-form-item class="col-span-3" label="Summary" path="Summary">
			<div class="flex flex-col col-span-1 max-h-[50%] w-full">
				<QuillEditor v-model:content="updatedAuthor.Summary" content-type="html" toolbar="full" />
			</div>
		</n-form-item>
		<n-form-item v-if="!editProfile">
			<p>Status: {{ updatedAuthor.IsEnabled ? 'Active' : 'Pending' }}</p>
		</n-form-item>
		<template #footer>
			<div v-if="!editProfile" class="flex justify-start">
				<n-button type="primary" @click="handleUserActivation">
					{{ updatedAuthor.IsEnabled ? 'Deactivate' : 'Activate' }}
				</n-button>
			</div>
		</template>
	</FormOverlay>
</template>

<script lang="ts" setup>
import { useAuthors } from '@/store/authors';
import { IAuthorInfo } from '@/types/Author';
import { Cultures, mobileFormat } from '@/modules/validation';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { DropdownOption, FormItemRule } from 'naive-ui';
import { useAuthenticationStore } from '@/store/auth';

const {
	modelValue = false,
	author,
	editProfile = false,
} = defineProps<{
	modelValue: boolean;
	author: IAuthorInfo;
	editProfile?: boolean;
}>();
const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
}>();

const authorStore = useAuthors();
const authStore = useAuthenticationStore();

let isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => emit('update:modelValue', value),
});
const getMobilePattern = $computed(() =>
	selectedCulture === 'en-au'
		? '0Z ##### ###'
		: mobileFormat(selectedCulture).replace(/9/g, '#').replace(/\[/g, '').replace(/\]/g, '')
);

let selectedCulture: Cultures = $ref(authStore.culture);

let currentPostAuthor = $computed(() => authorStore.updateAuthor);

let updatedAuthor: IAuthorInfo = $ref(editProfile ? { ...author } : { ...currentPostAuthor });

let isLoading = $ref(false);

function closeModal() {
	isOpenModal = false;
}

async function submitForm() {
	sendRequest(updatedAuthor, true);
}

async function handleUserActivation() {
	updatedAuthor.IsEnabled = !updatedAuthor.IsEnabled;
	sendRequest(
		{
			...author,
			IsEnabled: updatedAuthor.IsEnabled,
			Status: updatedAuthor.IsEnabled ? 'Active' : 'Pending',
		},
		false
	);
}

function handleOrganizationSelect(option: DropdownOption) {
	updatedAuthor.OrganizationID = Number(option.key);
	updatedAuthor.Organization = String(option.label);
}

async function sendRequest(user: IAuthorInfo, hideModal = false) {
	isLoading = true;
	try {
		await authorStore.saveAuthor(user);
		if (hideModal) {
			closeModal();
		}
	} catch (error) {
		console.error(error);
	} finally {
		isLoading = false;
	}
}

let rules = {
	UserName: {
		required: true,
		message: 'Please input your username',
		trigger: 'submit',
	},
	LoginName: {
		required: true,
		message: 'Please input your login name',
		trigger: 'submit',
	},
	PwdHash: {
		message: 'Please input your password',
		trigger: 'submit',
	},
	SecurityQuestion: {
		required: true,
		message: 'Please input your security question',
		trigger: 'submit',
	},
	SecurityAnswerHash: {
		message: 'Please input your security answer',
		trigger: 'submit',
	},
	FirstName: {
		required: true,
		message: 'Please input your first name',
		trigger: 'submit',
	},
	LastName: {
		required: true,
		message: 'Please input your last name',
		trigger: 'submit',
	},
	Organization: {
		required: true,
		message: 'Please input your organization',
		trigger: 'submit',
	},
	Email: {
		required: true,
		message: 'Please input your email',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			return isEmail(value);
		},
	},
	Phone: {
		required: true,
		message: 'Please input your phone',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			return isMobile(value, selectedCulture);
		},
	},
	Summary: {
		required: true,
		message: 'Please input your summary',
		trigger: 'submit',
	},
	ConfirmPassword: {
		message: 'Please input your confirm password',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			if (!updatedAuthor.PwdHash) return true;
			return value === updatedAuthor.PwdHash;
		},
	},
	Website: {
		message: 'Please input a valid website',
		trigger: 'submit',
		validator: (_: FormItemRule, value: string) => {
			return value === '' || isURL(value);
		},
	},
};
</script>
