<template>
	<BaseOverlay
		v-model="isOpenModal"
		modal-class="relative lg:max-w-[70%] lg:w-1/2 space-y-3 bg-white md:rounded-lg p-6 text-left overflow-hidden shadow-xl transform transition-all xs:my-8"
	>
		<template #title>
			<div class="flex flex-row justify-between w-full">
				<div class="mb-4 flex space-x-4 items-center">
					<ExclamationTriangleIcon v-if="modalType === 'warning'" class="mx-auto w-8 h-8 text-[#f2c97d]" />
					<ExclamationCircleIcon v-else-if="modalType === 'error'" class="mx-auto w-8 h-8 text-[#e88080]" />
					<CheckCircleIcon v-else-if="modalType === 'success'" class="w-8 h-8 mx-auto text-[#63e2b7]" />
					<InformationCircleIcon v-else-if="modalType === 'info'" class="w-8 h-8 mx-auto text-primary" />
					<h1
						:class="
							isCloseIconVisible
								? 'text-2xl font-extrabold text-center tracking-tight text-gray-700 capitalize'
								: 'text-lg font-medium text-gray-900'
						"
					>
						{{ title }}
					</h1>
				</div>
				<div v-if="isCloseIconVisible">
					<XMarkIcon class="w-6 h-6 text-gray-400 hover:text-gray-500 cursor-pointer" @click="isOpenModal = false" />
				</div>
			</div>
		</template>
		<template #body>
			<div class="flex flex-col text-base">
				<slot name="body"></slot>
			</div>
		</template>
		<template #footer>
			<slot name="footer">
				<div class="flex justify-end w-full gap-x-4 mt-4">
					<n-button :type="modalType" @click="isOpenModal = false">OK</n-button>
				</div>
			</slot>
		</template>
	</BaseOverlay>
</template>
<script lang="ts" setup>
import { NButton } from 'naive-ui';
import { Type } from 'naive-ui/es/button/src/interface';
import {
	CheckCircleIcon,
	ExclamationCircleIcon,
	ExclamationTriangleIcon,
	InformationCircleIcon,
	XMarkIcon,
} from '@heroicons/vue/24/solid';
const {
	modelValue = true,
	title = '',
	modalType,
	isCloseIconVisible = false,
} = defineProps<{
	modelValue: boolean;
	title: string;
	modalType: Type | undefined;
	isCloseIconVisible?: boolean;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: boolean): void;
}>();

let isOpenModal = $computed({
	get: () => modelValue,
	set: (value) => emit('update:modelValue', value),
});
</script>
