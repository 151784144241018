<template>
	<div
		v-if="!isLoading && isLoaded"
		class="flex flex-col w-full p-6 my-4 transition-shadow bg-white shadow-md shadow-stone-300 hover:shadow-lg"
	>
		<p class="pb-5 text-xl font-semibold">Sponsored Ads</p>
		<a :href="currentAd.LinkUrl" target="_blank">
			<div class="w-full h-full">
				<img
					class="w-full h-full transition-opacity hover:opacity-75"
					:alt="currentAd.Headline"
					:src="!currentAd.Picture || currentAd.Picture === '' ? placeholder : currentAd.Picture"
					:title="currentAd.Headline"
				/>
			</div>
		</a>
	</div>
	<SponsorAdSkeleton v-else-if="isLoading" />
</template>
<script lang="ts" setup>
import { useAdStore } from '@/store/sponsored-ads';
import placeholder from '@/assets/images/placeholder.jpeg';

const sponsoredAdStore = useAdStore();
const currentAd = $computed(() => sponsoredAdStore.currentAd);

let isLoading = $ref(false);
let isLoaded = $ref(false);

onMounted(async () => {
	isLoading = true;
	try {
		await sponsoredAdStore.getSponsoredAd();
		isLoaded = sponsoredAdStore.currentAd?.ID > 0;
	} catch (err) {
		console.error(err);
	} finally {
		isLoading = false;
	}
});
</script>
