<template>
	<div>
		<n-form ref="formRef" :model="formValue" :rules="rules">
			<n-form-item label="Username" path="username">
				<n-input v-model:value="formValue.username" :disabled="isLoading" placeholder="Your username" />
			</n-form-item>
			<n-form-item label="Password" path="password">
				<n-input
					v-model:value="formValue.password"
					type="password"
					:disabled="isLoading"
					show-password-on="mousedown"
					autocomplete="current-password"
					placeholder="Your password"
				/>
			</n-form-item>

			<div v-if="!isAdmin" class="flex items-center justify-between">
				<div class="flex flex-row justify-between w-full text-sm">
					<p
						class="underline cursor-pointer color-primary hover:text-primary-hover text-primary"
						@click="openRegisterModal"
					>
						{{ getSignUpMessage }}
					</p>
					<p
						class="underline cursor-pointer color-primary hover:text-primary-hover text-primary"
						@click="openForgotPasswordModal"
					>
						Forgot Password
					</p>
				</div>
			</div>

			<div class="flex flex-row justify-end w-full">
				<n-form-item
					:show-feedback="false"
					:class="{
						'-mt-5': isAdmin,
					}"
				>
					<n-button :loading="isLoading" type="primary" @click.prevent="login">
						<span class="w-full font-bold text-center uppercase"> Sign In </span>
					</n-button>
				</n-form-item>
			</div>

			<span v-if="errorMessage" class="text-red-500">{{ errorMessage }}</span>
		</n-form>
	</div>
</template>

<script setup lang="ts">
import { adminRouteNames } from '@/router';
import { useAuthenticationStore } from '@/store/auth';
import { AuthorTypes } from '@/types/constants';
import { FormInst } from 'naive-ui';

const { authorType } = defineProps<{
	authorType: number;
}>();

const emit = defineEmits<{
	(event: 'openRegisterModal'): void;
	(event: 'openForgotModal'): void;
}>();

const authStore = useAuthenticationStore();
const router = useRouter();

const isAdmin = $computed(() => authorType === AuthorTypes.Admins);
const getAuthorType = $computed(() => authorType);

const getSignUpMessage = $computed(() => {
	if (getAuthorType === AuthorTypes.Sponsors) return 'Click here to sign up as a Sponsor';
	if (getAuthorType === AuthorTypes.Admins) return 'Click here to sign up as an Admin';
	if (getAuthorType === AuthorTypes.Advertisers) return 'Click here to sign up as an Advertiser';
	return 'Click here to sign up as an Author';
});

let formValue = $ref({
	username: '',
	password: '',
});
let isLoading = $ref(false);
let errorMessage = $ref('');
let formRef = $ref<FormInst | null>(null);
async function login() {
	errorMessage = '';
	formRef?.validate(async (errors) => {
		if (errors?.length) {
			console.error(errors);
			return;
		}
		try {
			isLoading = true;
			await authStore.login(formValue, getAuthorType ?? 1);
			if (isAdmin) {
				router.push({ name: adminRouteNames.users });
				return;
			}
			router.push({ name: routeNames.home });
		} catch (error) {
			if (error) {
				console.error(error);
			}
			errorMessage = 'Invalid username or password, please try again!';
		} finally {
			isLoading = false;
		}
	});
}

let rules = {
	username: {
		required: true,
		message: 'Please input your name',
		trigger: 'submit',
	},
	password: {
		required: true,
		message: 'Please input your password',
		trigger: 'submit',
	},
};

function openRegisterModal() {
	emit('openRegisterModal');
}

function openForgotPasswordModal() {
	emit('openForgotModal');
}
</script>
<style scoped>
:deep(
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active
	) {
	-webkit-box-shadow: 0 0 0 30px white inset;
}
</style>
