<template>{{ formatDate }}</template>
<script lang="ts" setup>
import { useAuthenticationStore } from '@/store/auth';

const { timeToFormat } = defineProps<{
	timeToFormat: string;
}>();
const authStore = useAuthenticationStore();
const selectedCulture = $computed(() => authStore.culture);
const formatDate = $computed(() =>
	timeToFormat ? new Date(timeToFormat).toLocaleDateString(selectedCulture) : 'Unknown'
);
</script>
