import { ISponsorAdInfo } from '@/types/Sponsorship';

export async function getSponsoredAd(): Promise<ISponsorAdInfo> {
	try {
		const ad = getStorageItem<ISponsorAdInfo>(storageVariables.lastAd);
		const [adInfo] = await baseService.post<[ISponsorAdInfo, number]>(BaseEndpoints.GetSponsorAdInfo, {
			ID: Number(ad.ID) || 0,
		});
		if (adInfo?.ID > 0) {
			setStorageItem(storageVariables.lastAd, adInfo);
		}
		return adInfo;
	} catch (error) {
		throw handleError(error);
	}
}
