<template>
	<div class="flex flex-row justify-between w-full my-4" v-if="isAllowedToCreatePost">
		<n-skeleton size="medium" width="30%"></n-skeleton>
		<div class="w-full justify-end flex flex-row space-x-4">
			<n-skeleton width="5%" size="medium"></n-skeleton>
			<div class="w-[30%] md:w-[10%] flex flex-row">
				<n-skeleton size="medium" :repeat="isSmall"></n-skeleton>
			</div>
		</div>
	</div>
	<div v-else class="w-full flex justify-end">
		<n-skeleton width="10%" size="medium"></n-skeleton>
	</div>
	<article
		class="flex flex-col justify-start items-center md:flex-row rounded-md my-4 transition-shadow shadow-md shadow-stone-300 hover:shadow-xl w-full p-4 md:p-0"
		v-for="_ in 5"
	>
		<div class="w-[41%]">
			<n-skeleton width="90%" size="large" :repeat="isSmall"></n-skeleton>
		</div>
		<div class="w-full">
			<div class="flex flex-row justify-between">
				<div class="space-x-6">
					<n-skeleton text />
				</div>
			</div>
			<div class="bg-white flex flex-col justify-start w-full mr-6">
				<div class="flex flex-col space-y-4 mt-2">
					<div class="flex flex-row justify-between">
						<n-skeleton width="30%" size="small"></n-skeleton>
						<n-skeleton width="10%" size="medium" v-if="isLikeVisible"></n-skeleton>
					</div>
					<n-skeleton text size="large"></n-skeleton>
				</div>
			</div>
		</div>
	</article>
</template>
<script lang="ts" setup>
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { useAuthenticationStore } from '@/store/auth';

const { isLikeVisible, isAllowedToCreatePost = false } = defineProps<{
	isLikeVisible: boolean;
	isAllowedToCreatePost?: boolean;
}>();

const { isSmaller } = useBreakpoints(breakpointsTailwind);
const isSmall = $computed(() => (isSmaller('sm') ? 3 : 7));
</script>
