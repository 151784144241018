import { getPollResults, getPolls, savePoll, submitPollEntry } from '@/services/poll-service';
import { IPollInfo, IPollItemRequest, IPollEntry, IPollResults, IPollSave } from './../types/Polls';

export const usePollStore = defineStore('polls', {
	state: () => ({
		polls: [] as IPollInfo[],
		currentPoll: {} as IPollInfo,
		pagination: {} as IPollItemRequest,
		count: 0,
	}),
	actions: {
		async getPollInfo(): Promise<void> {
			try {
				const res = await getPollInfo();
				if (res) {
					this.count++;
					this.polls.push(res);
					this.currentPoll = res;
				}
			} catch (error) {
				return;
			}
		},
		async getPolls(pagination: IPollItemRequest): Promise<[IPollInfo[], number] | void> {
			try {
				const [res, count] = await getPolls(pagination);
				if (!res) {
					return;
				}
				pagination.Skip ? (this.polls = this.polls.concat(res)) : (this.polls = res);
				this.count = count;
				this.pagination = pagination;
			} catch (error) {
				throw handleError(error);
			}
		},
		async getPollResults(pollID: number): Promise<IPollResults | void> {
			try {
				const [res] = await getPollResults({
					ID: pollID,
				});
				if (!res) {
					return;
				}
				return res;
			} catch (error) {
				throw handleError(error);
			}
		},
		async savePoll(poll: IPollSave): Promise<IPollSave | void> {
			try {
				const res = await savePoll(poll);
				if (!res) {
					return;
				}
				this.getPolls(this.pagination);
			} catch (error) {
				throw handleError(error);
			}
		},
		async submitPollEntry(payload: IPollEntry): Promise<void> {
			try {
				await submitPollEntry(payload);
			} catch (error) {
				throw handleError(error);
			}
		},
	},
});
