<script lang="ts" setup>
import { INewsItem } from '@/types/News';
import { Tags, Tag } from '@/types/constants';
import placeholder from '@/assets/images/placeholder.jpeg';
import { separateMultiwordTag } from '@/plugins/helpers';
import { useAuthenticationStore } from '@/store/auth';

const { content, tagName, isLikeVisible } = defineProps<{
	content: INewsItem;
	tagName: string;
	isLikeVisible: boolean;
}>();

const emit = defineEmits<{
	(event: 'openUpdateModal', value: void): void;
}>();

const router = useRouter();
const authStore = useAuthenticationStore();
const currentPage = $computed(() => router.currentRoute.value.name ?? 'home');

const routes = routeNames;

const user = $computed(() => authStore.user ?? { IsAdmin: false });

const isAdmin = $computed(() => user.IsAdmin);
const isNewsCategory = $computed(() => content.Category === Category.News);
const isUpdateNewsAllowed = $computed(() => isAdmin && isNewsCategory);
</script>

<template>
	<article
		class="flex flex-col justify-start items-center md:flex-row rounded-md my-4 transition-shadow shadow-md shadow-stone-300 hover:shadow-xl w-full"
	>
		<div class="flex w-[35%]">
			<RouterLink :to="{ name: 'post', params: { id: content.ID } }" class="hover:opacity-75 transition-opacity">
				<ProgressiveImage
					v-if="content.Picture"
					:key="content.Picture"
					:src="content.Picture"
					class="max-w-[350px] max-h-[350px]"
				/>
				<ProgressiveImage v-else :src="placeholder" image-class="max-w-[350px] max-h-[350px] object-cover" />
			</RouterLink>
		</div>
		<div class="bg-white flex flex-col justify-start p-6 w-full">
			<div class="flex flex-row justify-between">
				<RouterLink
					:to="{ name: currentPage, query: { tag: Tags[tagName as Tag] } }"
					class="pb-4 text-sm font-bold text-primary uppercase"
					>{{ separateMultiwordTag(tagName) }}</RouterLink
				>
				<div>
					<a
						v-if="isUpdateNewsAllowed"
						class="text-primary underline"
						href="javascript:void(0)"
						@click="emit('openUpdateModal')"
						>Update</a
					>
				</div>
				<div v-if="isLikeVisible" class="space-x-6">
					<span class="inline-flex items-center text-sm">
						<LikeComponent :likes="content.Likes" :post-i-d="content.ID" />
					</span>
				</div>
			</div>
			<RouterLink
				:to="{ name: routes.post, params: { id: content.ID } }"
				class="pb-4 text-3xl font-bold hover:text-gray-700"
			>
				{{ content.Headline }}
			</RouterLink>
			<p class="pb-3 text-sm">
				Published on
				<BaseDateFormatter :time-to-format="content.PublishedOn" />
				by
				<span class="font-semibold hover:text-gray-800">{{ content.Author }}</span>
			</p>
			<RouterLink :to="{ name: routes.post, params: { id: content.ID } }" class="mb-6 truncate-3-lines">
				{{ content.Intro }}
			</RouterLink>
			<div class="w-full flex flex-row justify-between">
				<RouterLink
					:to="{ name: routes.post, params: { id: content.ID } }"
					class="uppercase text-primary transition-all"
				>
					Read more
				</RouterLink>

				<a
					v-if="isAdmin && !isNewsCategory"
					class="text-primary underline"
					href="javascript:void(0)"
					@click="emit('openUpdateModal')"
					>Update</a
				>
			</div>
		</div>
	</article>
</template>
