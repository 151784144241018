import { adminRouteNames, routeNames } from './../router';
import { AuthorTypes } from './../types/constants';
import { IAuthorInfo } from './../types/Author';
import { CheckSecurity, ForgotPassword, loginPayload, ResetPassword } from './../services/auth-service';
import { storageVariables } from '@/modules/storage';
import { Cultures } from '@/modules/validation';
import { ICheckSecurity, IForgotPassword, IResetPassword } from '@/types/Auth';

export const useAuthenticationStore = defineStore('authentication', {
	state: () => ({
		user: null as IAuthorInfo | null,
		resetPasswordToken: '',
		redirectAfterLogin: '',
		culture: 'en-us' as Cultures,
		isLoggedIn: false,
	}),
	actions: {
		setCultureOnLogin() {
			const store = useStore();
			this.culture = getCultureByCountryID(store.countries, this.user?.CountryID ?? 0);
			setStorageItem(storageVariables.culture, this.culture);
		},
		setCulture(culture: Cultures) {
			this.culture = culture;
			setStorageItem(storageVariables.culture, culture);
		},
		async login(payload: loginPayload, authorType: AuthorTypes) {
			const user = await login(payload, authorType);
			this.user = user;
			if (this.redirectAfterLogin !== '') this.router.push({ path: this.redirectAfterLogin });
			this.isLoggedIn = true;
			this.setCultureOnLogin();
			setStorageItem(storageVariables.user, user);
			return user;
		},
		async autoLogin() {
			try {
				const storedUser = getStorageItem<IAuthorInfo>(storageVariables.user);
				if (!storedUser?.ID) {
					this.culture = getStorageItem<Cultures>(storageVariables.culture) || 'en-us';
					throw new Error('No user found');
				}
				const [user] = await getAuthor(0);
				this.user = user;
				user.IsAdmin = (user.Types & AuthorTypes.Admins) == AuthorTypes.Admins;
				user.IsBlogger = user.IsAdmin || (user.Types & AuthorTypes.Bloggers) == AuthorTypes.Bloggers;
				user.IsAdvertiser = user.IsAdmin || (user.Types & AuthorTypes.Advertisers) == AuthorTypes.Advertisers;
				user.IsSponsor = user.IsAdmin || (user.Types & AuthorTypes.Sponsors) == AuthorTypes.Sponsors;
				this.setCultureOnLogin();
				this.isLoggedIn = true;
				return user;
			} catch (error) {
				this.user = null;
				this.isLoggedIn = false;
				if (this.router.currentRoute.value.meta.authorType === AuthorTypes.Admins) {
					this.router.push({ name: adminRouteNames.adminIndex });
				} else {
					this.router.push({ name: routeNames.home });
				}
			}
		},
		async logout() {
			this.router.push({ name: routeNames.home });
			this.user = null;
			this.isLoggedIn = false;
			removeStorageItem(storageVariables.user);
			removeStorageItem(storageVariables.token);
		},
		async forgotPassword(forgotPassword: IForgotPassword) {
			try {
				const [res] = await ForgotPassword(forgotPassword);
				return String(res);
			} catch (error) {
				throw new Error("We're sorry, we can't find an active user with the credentials you entered");
			}
		},
		async checkSecurity(checkSecurity: ICheckSecurity) {
			try {
				const [res] = await CheckSecurity(checkSecurity);
				this.resetPasswordToken = res;
			} catch (error) {
				throw new Error('Bad security answer');
			}
		},
		async resetPassword(resetPassword: IResetPassword) {
			try {
				resetPassword.ResetToken = this.resetPasswordToken;
				return ResetPassword(resetPassword);
			} catch (error) {
				throw new Error('Failed to handle reset password');
			}
		},
	},
});
