import { NotificationType, useNotification } from 'naive-ui';
import { INotificationOption } from '@/types/Generic';
const staticOptions: INotificationOption = {
	duration: 3000,
	meta: new Date().toLocaleString(),
};
export function useNotifications() {
	const notification = useNotification();

	function createNotification(type: NotificationType, options: INotificationOption) {
		notification.create({
			type: type,
			...options,
			...staticOptions,
		});
	}

	return { createNotification };
}
