import { AuthorTypes } from './../types/constants';
import { getStorageItem, setStorageItem, storageVariables } from '@/modules/storage';
import { getCountries, getStates, getTimezones } from '@/services/api-service';
import { ICountry, IState, ITimeZone } from '@/types/Generic';
import { defineStore } from 'pinia';

const versionString =
	import.meta.env.MODE === 'development' ? import.meta.env.VITE_APP_VERSION + '-dev' : import.meta.env.VITE_APP_VERSION;

export const useStore = defineStore('main', {
	state: () => ({
		debug: import.meta.env.MODE === 'development',
		version: versionString,
		isInitialized: false,
		isSignUpModalOpen: false,
		signUpAuthorType: AuthorTypes.Bloggers,
		countries: [] as ICountry[],
		defaultCountryId: 1,
		timezones: [] as ITimeZone[],
		defaultTimezoneId: 2,
		states: [] as IState[],
		defaultStateId: 20,
	}),

	actions: {
		async initApp() {
			this.countries = (getStorageItem(storageVariables.countries) as ICountry[]) || [];
			this.defaultCountryId = (getStorageItem(storageVariables.defaultCountryId) as number) || 1
			this.timezones = (getStorageItem(storageVariables.timezones) as ITimeZone[]) || [];
			this.defaultTimezoneId = (getStorageItem(storageVariables.defaultTimezoneId) as number) || 2	
			this.states = (getStorageItem(storageVariables.states) as IState[]) || [];
			this.defaultStateId = (getStorageItem(storageVariables.defaultStateId) as number) || 20	
			this.countries = await getCountries();
			this.defaultCountryId = this.countries.find((x) => x.IsDefault)?.ID ?? 1;
			this.timezones = await getTimezones(this.defaultCountryId);
			this.defaultTimezoneId = this.timezones.find((x) => x.IsDefault)?.ID ?? 2;	
			this.states = await getStates(this.defaultCountryId);
			this.defaultStateId = this.states.find((x) => x.IsDefault)?.ID ?? 20;	
			setStorageItem(storageVariables.countries, this.countries);
			setStorageItem(storageVariables.defaultCountryId, this.defaultCountryId);	
			setStorageItem(storageVariables.timezones, this.timezones);
			setStorageItem(storageVariables.defaultTimezoneId, this.defaultTimezoneId);	
			setStorageItem(storageVariables.states, this.states);
			setStorageItem(storageVariables.defaultStateId, this.defaultStateId);		
			this.isInitialized = true;
		},
		async getStatesByCountryID(countryID: number) {
			const states = await getStates(countryID);
			this.states = states;
			setStorageItem(storageVariables.states, states);
			return states;
		},
	},
	getters: {
		isReady: (state) => {
			return !state.isInitialized;
		},
	},
});
