import { handleError } from '@/plugins/errors';
import { GetSubmissions, SaveSubmission } from '@/services/submission-service';
import { ISubmissionItem, ISubmissionItemPayload, ISubmissionItemRequest } from '@/types/Submission';
import { defineStore } from 'pinia';

export const useSubmissionStore = defineStore('submission', {
	state: () => ({
		submissions: [] as ISubmissionItem[],
		pagination: {} as ISubmissionItemRequest,
		newSubmissionID: -1,
		count: 0,
	}),
	actions: {
		async getSubmissionById(submissionID: number) {
			try {
				const submission = await GetSubmission({ ID: submissionID });
				return submission;
			} catch (error) {
				throw handleError(error);
			}
		},
		async getSubmissions(pagination: ISubmissionItemRequest): Promise<ISubmissionItem[] | void> {
			try {
				this.pagination = pagination;
				const [res, count] = await GetSubmissions(pagination);
				if (!res) {
					return;
				}
				pagination.Skip ? (this.submissions = this.submissions.concat(res)) : (this.submissions = res);
				this.count = count;
			} catch (error) {
				throw handleError(error);
			}
		},

		async saveSubmission(payload: ISubmissionItemPayload): Promise<ISubmissionItem | void> {
			try {
				const [res] = await SaveSubmission(payload);
				if (!res) {
					return;
				}
				this.submissions.push(res);
				this.newSubmissionID = res.ID;
				return res;
			} catch (error) {
				throw handleError(error);
			}
		},

		async updateSubmission(payload: ISubmissionItemPayload): Promise<ISubmissionItem | void> {
			try {
				const [res] = await SaveSubmission(payload);
				if (!res) {
					return;
				}
				this.getSubmissions(this.pagination);
				return res;
			} catch (error) {
				throw handleError(error);
			}
		},
	},
});
