<template>
	<div :class="modelClass" @click="isUploadDisabled ? deleteSelectedPicture() : ''">
		<n-upload
			class="w-full"
			directory-dnd
			:accept="accept ?? 'image/jpeg, image/png, image/gif, image/jpg'"
			:file-list="[]"
			:disabled="isUploadDisabled"
			@change="handleFile"
		>
			<div class="relative flex items-center justify-center w-full">
				<div class="flex justify-center w-full h-full px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
					<div v-if="uploadedPicture" class="avatar-overlay">
						<n-avatar class="!w-[100px] !h-[100px] cursor-pointer" :src="uploadedPicture" />
					</div>
					<div v-else class="space-y-1 text-center">
						<svg
							class="w-12 h-12 mx-auto text-gray-400"
							stroke="currentColor"
							fill="none"
							viewBox="0 0 48 48"
							aria-hidden="true"
						>
							<path
								d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<div class="flex flex-col text-sm text-gray-600">
							<label
								for="file-upload"
								class="relative font-medium text-indigo-600 bg-white rounded-md cursor-pointer focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
							>
								<span>Upload a file</span>
							</label>
							<p class="pl-1">or drag and drop</p>
						</div>
						<p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
					</div>
				</div>
			</div>
		</n-upload>
	</div>
</template>
<script lang="ts" setup>
import { UploadFileInfo } from 'naive-ui';
import { ImageAspectRatios, ImageDimensions } from '@/types/constants';

const {
	modelValue = '',
	accept,
	isAuthorImage = false,
} = defineProps<{
	modelValue?: string;
	accept?: string;
	modelClass?: string;
	isAuthorImage?: boolean;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: string): void;
}>();

const { cropReduceImage } = useImageManipulation();

let uploadedPicture: string = $ref(modelValue);

const isUploadDisabled = $computed(() => uploadedPicture?.length > 0);

function handleFile(data: { fileList: UploadFileInfo[] }) {
	if (!data.fileList[0].file) {
		return;
	}
	// convert data.fileList[0].file to base64
	const reader = new FileReader();
	reader.readAsDataURL(data.fileList[0].file);

	reader.onload = async () => {
		const croppedImage = await cropReduceImage(
			reader.result as string,
			ImageAspectRatios.Standard,
			isAuthorImage ? ImageDimensions.AuthorPicture : ImageDimensions.BlogPicture
		);
		uploadedPicture = croppedImage?.includes('base64') ? croppedImage : (reader.result as string);
		emit('update:modelValue', uploadedPicture);
	};
}

function deleteSelectedPicture() {
	uploadedPicture = '';
	emit('update:modelValue', uploadedPicture);
}
</script>
<style lang="scss" scoped>
.avatar-overlay:before {
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.4s ease-out;
}
.avatar-overlay:hover::before {
	content: 'Delete';
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 1;
	width: 100%;
	height: 100%;
	opacity: 1;
}

:deep(.n-upload-trigger) {
	width: 100%;
}

:deep(.n-upload-trigger.n-upload-trigger--disabled) {
	cursor: pointer;
	opacity: 1;
}
</style>
