<template>
	<div class="flex col-span-3 justify-between">
		<p>Sponsorship level:</p>
		<n-tag
			v-for="sponsorship in sponsorships"
			:key="sponsorship.id"
			checkable
			:checked="isSponsorshipSelected(sponsorship)"
			@click="selectSponsorship(sponsorship)"
		>
			{{ sponsorship.name }}
		</n-tag>
	</div>

	<n-form-item class="w-full col-span-3">
		<div class="flex flex-row justify-between w-full items-center">
			<n-checkbox v-model:checked="isCouponCodeApplied">Apply Coupon</n-checkbox>
			<n-input
				v-model:value="couponCode"
				placeholder="Enter coupon code"
				:disabled="!isCouponCodeApplied"
				@update:value="(value) => emit('coupon-code', value)"
			/>
		</div>
	</n-form-item>

	<div v-if="!isCouponCodeApplied" class="col-span-3">
		<n-form-item class="col-span-3">
			<n-checkbox v-model:checked="isTermsAccepted" @update:checked="(value) => emit('terms-accepted', value)"
				>I hereby confirm that I have authority to make this purchase on behalf of the advertising organization, and I
				acknowledge that I have read and understood the terms and conditions of service listed
				<a href="#">here</a>.</n-checkbox
			>
		</n-form-item>

		<n-form-item class="col-span-3">
			<div class="flex w-full justify-between items-center">
				<div v-if="paymentOnFile">{{ paymentViaText }}</div>
				<n-button type="primary" @click="isModalVisible = true"> Payment Information </n-button>
				<div>Payment amount: {{ formatMoney(selectedSponsorship.value, culture) }}</div>
			</div>
		</n-form-item>
	</div>
	<PaymentTabs v-if="isModalVisible" v-model="isModalVisible" @payment-verified="paymentVerified" />
</template>
<script lang="ts" setup>
import { useAuthenticationStore } from '@/store/auth';
import { formatMoney } from '@/modules/validation';
import { IPaymentInstrument, IFeeOptions } from '@/types/Payments';
import { usePaymentsStore } from '@/store/payments';
import { ISponsorshipSelection } from '@/types/Sponsorship';
import { NTag, NFormItem, NCheckbox, NInput, NButton } from 'naive-ui';

const { country = 1 } = defineProps<{
	country: number | undefined;
}>();

const emit = defineEmits<{
	(event: 'update:modelValue', value: Sponsorship): void;
	(event: 'terms-accepted', value: boolean): void;
	(event: 'coupon-code', value: string): void;
	(event: 'payment-verified', value: IPaymentInstrument): void;
}>();

let couponCode = $ref('');
let isCouponCodeApplied = $ref(false);
const isTermsAccepted = $ref(false);
let isModalVisible = $ref(false);
let paymentOnFile: IPaymentInstrument | null = $ref(null);

let sponsorships: ISponsorshipSelection[] = $ref([]);

const accountNumber = $computed(() => {
	return paymentOnFile?.AccountNumber != null
		? paymentOnFile.AccountNumber.length > 4
			? paymentOnFile.AccountNumber.slice(-4)
			: paymentOnFile?.AccountNumber
		: '';
});

watchEffect(() => {
	if (!isCouponCodeApplied) {
		couponCode = '';
		emit('coupon-code', '');
	}
});

const paymentViaText = $computed(() =>
	paymentOnFile && !paymentOnFile.IsAch
		? `Payment via: ${CardTypes[paymentOnFile.PaymentType]}  *${accountNumber} Exp.
					${paymentOnFile.ExpirationMonth}/${String(paymentOnFile.ExpirationYear)?.slice(-2)}`
		: `Payment via: ${AchTypes[paymentOnFile?.PaymentType ?? 7]} *${accountNumber}`
);

function paymentVerified(payment: IPaymentInstrument) {
	paymentOnFile = payment;
	emit('payment-verified', payment);
}

type Sponsorship = typeof sponsorships[number];

let selectedSponsorship: Sponsorship = $ref({} as Sponsorship);

const paymentStore = usePaymentsStore();

async function getPaymentInfo() {
	try {
		await paymentStore.getPaymentInfo(country);
	} catch (error) {
		console.error(error);
	}
}

watchEffect(getPaymentInfo);

function selectSponsorship(sponsorship: Sponsorship) {
	selectedSponsorship = sponsorship;
	emit('update:modelValue', selectedSponsorship);
}

function isSponsorshipSelected(sponsorship: Sponsorship) {
	return sponsorship.name === selectedSponsorship.name;
}

let authStore = useAuthenticationStore();

const culture = $computed(() => authStore.culture);

const getCurrency = $computed(() => (culture === 'en-gb' ? '£' : '$'));

function loadSponsorships() {
	paymentStore.paymentInfo.SponsorshipFees.forEach((fee: IFeeOptions, idx) => {
		sponsorships.push({
			name: fee.Prompt,
			value: fee.Fee,
			id: 2 ** (idx + 1),
		});
	});
	selectedSponsorship = sponsorships[0];
	emit('update:modelValue', sponsorships[0]);
}

onBeforeMount(async () => {
	await getPaymentInfo();
	loadSponsorships();
});
</script>
<style scoped>
:deep(.n-checkbox) {
	align-items: center;
}
</style>
