import {
	GetActiveSponsors,
	GetOrganization,
	GetOrganizations,
	SaveOrganization,
} from '@/services/organization-service';
import {
	IActiveSponsors,
	IActiveSponsorsRequest,
	IGetOrganizationRequest,
	IOrganizationInfo,
} from '../types/Organizations';

export const useOrganizations = defineStore('organizations', {
	state: () => ({
		organizations: [] as IOrganizationInfo[],
		currentOrganization: {} as IOrganizationInfo,
		activeSponsors: [] as IActiveSponsors[],
		count: 0,
	}),
	actions: {
		async getOrganizations(pagination: IGetOrganizationRequest) {
			const [resp, count] = await GetOrganizations(pagination);
			this.count = count;
			if (!resp) {
				throw new Error('Failed to get organizations');
			}
			pagination.Skip ? (this.organizations = this.organizations.concat(resp)) : (this.organizations = resp);
		},
		async getOrganizationInfo(id: number) {
			if (this.currentOrganization?.ID === id) return this.currentOrganization;
			const [resp] = await GetOrganization(id);
			if (!resp) {
				throw new Error('Failed to get organization');
			}
			this.currentOrganization = resp;
			return resp;
		},
		async saveOrganization(organization: IOrganizationInfo) {
			try {
				const res = await SaveOrganization(organization);
				if (this.currentOrganization.ID === organization.ID) {
					this.currentOrganization = organization;
				}
				this.organizations = this.organizations.map((org) => {
					if (org.ID === organization.ID) {
						return organization;
					}
					return org;
				});
				return res;
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
		async getActiveSponsors(request: IActiveSponsorsRequest) {
			const [resp] = await GetActiveSponsors(request);
			if (!resp) {
				throw new Error('Failed to get sponsors');
			}
			this.activeSponsors = resp;
		},
	},
});
