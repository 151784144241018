<script lang="ts" setup>
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';

const { isSmaller } = useBreakpoints(breakpointsTailwind);
const isSmall = $computed(() => isSmaller('lg'));
</script>

<template>
	<!--  #header begin  -->
	<header class="container lg:flex lg:items-center py-6 mx-auto bg-transparentflex-wrap hidden">
		<RouterLink :key="String(isSmall)" class="flex items-center justify-center h-full lg:justify-start" to="/">
			<img
				v-if="isSmall"
				height="50"
				class="h-[50px]"
				src="@/assets/images/dpt-logo-vertical-blue.svg"
				alt="dental_practice_logo"
			/>
			<img
				v-else
				height="50"
				class="h-[50px]"
				src="@/assets/images/dpt-logo-horizontal-blue.svg"
				alt="dental_practice_logo"
			/>
		</RouterLink>
	</header>
	<!--  #header end  -->
</template>

<style lang="scss" scoped>
.image-position {
	position: absolute;
	top: -50%;
	left: -50%;
	transform: translate(-50%, 50%);
}
</style>
