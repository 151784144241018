import { INewsItem, INewsItemInfo, INewsItemRequest } from '@/types/News';

export async function GetNewsItems(pagination: INewsItemRequest): Promise<[INewsItem[], number]> {
	try {
		return baseService.post(BaseEndpoints.GetNewsItems, pagination);
	} catch (error) {
		throw handleError(error);
	}
}

export async function GetNewsItemInfo(id: number): Promise<[INewsItemInfo, number]> {
	try {
		return await baseService.post(BaseEndpoints.GetNewsItemInfo, {
			ID: id,
		});
	} catch (error) {
		throw handleError(error);
	}
}

export async function LikeNewsItem(id: number): Promise<void> {
	try {
		return await baseService.post(BaseEndpoints.LikeNewsItem, {
			ID: id,
		});
	} catch (error) {
		throw handleError(error);
	}
}
