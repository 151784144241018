import { getAuthor, getAuthorInfo, getAuthors } from '@/services/author-service';
import { ItemID } from '@/types';
import { IAuthorInfo, IAuthorItemRequest, IAuthorSignUp } from '@/types/Author';
import { useAuthenticationStore } from './auth';

export const useAuthors = defineStore('authors', {
	state: () => ({
		authors: [] as IAuthorInfo[],
		currentPostAuthor: {} as IAuthorInfo,
		updateAuthor: {} as IAuthorInfo,
		newAuthorID: -1,
		count: 0,
	}),
	actions: {
		async getAuthor(authorID: ItemID) {
			const [resp] = await getAuthor(authorID);
			if (!resp) {
				throw new Error('Failed to get author');
			}
			this.updateAuthor = resp;
			return resp;
		},
		async getAuthorInfo(authorID: ItemID) {
			const [res] = await getAuthorInfo(authorID);
			if (!res) {
				throw new Error('Author not found');
			}
			this.currentPostAuthor = res;
			return res;
		},
		async getAuthors(pagination: IAuthorItemRequest) {
			const [resp, count] = await getAuthors(pagination);
			if (!resp) {
				throw new Error('Failed to get authors');
			}
			pagination.Skip ? (this.authors = this.authors.concat(resp)) : (this.authors = resp);
			this.count = count;
		},
		async saveAuthor(payload: IAuthorInfo) {
			try {
				const res = await saveAuthor(payload);
				const authorIndex = this.authors.findIndex((author) => author.ID === payload.ID);
				if (authorIndex !== -1) this.authors[authorIndex] = payload;
				else {
					const store = useAuthenticationStore();
					store.user = payload;
				}
				return res;
			} catch (error) {
				return;
			}
		},
		async createNewAuthorRequest(payload: IAuthorSignUp) {
			try {
				const [res] = await createNewAuthorRequest(payload);
				this.newAuthorID = res.ID ?? -1;
				return res;
			} catch (error) {
				console.error(error);
				throw error;
			}
		},
	},
});
